export class Task {
    Subject: string;
    Body: string;
    DueDate: Date;
    Contacts: string[];
    Overdue: boolean;
    ComingDue: boolean;
    Status: string;
    Assigner: string;
    Id: any;
}
