import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { PageInfo } from 'src/app/models/PageInfo';
import { SharedDataService, DictKey } from 'src/app/services/shared-data.service';
import { Router } from '@angular/router';
import { CartPopup } from 'src/app/models/CartPopup';
import { OdpService } from 'src/app/services/odp.service';
import { CartItem } from 'src/app/models/CartItem';
import { DistributorService } from 'src/app/services/distributor.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.page.html',
  styleUrls: ['./shopping-cart.page.scss'],
})
export class ShoppingCartPage implements OnInit {

  cartPopup: CartPopup = new CartPopup();

  odpUrl: string;
  odpCustomerName: string;
  billToNumber: string;

  constructor(
    private modalController: ModalController,
    private sharedDataService: SharedDataService,
    private router: Router,
    private distributorService: DistributorService,
    private odpService: OdpService,
    behaviorSubjectService: BehaviorSubjectService
  ) {
    behaviorSubjectService.opdUrlSubject.subscribe(url => {
      this.odpUrl = url;
    });
    const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    this.odpCustomerName = info.OdpCustomerName;
    this.billToNumber = info.BillToNumber;
  }

  ngOnInit() {
    // Test cart
    // const exampleCartPopup: CartPopup = {
    //   QuoteNumber: 12323,
    //   QuoteDate: new Date(),
    //   CartCount: 2,
    //   CartItems: [
    //     {
    //       CustomerPartNumber: '4324',
    //       PartNumber: '14324',
    //       PartDescription: 'This is a part',
    //       PartQuantity: 1,
    //       PartTotalPrice: 199.99
    //     },
    //     {
    //       CustomerPartNumber: '5234',
    //       PartNumber: '24432',
    //       PartDescription: 'This is a part too',
    //       PartQuantity: 1,
    //       PartTotalPrice: 2493.34
    //     },
    //   ],
    //   CartTotalPrice: 2693.33
    // };
    // this.cartPopup = exampleCartPopup;

    var currentCart = this.sharedDataService.getCurrentCartQuoteNumber();
    this.distributorService.GetCartItemList(currentCart).subscribe(cartPopup => {
      this.cartPopup = cartPopup
    });
  }

  closeModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss();
  }

  navigateToOdp() {
    window.open(this.odpUrl, '_blank');
  }

  onPartDescription(part: CartItem) {
    // navigate to that part's page
    this.closeModal();
    this.router.navigateByUrl(`/product/${part.PartNumber}`);
  }

  onNewQuote() {
    const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    if (!info) {
      return;
    }

    // Create a new quote and go to home page
    this.distributorService.getNewQuote().subscribe(() => {
      this.distributorService.getCartCountArray(null).subscribe(cartInfo => {
        this.sharedDataService.setValue(DictKey.CartCountArray, cartInfo);

        this.closeModal();
        // const url = `customer/${info.SalesforceId}/orders/${cartInfo[0]}/Q`;
        const url = 'product-catalog';
        this.odpService.storeNewQuoteMessage();
        if (this.router.url.indexOf('product-catalog') >= 0 && !this.router.url.lastIndexOf("/")) {
          this.odpService.displayNewQuoteMessageIfStored()
        } else {
          this.router.navigateByUrl(url, { replaceUrl: true });
        }
      });
    });

    // The went back on forth as to what they wanted this button to do.
    // window.open(this.odpUrl, '_blank')s;
  }

  onChangeQuote() {
    // Go to quotes page
    this.closeModal();
    const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    if (info && info.BillToNumber) {
      this.sharedDataService.setValue(DictKey.OrderType, 0);
      this.router.navigateByUrl(`customer/${info.SalesforceId}/orders`);
    }
  }

  onViewQuote() {
    // Go to quote details page
    const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    if (info && info.BillToNumber && this.cartPopup && this.cartPopup.QuoteNumber) {
      const url = `customer/${info.BillToNumber}/orders/${this.cartPopup.QuoteNumber}/Q`;
      this.router.navigateByUrl(url);
      this.closeModal();
    }
  }

  onOdp() {
    const url = `${this.odpUrl}Quote.aspx?order=${this.cartPopup.QuoteNumber}&ordqte=Q`;
    window.open(url, '_blank');
  }

}
