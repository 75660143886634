import { Injectable, EventEmitter } from '@angular/core';
import deepEqual from 'deep-equal';
import { DistributorService } from './distributor.service';
import { EndUserShoppingCartService } from './end-user-shopping-cart.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private dict: Record<string, DictionaryEntryData>;

  constructor(
    private distributorService: DistributorService,
    private endUserShoppingCartService: EndUserShoppingCartService,
    private settingsService: SettingsService
  ) {
    this.dict = {};
  }

  setValue(key: string, value: any) {
    let data = this.dict[key];
    if (data) {
      this.checkAndUpdate(key, data, value);
    } else {
      this.buildDictionaryEntryData(key);
      data = this.dict[key];
      this.checkAndUpdate(key, data, value);
    }
  }

  getValue(key: string) {
    try {
      return this.dict[key].value;
    } catch (error) {
      return null;
    }
  }

  getEmitter(key: string) {
    let data = this.dict[key];
    if (!data) {
      this.buildDictionaryEntryData(key);
      data = this.dict[key];
    }
    return data.emitter;
  }

  private checkAndUpdate(key: string, data: DictionaryEntryData, value: any) {
    if (!deepEqual(data.value, value)) {
      data.value = value;
      data.emitter.next(value);
      console.log(`checkAndUpdate emitted new ${key}:`, value);
    }
  }

  private buildDictionaryEntryData(key: string) {
    const data = new DictionaryEntryData();
    data.emitter = new EventEmitter<any>();
    this.dict[key] = data;
  }

  getCurrentCartQuoteNumber() {
    var cartCountArray = this.getValue(DictKey.CartCountArray);
    if (cartCountArray) {
      return cartCountArray[0];
    }
  }

  getCurrentCartItemCount() {
    var cartCountArray = this.getValue(DictKey.CartCountArray);
    if (cartCountArray && cartCountArray.length > 1) {
      return cartCountArray[1];
    }
  }

  fetchCartCount() {
    var isEndUser = this.settingsService.isEndUser();
    if (isEndUser) {
      const username = this.settingsService.getUserSettings().username;
      this.endUserShoppingCartService.getActiveCart(username)
      .subscribe(cartId => {
        this.getCartCountEndUser(cartId);
      })
    } else {
      this.distributorService.getCartCountArray(null).subscribe(cartInfo => {
        this.setValue(DictKey.CartCountArray, cartInfo);
      });
    }
  }

 getCartCountEndUser(cartId: number) {
    this.endUserShoppingCartService.getCartItemCount(cartId)
      .subscribe(count => {
        // Value format is [quotenum, count]
        this.setValue(DictKey.CartCountArray, [cartId, count]);
      })
  }
}

class DictionaryEntryData {
  value: any;
  emitter: EventEmitter<any>;
}

export enum DictKey {
  AppPageInfo = 'appPageInfo',
  SearchText = 'searchText',
  BannerHTML = 'bannerHTML',
  OrderType = 'orderType', // 0 to go to quotes tab, 1 to go to orders tab
  CartCountArray = 'cartCountArray' // [quotenum, count]
}
