import { Pipe, PipeTransform } from '@angular/core';
import { PTAPortabandDimensionsModel } from '../models/PTAPortabandDimensionsModel';

@Pipe({
  name: 'portaBladeLwt'
})
export class PortaBladeLwtPipe implements PipeTransform {

  transform(lwt: any, args?: any): any {
    if (!lwt || !lwt.Length) return null;
    return `${lwt.Length}L x ${lwt.Width}W x  ${lwt.Thickness}T`;
  }

}
