import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubjectService } from './behavior-subject.service';
import { SelectListItem } from '../models/SelectListItem';
import { map } from 'rxjs/operators';
import { TerritorySalesperson } from '../models/TerritorySalesperson';
import { SalesPerson } from '../models/SalesPerson';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private url: string;

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService) {
      behaviorSubjectService.morseAPISubject.subscribe(url => {this.url = url});
  }

  getSalesPeople(): Observable<SalesPerson[]> {
    const url = `${this.url}/GetSalesPeople`;
    let options = httpOptions;
    return this.http.get<SalesPerson[]>(url, options);
  }

  getPermittedTerritories(): Observable<SelectListItem[]> {
    const url = `${this.url}/GetPermittedTerritories`;
    let options = httpOptions;
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getTerritoriesSalespeople(): Observable<TerritorySalesperson[]> {
    const url = `${this.url}/GetTerritoriesSalespeople`;
    let options = httpOptions;
    return this.http.get<TerritorySalesperson[]>(url, options);
  }
}
