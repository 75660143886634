import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CustomerService } from 'src/app/services/customer.service';
import { Contact } from 'src/app/models/Contact';
import { ListService } from 'src/app/services/list.service';
import { SelectListItem } from 'src/app/models/SelectListItem';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.page.html',
  styleUrls: ['./add-contact.page.scss'],
})
export class AddContactPage implements OnInit {

  @Input() AccountId: number;
  @Input() contactId: number;
  contactForm: FormGroup;
  contact: Contact;
  submitAttempt: boolean;
  title: string;
  saving = false;
  contactMethodTypeOptions: SelectListItem[] = [];

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private listService: ListService
  ) {
    this.contactForm = this.formBuilder.group({
      FirstName: [null, Validators.required],
      LastName: [null, Validators.required],
      Position: [null, Validators.required],
      IsPrimaryContact: [false, Validators.required],
      ContactMethods: this.formBuilder.array([
      ])
    });
   }

  initContactMethodSubform() {
    return this.formBuilder.group({
      Id: [null],
      ContactMethodType: [null, Validators.required],
      ContactData: [null, Validators.required],
      IsPrimary: [false, Validators.required],
    });
  }

  ngOnInit() {
    this.listService.getContactMethodTypes().subscribe((cmts) => {
      this.contactMethodTypeOptions = cmts;
    });

    if (this.contactId) {
      this.title = 'Update Contact';
      this.customerService.getContact(this.contactId).subscribe((contact) => {
        this.contact = JSON.parse(contact);

        for (let index = 0; index < this.contact.ContactMethods.length; index++) {
          this.AddNewContactMethod();
        }
        this.contactForm.patchValue(this.contact);

        // load type options into drop downs from model
        for (let index = 0; index < this.contact.ContactMethods.length; index++) {
          const control = ((this.contactForm.controls['ContactMethods'] as FormArray).controls[index] as FormGroup).
            controls['ContactMethodType'];
          control.setValue(this.contactMethodTypeOptions.find(cmto =>
            cmto.Value === this.contact.ContactMethods[index].ContactMethodTypeId.toString()).Value);
        }
      });
    } else {
      this.title = 'Add Contact';
      if (this.AccountId) {
        this.contact = new Contact();
        this.contact.AccountId = this.AccountId.toString();
        this.contact.IsPrimaryContact = false; // default it to not null
      }
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  submit() {
    this.submitAttempt = true;
    // if the form is valid
    if (this.contactForm.valid) {
      this.saving = true;
      const fromForm = this.contactForm.getRawValue();
      this.contact.FirstName = fromForm.FirstName;
      this.contact.LastName = fromForm.LastName;
      this.contact.Position = fromForm.Position;
      this.contact.IsPrimaryContact = fromForm.IsPrimaryContact;
      this.contact.ContactMethods = fromForm.ContactMethods.map(cm => {
        return {
          Id: cm.Id,
          ContactMethodTypeId: cm.ContactMethodType,
          ContactMethodType:  this.contactMethodTypeOptions.find(cmto => cmto.Value === cm.ContactMethodType).Text,
          // ContactCategory: string,
          ContactData: cm.ContactData,
          IsPrimary: cm.IsPrimary,
        };
      });
      this.customerService.addUpdateContact(this.contact).subscribe(result => {
        // If event adds successfully close the modal
        if (result) {
          this.modalController.dismiss();
        } else {
          alert('Save unsuccesful. Please try again.');
          this.saving = false;
        }
      }, (error) => {
        alert(`Save unsuccessful. Please try again. If it still doesn't work, this may help tech support solve this issue more quickly:
        Status: ${error.status}. Location: P-M-ACP. Reason: ${error.error.ExceptionMessage}`);
          this.saving = false;
      });
    } else {
      console.log('ERRORS');
      console.log(this.contactForm);
    }
  }

  AddNewContactMethod() {
    const control = (<FormArray>this.contactForm.controls['ContactMethods']) as FormArray;
    control.push(this.initContactMethodSubform());
  }

  RemoveContactMethod(index: number) {
    const control = (<FormArray>this.contactForm.controls['ContactMethods']) as FormArray;
    control.removeAt(index);
  }

  ConfirmRemoveContactMethod(index: number) {
    if (confirm('Are you sure you want to remove this contact method?')) {
      this.RemoveContactMethod(index);
    }
  }

}
