import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CustomerService } from 'src/app/services/customer.service';
import { GetARDetail } from 'src/app/models/GetARDetail';

@Component({
  selector: 'app-credit-details',
  templateUrl: './credit-details.page.html',
  styleUrls: ['./credit-details.page.scss'],
})
export class CreditDetailsPage implements OnInit {

  @Input('customerNumber') customerNumber: string;
  creditDetails: GetARDetail;
  isLoading = true;

  constructor(
    private modalController: ModalController,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.customerService.getARDetail(this.customerNumber)
      .subscribe(creditDetails => {
        this.isLoading = false;
        this.creditDetails = creditDetails;
        console.log(creditDetails);
      })
  }

  closeModal(retVal) {
    this.modalController.dismiss({
      'reload': retVal
    });
  }

  // Dates stored as strings. Ex: 62119 => 06-20-2119
  formatDate(number): string {
    if (number == 0) {
      return '---';
    } else {
      const string = number.toString();
      const year = string.slice(-2);
      const day = string.slice(-4, -2);
      const month =  string.slice(0, -4);
      return month + '-' + day + '-' + year;
    }
  }

}
