import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { getLocaleDateTimeFormat, Location } from '@angular/common';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SettingsService, UserView } from './services/settings.service';
import { NotificationType } from './models/enums/NotificationType';
import { TaskService } from './services/task.service';
import { MenuService } from './services/menu.service';
import { BehaviorSubjectService } from './services/behavior-subject.service';
import { Observable, of, forkJoin } from 'rxjs';
import { PwaService } from './services/pwa-service.service';
import { OdpService } from './services/odp.service';
import { PageService } from './services/page.service';
import { SharedDataService, DictKey } from './services/shared-data.service';
import { PageInfo } from './models/PageInfo';
import { SharedService } from './services/shared.service';
import { DistributorService } from './services/distributor.service';
import { environment } from '../environments/environment';
declare let gtag: Function;
import { CustomerType } from 'src/app/models/enums/CustomerType';
import { CustomerDetails } from './models/CustomerDetails';
import { CustomerService } from './services/customer.service';
import { CustomerListItem } from './models/CustomerListItem';
import { EndUserShoppingCartService } from './services/end-user-shopping-cart.service';
import { ToolsPage } from './pages/tools/tools.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private notices;
  private tasks;
  public window = window;
  public isLoggedIn = false;
  private isOdpUser = false;
  private isOdpUserSet = false;
  requestedRoute = null;
  public notificationTypes = NotificationType;

  public publicURL = false;
  googleAnalyticsId: string;

  public appPages: any[];
  // public repPages: any[];

  subscription: any;
  routerSubscription: any;
  gaRouterSubscription: any;
  currentCustomerId: number;
  customerNumberChangeSubscription: any;
  themeChangeSubscription: any;

  bannerHtml: string;
  trialResultsURL: string;
  showMenu = true;
  gtagSet = false;
  currentCustomer: CustomerListItem;
  pageInfo: PageInfo;

  taskIcon: string = this.settingsService.getTheme("menu/tasks.svg");
  customerIcon:string = this.settingsService.getTheme("menu/customers.svg");
  ordersIcon: string = this.settingsService.getTheme("menu/orders.svg");
  salesIcon: string = this.settingsService.getTheme("menu/sales.svg");
  toolsIcon: string = this.settingsService.getTheme("menu/tools.svg");
  chipLoad: string = this.settingsService.getTheme("menu/chipload2.png");
  supportIcon: string = this.settingsService.getTheme("menu/support.svg");

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private oauthService: OAuthService,
    private lclStorage: LocalStorage,
    private router: Router,
    private settingsService: SettingsService,
    private location: Location,
    private taskService: TaskService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private menuService: MenuService,
    private behaviorSubjectService: BehaviorSubjectService,
    private pageService: PageService,
    private distributorService: DistributorService,
    public Pwa: PwaService,
    private odpService: OdpService,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private customerService: CustomerService,
    private endUserShoppingCartService: EndUserShoppingCartService
    ) {
    this.setTheme();
    this.getTrialResultsUrl();
    this.setAppPages();
    this.initializeApp();
    this.publicURL = this.isPublicURL();
    if (!sessionStorage.getItem('postLoginReload') && this.hasTrackingConsent()) {
      this.trackGoogleAnalytics();
    }
    if (!this.publicURL) {
      this.configureAuthWithNewConfigApi();
    }
    this.getNotifications();

    // Listen for periodic notification updates
    this.subscription = this.taskService.getTaskNotificationEmitter()
      .subscribe(item => this.setTaskNotificationCount(item));
    // Listen for route changes to alter menu
    this.routerSubscription = router.events.subscribe(navEnd => {
      if (navEnd instanceof NavigationEnd) {
        this.routeChanged(navEnd as NavigationEnd);
      }

    });

    // Listen for customer number changes to alter menu
    this.customerNumberChangeSubscription = this.menuService.customerChange.subscribe(customerDetails => {
      this.afterCustomerChange(customerDetails);
    });

    this.themeChangeSubscription = this.settingsService.themeUpdate.subscribe(() => {
      this.taskIcon = this.settingsService.getTheme("menu/tasks.svg");
      this.customerIcon = this.settingsService.getTheme("menu/customers.svg");
      this.ordersIcon = this.settingsService.getTheme("menu/orders.svg");
      this.salesIcon = this.settingsService.getTheme("menu/sales.svg");
      this.toolsIcon = this.settingsService.getTheme("menu/tools.svg");
      this.chipLoad = this.settingsService.getTheme("menu/chipload2.png");
      this.supportIcon = this.settingsService.getTheme("menu/support.svg");
      this.setAppPages();
    })
  }

  private loadCookieYesScript() {
      this.behaviorSubjectService.cookieYesUrlSubject.subscribe(cookieYesUrl => { 
        const script = document.createElement('script');
        script.src = cookieYesUrl;
        document.head.appendChild(script);
      });
  }

  hasTrackingConsent(): boolean {
    if (this.getCookie('cky-consent') == 'yes' && this.getCookie('cookieyes-analytics') == 'yes') {
      return true;
    } else {
      return false;
    }
  }

  getCookie(name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : 'no';
  }

  trackGoogleAnalytics() {
    this.setupGoogleAnalyticsScripts();
    this.gaRouterSubscription = this.router.events.subscribe(navEnd => {
      if (navEnd instanceof NavigationEnd) {
        gtag('config', this.googleAnalyticsId, 
        {
          'page_path': this.location.path(false).split('?')[0],
          'custom_map': {'dimension1': 'Role' }
        });
        this.trackRoleGoogleAnalytics();
      }
    });
  }

  setupGoogleAnalyticsScripts() {
    this.googleAnalyticsId = this.behaviorSubjectService.googleAnalyticsIdSubjectManager.getValue();
    if (this.googleAnalyticsId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
      document.head.appendChild(gTagManagerScript);
  
      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
      `;
      document.head.appendChild(gaScript);
    }
  }

  trackRoleGoogleAnalytics() {
    const userRole = this.settingsService.getUserRoleAnalytics();
    if (userRole == 'Public' && !this.isPublicURL()) {
      // Skip logging role for initial redirect to login page
    } else {
      gtag('set', 'user_properties', {
        Role: userRole
      });
      gtag('event', 'Role', {'Role': userRole});
      console.log('role set');
    }
  }


  setTheme(newTheme?: string) {
    var settings = this.settingsService.getUserSettings();
    let theme = null;
    if (settings) {
      theme = settings.theme;
    } 
    if (newTheme) {
      theme = newTheme;
    }
    //default theme
    if (!theme) {
      theme = 'light';
    }
    this.removeOldThemeClass();
    this.renderer.addClass(document.body, theme);
    //localStorage.setItem('theme', theme);   
  }

  removeOldThemeClass() {
    this.renderer.removeClass(document.body, 'light');
    this.renderer.removeClass(document.body, 'dark');
  }

  afterCustomerChange(customerDetails: CustomerListItem) {
    const parent = this.appPages.find(x => x.url === '/customers' || (x.url ? x.url.indexOf('/customer/') == 0 : false));
    if (customerDetails) {
      this.currentCustomer = customerDetails;
      this.setCustomerMenuItem(parent, customerDetails.Name, '/customer/' + customerDetails.SalesforceId);
    } else {
      this.setCustomerMenuItem(parent, 'Customers', '/customers');
    }
    if (this.allowOrdersQuotesForCurrentCustomer()) {
      // this.setChildPagesUrlId(parent);
      this.setCustomerMenuItems(+customerDetails.BilltoNumber);
    } else {
      this.setCustomerMenuItems(null);
    }
  }

  allowOrdersQuotesForCurrentCustomer(): boolean {
     if (this.currentCustomer && this.currentCustomer.CustomerType != CustomerType.EndUser && this.currentCustomer.BilltoNumber) {
       return true;
     } else {
       return false;
     }
  }

  ngOnInit() {
    const placeholderBanner = '';
    this.pageService.getBannerMessage(placeholderBanner).subscribe(bannerHtml => {
      this.bannerHtml = bannerHtml;
      this.sharedDataService.setValue(DictKey.BannerHTML, bannerHtml);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.gaRouterSubscription.unsubscribe();
  }

  setAppPages() {
    if (this.settingsService.isDistributor()) {
      this.appPages = this.getDistributorPages()
    } else if (this.settingsService.isEndUser()) {
      this.appPages = this.getEndUserPages();
    } else {
      this.appPages = this.getAppPages();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.translate.setDefaultLang('en');
      this.translate.addLangs(['en', 'es']);
    });
  }

  configureAuthWithNewConfigApi() {
    // this.oauthService.setStorage(localStorage);
    let authConfig: AuthConfig;
    if (this.behaviorSubjectService.environmentSubject.getValue() === 'local') {
      authConfig = {
        issuer: 'https://localhost:44333/core',
        // URL of the app to redirect the user to after login
        redirectUri: window.location.href,
        // The ClientId. The app registerd with this id at the auth-server
        clientId: 'morsesalesapplocal.mkmorse.com',
        // set the scope for the permissions the client should request
        // The first three are defined by OIDC. The 4th is a usecase-specific one
        scope: 'openid odpapi roles all_claims', // profile email voucher
        silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
        // postLogoutRedirectUri: 'https://morsesalesappdev.mkmorse.com'
        postLogoutRedirectUri: 'https://localhost:8100'
      };
    } else {
      authConfig = {
        issuer: this.behaviorSubjectService.authUrlSubject.getValue() + '/core',
        redirectUri: this.behaviorSubjectService.mobileUrlSubject.getValue(), // URL of the app to redirect the user to after login
        clientId: this.behaviorSubjectService.clientIdSubject.getValue(), // The app registerd with this id at the auth-server
        // set the scope for the permissions the client should request
        // The first three are defined by OIDC. The 4th is a usecase-specific one
        scope: 'openid odpapi roles all_claims', // profile email voucher
        silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
        postLogoutRedirectUri: this.behaviorSubjectService.mobileUrlSubject.getValue()
      };
    }
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (!this.checkIsLoggedIn()) {
        sessionStorage.setItem('postLoginReload', '1');
        this.login();
      } else {
        this.isLoggedIn = true;
        // Reset settings based on access token
        // const userSettings = this.settingsService.getUserSettings();
        // this.lclStorage.setItem('settings', this.settingsService.mapTokenClaimsToUserSettings(userSettings)).subscribe(() => { });
        // if user has not set settings previously, redirect
        // this.lclStorage.getItem<boolean>('settings_set').subscribe((settingsSet) => {
        // Redirect to settings page if first time
        // if (settingsSet == null || (settingsSet as boolean) === false) {
        //  this.router.navigate(['settings']);
        // Redirect to preivously attempted URI
        // } else {
        // Timing issue with token saved to local storage and page load so reload if needed after login saves token
        if (sessionStorage.getItem('postLoginReload')) {
          setTimeout(() => { this.window.location.reload(); });
        }
        // }
        sessionStorage.removeItem('postLoginReload');
        this.oauthService.setupAutomaticSilentRefresh();
        var isEndUser = this.settingsService.isEndUser();

        if (this.settingsService.isLoggedIn()) {
          this.loadCookieYesScript();
          const isProfileComplete = this.settingsService.isProfileComplete();
          const isProfileCompletePage = this.route && this.route.routeConfig && this.route.routeConfig.path ? this.route.routeConfig.path.includes('complete-user-profile') : false;
          if (!isProfileComplete && !isProfileCompletePage && isEndUser) {
              this.showMenu = false;
              this.router.navigate(['/complete-user-profile']);
          } else {
            this.showMenu = true;
          }
        }
        if (!isEndUser) {
          this.getPageInfo();
        }
        // });
      }
    });
  }

  login() {
    this.oauthService.initImplicitFlow();
  }

  logout() {
    this.oauthService.logOut();
  }

  checkIsLoggedIn() {
    return this.oauthService.hasValidIdToken();
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

  redirectExternal(route:string) {
    if (route) {
      window.open(route);
    }
  }

  setTaskNotificationCount(count) {
    const taskPage = this.appPages.find(x => x.notificationType === this.notificationTypes.Task);
    if (taskPage) {
      taskPage.notifications = count;
    }
  }

  getNotifications() {
    var isEndUser = this.settingsService.isEndUser();
    if (!isEndUser) {
      this.getPageInfo();
      this.getCartCount();
      // 30 sec refresh
      setTimeout(() => {
        this.getNotifications();
      }, 30000);
    } else {
      const username = this.settingsService.getUserSettings().username;
      this.endUserShoppingCartService.getActiveCart(username)
        .subscribe(cartId => {
          this.sharedDataService.getCartCountEndUser(cartId);
        })
      // 30 sec refresh
      setTimeout(() => {
        this.getNotifications();
      }, 30000);
    }
  }

  getPageInfo() {
    this.pageService.getPageInfo().subscribe(pageInfo => {
      this.pageInfo = pageInfo;
      this.sharedDataService.setValue(DictKey.AppPageInfo, pageInfo);

      const accountPage = this.appPages.find(x => x.title === 'My Account');
      if (pageInfo && !pageInfo.showAccount && accountPage) {
        accountPage.hide = true;
      }

      // Initial load pull current customer from page info, rest use customer change event emitter
      if (pageInfo && pageInfo.OdpCustomerName && !this.currentCustomer) {
        const customerDetails = new CustomerDetails();
        customerDetails.Name = this.pageInfo.OdpCustomerName;
        customerDetails.Id = this.pageInfo.SalesAccountId;
        customerDetails.CustomerNumber = this.pageInfo.OdpCustomerNumber;
        customerDetails.BilltoNumber = this.pageInfo.BillToNumber;
        customerDetails.SalesforceId = this.pageInfo.SalesforceId;
        this.afterCustomerChange(customerDetails);
      }
    }, er => console.log('ERROR: getPageInfo', er));
  }

  getCartCount() {
    this.distributorService.getCartCountArray(null).subscribe(cartInfoArr => {
      this.sharedDataService.setValue(DictKey.CartCountArray, cartInfoArr);
    }, er => console.log('ERROR: getCartCountArray', er));
  }

  routeChanged(navEnd: NavigationEnd) {
    let activeParentUrl = null;
    // if (navEnd.urlAfterRedirects.indexOf('/customer/') >= 0) {
    //   activeParentUrl = '/customers';
    // }

    if (activeParentUrl) {
      this.showChildPages(activeParentUrl);
    }
    this.hideChildPagesExcluding(activeParentUrl);

    this.isPublicURL();
  }

  expandCollapseChildPages(activeParentUrl: string) {
    let parent = this.appPages.find(x => x.url === activeParentUrl);
    if (!parent) {
      parent = this.appPages.find(x => x.psuedoUrl === activeParentUrl);
    }
    setTimeout(() => {
      parent.showChildPages = !parent.showChildPages;
    });
  }

  /*   expandCollapseRepChildPages(activeParentUrl: string) {
        let parent = this.repPages.find(x => x.url === activeParentUrl);
        if (!parent) {
          parent = this.repPages.find(x => x.psuedoUrl === activeParentUrl);
        }
        setTimeout(() => {
          parent.showChildPages = !parent.showChildPages;
        });
    }*/

  showChildPages(activeParentUrl: string) {
    const parent = this.appPages.find(x => x.url === activeParentUrl);
    if (parent) {
      parent.showChildPages = true;
      this.setChildPagesUrlId(parent);
    }
  }

  setCustomerMenuItem(parent, customerName: string, itemUrl: string) {
    if (parent) {
      parent.title = customerName;
      parent.url = itemUrl;
    }
  }

    
  setCustomerMenuItems(customerId: number) {
    const menuItems = this.appPages.filter(m => m.title == 'Orders' || m.title == 'Quotes');
    if (customerId) {
      menuItems.map(m => m.disabled = false);
    } else {
      menuItems.map(m => m.disabled = true);
    }
  }

  setChildPagesUrlId(parent) {
    parent.showChildPages = false;
    let customerNumber = null;
    let id = null;
    this.route.firstChild.params.subscribe((params) => {
      id = params['id'];
      if (parent.url.indexOf('customer') >= 0 && this.menuService.customer) {
        customerNumber = this.menuService.customer.CustomerNumber;
      }
      
      if (id) {
        if (!this.isOdpUserSet) {
          this.setIsOdpUser();
        }
        parent.childPages.map(childPage => {
          // Disable these pages if customerNumber is missing
          if (childPage.urlSuffix.indexOf('orders') >= 0 || childPage.urlSuffix.indexOf('sales') >= 0
            || childPage.urlSuffix.indexOf('rga') >= 0) {
            const hasAccess = this.isOdpUser || childPage.urlSuffix.indexOf('orders') < 0;

            if (customerNumber && hasAccess) {
              childPage.urlIdPrefix = id;
              childPage.disabled = false;
            } else {
              childPage.urlIdPrefix = null;
              childPage.disabled = true;
            }
          } else {
            childPage.urlIdPrefix = id;
            childPage.disabled = false;
          }
        });
      }
    });
    parent.showChildPages = true;
  }

  async setIsOdpUser() {
    // Determine if user has odp access.
    await this.odpService.isOdpUser().toPromise().then(isOdpUser => {
      this.isOdpUser = true;
      this.isOdpUserSet = true;
    });
  }

  hideChildPagesExcluding(excludedParentUrl: string) {
    // Assumes only 1 parent open at a time
    const openParent = this.appPages.find(x => x.showChildPages && x.url !== excludedParentUrl);
    if (openParent) {
      openParent.showChildPages = false;
    }
  }

  installPwa() {
    this.Pwa.promptEvent.prompt();
  }

  isPublicURL(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const isBladewizardPage = window.location.pathname.indexOf('bladewizard') >= 0;
    console.log('is Bladewizard: ', window.location.pathname.indexOf('bladewizard') >= 0);
    return urlParams.has('public') && isBladewizardPage;
  }

  getAppPages() {
    return [
      {
        title: 'app.home',
        url: '/product-catalog',
        icon: 'home',
        iconType: 'ion',
        childPages: [],
      },
      // {
      //   title: 'app.search',
      //   url: '/search',
      //   icon: 'search',
      //   iconType: 'ion',
      //   childPages: [],
      // },
      // {
      //   title: 'app.tasks',
      //   url: '/tasks',
      //   icon: this.taskIcon,
      //   iconType: 'img',
      //   notifications: null,
      //   notificationType: this.notificationTypes.Task,
      //   childPages: [],
      // },
      {
        title: 'app.customers',
        url: '/customers',
        icon: this.customerIcon,
        iconType: 'img',
        //childPages: [
          // {
          //   title: 'Interactions',
          //   urlPrefix: '/customer',
          //   urlSuffix: '/interactions',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: this.iconFolder + '/interactions.svg',
          //   iconType: 'img',
          //   disabled: true
          // },
          // {
          //   title: 'Sales',
          //   urlPrefix: '/customer',
          //   urlSuffix: '/sales',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: this.iconFolder + '/sales.svg',
          //   iconType: 'img',
          //   disabled: true
          // },
          // {
          //   title: 'RGA',
          //   urlPrefix: '/customer',
          //   urlSuffix: '/rgas',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: this.iconFolder + '/rga.svg',
          //   iconType: 'img',
          //   disabled: true
          // },
          // {
          //   title: 'Notes',
          //   urlPrefix: '/customer',
          //   urlSuffix: '/notes',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: this.iconFolder + '/notes.svg',
          //   iconType: 'img',
          //   disabled: true
          // }
        //],
        //showChildPages: false
      },
      {
        title: 'Orders',
        click: () => this.goToOrders(),
        icon: this.ordersIcon,
        iconType: 'img',
        disabled: true
      },
      {
        title: 'Quotes',
        click: () => this.goToQuotes(),
        icon: 'paper',
        iconType: 'ion',
        childPages: [],
        disabled: true
      },
      {
        title: 'Change Customer',
        url: '/customers',
        icon: 'sync',
        iconType: 'ion',
        childPages: []
      },
      {
        title: 'app.sales',
        url: '/sales',
        icon: this.salesIcon,
        iconType: 'img',
        childPages: [],
      },
      // {
      //   title: 'Product Catalog',
      //   url: '/product-catalog',
      //   icon: 'cart',
      //   iconType: 'ion',
      //   childPages: [],
      // },
      {
        title: 'app.tools',
        url: '',
        psuedoUrl: '/tools-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/tools-expandable');
        },
        icon: this.toolsIcon,
        iconType: 'img',
        childPages: [
          {
            title: 'Bladewizards',
            urlPrefix: '/bladewizard',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.toolsIcon,
            iconType: 'img',
            disabled: false
          },
          // {
          //   title: 'Trial Results',
          //   urlPrefix: '/trial-results',
          //   urlSuffix: '',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: 'flask',
          //   iconType: 'ion',
          //   disabled: false
          // },
          // {
          //   title: 'Selling Tips',
          //   urlPrefix: '/selling-tips',
          //   urlSuffix: '',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: 'bulb',
          //   iconType: 'ion',
          //   disabled: false
          // },
          // {
          //   title: 'Saw Information',
          //   urlPrefix: '/saw-info',
          //   urlSuffix: '',
          //   urlIdPrefix: '',
          //   urlIdSuffix: '',
          //   icon: 'information-circle-outline',
          //   iconType: 'ion',
          //   disabled: false
          // },
          {
            title: 'Competitive Cross Reference',
            urlPrefix: '/competitive-cross-reference',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'swap',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'Savings Solutions',
            urlPrefix: '/value-calculator',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'calculator',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'Chipload Calculator',
            urlPrefix: '/chipload-calculator',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.chipLoad,
            iconType: 'img',
            disabled: false
          },
          {
            title: 'End User Trial Tests',
            urlPrefix: '',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            href: this.trialResultsURL,
            icon: 'clipboard',
            iconType: 'ion',
            disabled: false
          },
        ],
        showChildPages: false
      },
      {
        title: 'app.toolHistory',
        url: '',
        psuedoUrl: '/toolHistory-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/toolHistory-expandable');
        },
        icon: 'list',
        iconType: 'ion',
        childPages: [
          {
            title: 'app.bwHistory',
            urlPrefix: '/tool-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.toolsIcon,
            iconType: 'img',
            disabled: false
          },
          {
            title: 'app.vcHistory',
            urlPrefix: '/value-calculator-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'calculator',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'app.ccrHistory',
            urlPrefix: '/competitive-cross-reference-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'swap',
            iconType: 'ion',
            disabled: false
          }
        ],
        showChildPages: false
        // disabled: true
      },
      {
        title: 'Marketing',
        // url: '/marketing',
        url: '',
        psuedoUrl: '/marketing-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/marketing-expandable');
        },
        icon: 'albums',
        iconType: 'ion',
        childPages: [
          {
            title: 'Saw Information',
            urlPrefix: '/saw-information',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'information-circle-outline',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'app.training',
            urlPrefix: '/help',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'help-circle-outline',
            iconType: 'ion',
            childPages: [],
          },
        ],
      },
      {
        title: 'app.help',
        url: '/help',
        icon: 'help-circle-outline',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'app.support',
        url: '/support',
        icon: this.supportIcon,
        iconType: 'img',
        childPages: [],
      },
      // {
      //   title: '(Attachments)',
      //   url: '/list',
      //   icon: 'list'
      // },
      // {
      //   title: 'app.settings',
      //   url: '/settings/',
      //   icon: 'settings',
      //   iconType: 'ion',
      //   childPages: [],
      // }
    ];
  }

  getEndUserPages() {
    return [
      {
        title: 'app.home',
        url: '/product-catalog',
        icon: 'home',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'app.tools',
        url: '',
        psuedoUrl: '/tools-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/tools-expandable');
        },
        icon: this.toolsIcon,
        iconType: 'img',
        childPages: [
          {
            title: 'Bladewizards',
            urlPrefix: '/bladewizard',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.toolsIcon,
            iconType: 'img',
            disabled: false
          },
          {
            title: 'Competitive Cross Reference',
            urlPrefix: '/competitive-cross-reference',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'swap',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'Chipload Calculator',
            urlPrefix: '/chipload-calculator',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.chipLoad,
            iconType: 'img',
            disabled: false
          }
        ],
        showChildPages: false
      },
      {
        title: 'app.toolHistory',
        url: '',
        psuedoUrl: '/toolHistory-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/toolHistory-expandable');
        },
        icon: 'list',
        iconType: 'ion',
        childPages: [
          {
            title: 'app.bwHistory',
            urlPrefix: '/tool-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.toolsIcon,
            iconType: 'img',
            disabled: false
          },
          {
            title: 'app.ccrHistory',
            urlPrefix: '/competitive-cross-reference-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'swap',
            iconType: 'ion',
            disabled: false
          }
        ],
        showChildPages: false
        // disabled: true
      },
      {
        title: 'Distributor Locator',
        url: '/distributor-locator',
        icon: 'navigate',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'app.help',
        url: '/help',
        icon: 'help-circle-outline',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'app.support',
        url: '/support',
        icon: this.supportIcon,
        iconType: 'img',
        childPages: [],
      },
    ];
  }

  goToOrders() {
    if (this.allowOrdersQuotesForCurrentCustomer()) {
      this.sharedDataService.setValue(DictKey.OrderType, 1);
      this.router.navigateByUrl(`customer/${this.currentCustomer.SalesforceId}/orders`);
    }
  }

  goToQuotes() {
    if (this.allowOrdersQuotesForCurrentCustomer()) {
      this.sharedDataService.setValue(DictKey.OrderType, 0);
      this.router.navigateByUrl(`customer/${this.currentCustomer.SalesforceId}/orders`);
    }
  }

  goToDistQuotes() {
    const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    if (info && info.BillToNumber) {
      this.sharedDataService.setValue(DictKey.OrderType, 0);
      this.router.navigateByUrl(`customer/${info.SalesforceId}/orders`);
    }
  }

  goToDistOrders() {
    const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    if (info && info.BillToNumber) {
      this.sharedDataService.setValue(DictKey.OrderType, 1);
      this.router.navigateByUrl(`customer/${info.SalesforceId}/orders`);
    }
  }

  getTrialResultsUrl() {
    this.trialResultsURL = this.behaviorSubjectService.trialResultsUrlSubject.getValue();
  }

  getDistributorPages() {
    return [
      {
        title: 'app.home',
        url: '/distributor-home',
        icon: 'home',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'My Account',
        url: '/my-account',
        icon: 'contact',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'Quotes',
        click: () => this.goToDistQuotes(),
        icon: 'paper',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'Orders',
        click: () => this.goToDistOrders(),
        icon: 'list-box',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'Buy Again',
        url: '/buy-again',
        icon: 'card',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'Promotions',
        url: '/promotions',
        icon: 'megaphone',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'app.tools',
        url: '',
        psuedoUrl: '/tools-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/tools-expandable');
        },
        icon: this.toolsIcon,
        iconType: 'img',
        childPages: [
          {
            title: 'Bladewizards',
            urlPrefix: '/bladewizard',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.toolsIcon,
            iconType: 'img',
            disabled: false
          },
          {
            title: 'Competitive Cross Reference',
            urlPrefix: '/competitive-cross-reference',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'swap',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'Savings Solutions',
            urlPrefix: '/value-calculator',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'calculator',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'Chipload Calculator',
            urlPrefix: '/chipload-calculator',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.chipLoad,
            iconType: 'img',
            disabled: false
          },
        ],
        showChildPages: false
      },
      {
        title: 'app.toolHistory',
        url: '',
        psuedoUrl: '/toolHistory-expandable',
        click: ($event) => {
          $event.stopPropagation();
          return this.expandCollapseChildPages('/toolHistory-expandable');
        },
        icon: 'list',
        iconType: 'ion',
        childPages: [
          {
            title: 'app.bwHistory',
            urlPrefix: '/tool-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: this.toolsIcon,
            iconType: 'img',
            disabled: false
          },
          {
            title: 'app.vcHistory',
            urlPrefix: '/value-calculator-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'calculator',
            iconType: 'ion',
            disabled: false
          },
          {
            title: 'app.ccrHistory',
            urlPrefix: '/competitive-cross-reference-history',
            urlSuffix: '',
            urlIdPrefix: '',
            urlIdSuffix: '',
            icon: 'swap',
            iconType: 'ion',
            disabled: false
          }
        ],
        showChildPages: false
        // disabled: true
      },
      {
        title: 'RGA',
        url: '/rgas',
        icon: 'return-left',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'app.help',
        url: '/help',
        icon: 'help-circle-outline',
        iconType: 'ion',
        childPages: [],
      },
      {
        title: 'Contact Us/Support',
        url: '/support',
        icon: this.supportIcon,
        iconType: 'img',
        childPages: [],
      }
    ];
  }

  redirectHome() {
    this.sharedService.redirectHome(this.publicURL);
  }

  // getRepPages() {
  //   return [
  //     {
  //       title: 'app.home',
  //       url: '/home',
  //       icon: 'home',
  //       iconType: 'ion',
  //       childPages: [],
  //     },
  //     // {
  //     //   title: 'app.search',
  //     //   url: '/search',
  //     //   icon: 'search',
  //     //   iconType: 'ion',
  //     //   childPages: [],
  //     // },
  //     {
  //       title: 'app.tasks',
  //       url: '/tasks',
  //       icon: this.iconFolder + '/tasks.svg',
  //       iconType: 'img',
  //       notifications: null,
  //       notificationType: this.notificationTypes.Task,
  //       childPages: [],
  //     },
  //     {
  //       title: 'app.customers',
  //       url: '/customers',
  //       icon: this.iconFolder + '/customers.svg',
  //       iconType: 'img',
  //       childPages: [
  //         {
  //           title: 'Interactions',
  //           urlPrefix: '/customer',
  //           urlSuffix: '/interactions',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: this.iconFolder + '/interactions.svg',
  //           iconType: 'img',
  //           disabled: true
  //         },
  //         {
  //           title: 'Orders',
  //           urlPrefix: '/customer',
  //           urlSuffix: '/orders',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: this.iconFolder + '/orders.svg',
  //           iconType: 'img',
  //           disabled: true
  //         },
  //         // {
  //         //   title: 'Sales',
  //         //   urlPrefix: '/customer',
  //         //   urlSuffix: '/sales',
  //         //   urlIdPrefix: '',
  //         //   urlIdSuffix: '',
  //         //   icon: this.iconFolder + '/sales.svg',
  //         //   iconType: 'img',
  //         //   disabled: true
  //         // },
  //         {
  //           title: 'RGA',
  //           urlPrefix: '/customer',
  //           urlSuffix: '/rgas',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: this.iconFolder + '/rga.svg',
  //           iconType: 'img',
  //           disabled: true
  //         },
  //         {
  //           title: 'Notes',
  //           urlPrefix: '/customer',
  //           urlSuffix: '/notes',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: this.iconFolder + '/notes.svg',
  //           iconType: 'img',
  //           disabled: true
  //         }
  //       ],
  //       showChildPages: false
  //     },
  //     {
  //       title: 'app.sales',
  //       url: '/sales',
  //       icon: this.iconFolder + '/sales.svg',
  //       iconType: 'img',
  //       childPages: [],
  //     },
  //     {
  //       title: 'app.tools',
  //       url: '',
  //       psuedoUrl: '/tools-expandable',
  //       click: ($event) => {
  //         $event.stopPropagation();
  //         return this.expandCollapseChildPages('/tools-expandable');
  //       },
  //       icon: this.iconFolder + '/tools.svg',
  //       iconType: 'img',
  //       childPages: [
  //         {
  //           title: 'Bladewizards',
  //           urlPrefix: '/bladewizard',
  //           urlSuffix: '',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: this.iconFolder + '/tools.svg',
  //           iconType: 'img',
  //           disabled: false
  //         },
  //         // {
  //         //   title: 'Trial Results',
  //         //   urlPrefix: '/trial-results',
  //         //   urlSuffix: '',
  //         //   urlIdPrefix: '',
  //         //   urlIdSuffix: '',
  //         //   icon: 'flask',
  //         //   iconType: 'ion',
  //         //   disabled: false
  //         // },
  //         // {
  //         //   title: 'Selling Tips',
  //         //   urlPrefix: '/selling-tips',
  //         //   urlSuffix: '',
  //         //   urlIdPrefix: '',
  //         //   urlIdSuffix: '',
  //         //   icon: 'bulb',
  //         //   iconType: 'ion',
  //         //   disabled: false
  //         // },
  //         // {
  //         //   title: 'Saw Information',
  //         //   urlPrefix: '/saw-info',
  //         //   urlSuffix: '',
  //         //   urlIdPrefix: '',
  //         //   urlIdSuffix: '',
  //         //   icon: 'information-circle-outline',
  //         //   iconType: 'ion',
  //         //   disabled: false
  //         // },
  //         {
  //           title: 'Competitive Cross Reference',
  //           urlPrefix: '/competitive-cross-reference',
  //           urlSuffix: '',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: 'swap',
  //           iconType: 'ion',
  //           disabled: false
  //         },
  //         {
  //           title: 'Savings Solutions',
  //           urlPrefix: '/value-calculator',
  //           urlSuffix: '',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: 'calculator',
  //           iconType: 'ion',
  //           disabled: false
  //         },
  //       ],
  //       showChildPages: false
  //     },
  //     {
  //       title: 'app.toolHistory',
  //       url: '',
  //       psuedoUrl: '/toolHistory-expandable',
  //       click: ($event) => {
  //         $event.stopPropagation();
  //         return this.expandCollapseChildPages('/toolHistory-expandable');
  //       },
  //       icon: 'list',
  //       iconType: 'ion',
  //       childPages: [
  //         {
  //           title: 'app.bwHistory',
  //           urlPrefix: '/tool-history',
  //           urlSuffix: '',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: this.iconFolder + '/tools.svg',
  //           iconType: 'img',
  //           disabled: false
  //         },
  //         {
  //           title: 'app.vcHistory',
  //           urlPrefix: '/value-calculator-history',
  //           urlSuffix: '',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: 'calculator',
  //           iconType: 'ion',
  //           disabled: false
  //         },
  //       ],
  //       showChildPages: false
  //       // disabled: true
  //     },
  //     {
  //       title: 'Marketing',
  //       // url: '/marketing',
  //       url: '',
  //       psuedoUrl: '/marketing-expandable',
  //       click: ($event) => {
  //         $event.stopPropagation();
  //         return this.expandCollapseChildPages('/marketing-expandable');
  //       },
  //       icon: 'albums',
  //       iconType: 'ion',
  //       childPages: [
  //         {
  //           title: 'Saw Information',
  //           urlPrefix: '/saw-information',
  //           urlSuffix: '',
  //           urlIdPrefix: '',
  //           urlIdSuffix: '',
  //           icon: 'information-circle-outline',
  //           iconType: 'ion',
  //           disabled: false
  //         }
  //       ],
  //     },
  //     {
  //       title: 'app.support',
  //       url: '/support',
  //       icon: this.iconFolder + '/support.svg',
  //       iconType: 'img',
  //       childPages: [],
  //     },
  //     // {
  //     //   title: '(Attachments)',
  //     //   url: '/list',
  //     //   icon: 'list'
  //     // },
  //     {
  //       title: 'app.settings',
  //       url: '/settings/',
  //       icon: 'settings',
  //       iconType: 'ion',
  //       childPages: [],
  //     }
  //   ];
  // }

}
