import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { PageInfo } from 'src/app/models/PageInfo';
import { SharedDataService, DictKey } from 'src/app/services/shared-data.service';
import { TaskService } from 'src/app/services/task.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  @Input() taskNotificationCount = 0;
  @Input() shouldHideOnThisPage = false;
  private subscription: any;
  isPublicURL = false;

  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private sharedDataService: SharedDataService,
    private menuService: MenuService
  ) { }
  

  ngOnInit() {
    this.isPublicURL = this.menuService.isPublicURL();
  }

  setTaskNotificationCount(item) {
    this.taskNotificationCount = item;
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

  goToDistOrders() {
    try {
      const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
      if (info && info.BillToNumber) {
        this.sharedDataService.setValue(DictKey.OrderType, 1);
        this.router.navigateByUrl(`customer/${info.SalesforceId}/orders`);
      }
    } catch (e) {
    
    }
  }

}
