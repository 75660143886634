import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { EndUser } from 'src/app/models/EndUser';
import { CustomerType } from 'src/app/models/enums/CustomerType';
import { PageInfo } from 'src/app/models/PageInfo';
import { CustomerService } from 'src/app/services/customer.service';
import { DictKey, SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-add-customer-value-calculator',
  templateUrl: './add-customer-value-calculator.page.html',
  styleUrls: ['./add-customer-value-calculator.page.scss'],
})
export class AddCustomerValueCalculatorPage implements OnInit {

  customerForm: FormGroup;
  saving = false;
  submitAttempt = false;
  distributorId: number;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private modalController: ModalController,
    private sharedDataService: SharedDataService
  ) {
    this.customerForm = this.formBuilder.group({
      // Customer fields
      Name: [null, Validators.required],
   });
  }

  ngOnInit() {
    // this.distributorId = this.getDistributorId();
    // this.customerForm.get('DistributorId').setValue(this.distributorId);
  }

  addCustomer() {
    this.submitAttempt = true;
    const name = this.customerForm.get('Name').value as string;
    name.trim();
    // if the form is valid
    if (this.customerForm.valid && name) {
      this.customerService.addSimpleAccount(name).subscribe(result => {
        this.saving = true;
          // If event adds successfully close the modal
          if (result) {
            const returnResult = { 
              Id: result,
              Name: name
            }
            this.modalController.dismiss(returnResult);
          }
        });    
    } else {
      console.log('ERRORS');
      console.log(this.customerForm)
    }
  }

  // getDistributorId(): number {
  //   const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
  //   return info.SalesAccountId;
  // }

  closeModal() {
    this.modalController.dismiss();
  }

}
