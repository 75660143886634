import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { GlobalMethodsService } from 'src/app/global-methods.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-popover-sales-drill',
  templateUrl: './popover-sales-drill.component.html',
  styleUrls: ['./popover-sales-drill.component.scss'],
})
export class PopoverSalesDrillComponent implements OnInit {
  
  callerRef: any;
  //row: any;
  groupId: string;
  groupName: string;
  customerId;
  previousFilterGroup: string;
  type: string;

  constructor(private popoverCtrl: PopoverController, public global:GlobalMethodsService) {}

  ngOnInit() {}

  // async closePopover(){
  //   const onClosedData: string = "Done with this shit";
  //   await this.popoverCtrl.dismiss(onClosedData)
  // }
  // selectUser(selectedOption: any){
  //   this.popoverCtrl.dismiss(selectedOption);
  // }

  // rowId undefined and groupNext undefined - use current area info with no grouping
  // rowId defined and groupNext undefined - go to next level on current selected row, no grouping
  // rowId defined and groupNext defined - go to next level on current selected row, use grouping
  drillIn(areaId:string, groupNext:string) {
    this.popoverCtrl.dismiss();

    // how to get arealevel and areaid?  
    //  - call to get next AreaLevel = always go down 1, use given areaId from row
    //  - call to get a grouping - if there is a current grouping, stay at same level, else go down 1
    var arealevel = this.callerRef.salesAreaPage.CurrentAreaType;
    var areaid = this.callerRef.salesAreaPage.CurrentAreaId;
    if (groupNext == undefined || this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None'){
      arealevel = this.callerRef.getNextLevelBelowName(1);
      areaid = areaId;
    }

    var groupby = groupNext ? groupNext : 'None';
    var filter1type = null;
    var filter1id = '';
    var filter2type = null;
    var filter2id = '';
    if (groupNext && this.callerRef.salesAreaPage.CurrentAreaGrouping != 'None') {
      // promote current grouping to filter1 and existing filters upward
      if (this.callerRef.salesAreaPage.Filters.length == 0){
        filter1type = this.callerRef.salesAreaPage.CurrentAreaGrouping;
        filter1id = areaId;
      }
      if (this.callerRef.salesAreaPage.Filters.length == 1) {
        filter1type = this.callerRef.salesAreaPage.Filters[0].FilterAreaType;
        filter1id = this.callerRef.salesAreaPage.Filters[0].FilterId;
        filter2type = this.callerRef.salesAreaPage.CurrentAreaGrouping;
        filter2id = areaId;
      }
    }
    this.callerRef.getSalesAreaPage(arealevel, areaid, groupby, filter1type, filter1id, filter2type, filter2id);
  }

  // discards current filters and runs new grouping
  changeDrill(groupNext:string){
    this.popoverCtrl.dismiss();
    var arealevel = this.callerRef.salesAreaPage.CurrentAreaType;
    var areaid = this.callerRef.salesAreaPage.CurrentAreaId;
    var groupby = groupNext ? groupNext : null;
    this.callerRef.getSalesAreaPage(arealevel, areaid, groupby, null, '', null, '');
  }

  backupGrouping(){
    this.popoverCtrl.dismiss();
    var arealevel = this.callerRef.salesAreaPage.CurrentAreaType;
    var areaid = this.callerRef.salesAreaPage.CurrentAreaId;
    var groupby = this.callerRef.salesAreaPage.Filters[this.callerRef.salesAreaPage.Filters.length - 1].FilterAreaType;
    // filter1 remains, if there were 2 filters
    var filter1 = this.callerRef.salesAreaPage.Filters[0].FilterAreaType;
    var filter1id = this.callerRef.salesAreaPage.Filters[0].FilterId;
    if (this.callerRef.salesAreaPage.Filters.length < 2){
      filter1 = null;
      filter1id = '';
    } else {

    }
    
    this.callerRef.getSalesAreaPage(arealevel, areaid, groupby, filter1, filter1id, null, '');
  }

  removeFilter(index:number){
    this.popoverCtrl.dismiss();
    var arealevel = this.callerRef.salesAreaPage.CurrentAreaType;
    var areaid = this.callerRef.salesAreaPage.CurrentAreaId;
    var groupby = this.callerRef.salesAreaPage.CurrentAreaGrouping;
    var filter1 = null;
    var filter1id = '';
    if (index == 0 && this.callerRef.salesAreaPage.Filters.length > 1) {
      filter1 = this.callerRef.salesAreaPage.Filters[1].FilterAreaType;
      filter1id = this.callerRef.salesAreaPage.Filters[1].FilterId;
    } else if (index == 1){
      filter1 = this.callerRef.salesAreaPage.Filters[0].FilterAreaType;
      filter1id = this.callerRef.salesAreaPage.Filters[0].FilterId;
    }
    this.callerRef.getSalesAreaPage(arealevel, areaid, groupby, filter1, filter1id, null, '');
  }

  showGroupingOption(groupType, callerType){
    if (this.callerRef.salesAreaPage.CurrentAreaGrouping == groupType) return false;
    for (var i = 0; i < this.callerRef.salesAreaPage.Filters.length; i++){
      if (this.callerRef.salesAreaPage.Filters[i].FilterAreaType == groupType) return false;
    }
    // don't show Channel if in Customer, they are in a single channel
    if (groupType == 'Channel' && callerType == 'row' &&
      (this.callerRef.salesAreaPage.CurrentAreaGrouping == 'Customer' || 
      (this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None' && this.callerRef.salesAreaPage.CurrentAreaType == 'Territory'))) return false;
    // don't show Customer if Row is Territory AreaLevel
    if (callerType == 'row' && groupType == 'Customer' && this.callerRef.salesAreaPage.CurrentAreaType == 'Salesperson' && this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None') return false;
    // don't show Customer if in Customer AreaLevel
    if (groupType == 'Customer' && this.callerRef.salesAreaPage.CurrentAreaType == 'Territory' && this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None') return false;
    return true;
  }

  hasOtherParallelAreas(){
    return this.callerRef.salesAreaPage.OtherParallelAreas ? this.callerRef.salesAreaPage.OtherParallelAreas.length > 0 : false;
  }

  showMainDrillDown(){
    var nextLevel = this.callerRef.getNextLevelBelowName(2);
    var noFilter = !this.hasFilter();
    var noGrouping = this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None';
    return !this.hasFilter() && this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None'
    && nextLevel != undefined;
  }

  previousFilterForGroup(){
    var isGroup = this.type == "Group";
    var hasFilter = this.hasFilter();
    return this.type == "Group" && this.hasFilter()  }

  previousFilter(){
    return this.callerRef.salesAreaPage.Filters.length == 2 ? this.callerRef.salesAreaPage.Filters[1].FilterAreaType :
      this.callerRef.salesAreaPage.Filters.length == 1 ? this.callerRef.salesAreaPage.Filters[0].FilterAreaType :
      null;
  }

  hasFilter(){
    return this.callerRef.salesAreaPage.Filters.length > 0;
  }

  isCustomerRow(){
    if (this.callerRef.salesAreaPage.CurrentAreaGrouping == 'Customer') return true;
    if (this.callerRef.salesAreaPage.CurrentAreaType == 'Territory' && this.callerRef.salesAreaPage.CurrentAreaGrouping == 'None' ) return true;
    return false;
  }

}
