import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { TaskService } from 'src/app/services/task.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription, Observable, of } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { Task } from 'src/app/models/Task';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { TaskStatus } from 'src/app/models/enums/TaskStatus';
import { catchError } from 'rxjs/operators';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { SettingsService } from 'src/app/services/settings.service';
import { MultiSelectService } from 'src/app/services/multi-select.service';

class Person {
  public address: string;
  public name: string;
}

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.page.html',
  styleUrls: ['./add-task.page.scss'],
})
export class AddTaskPage implements OnInit {
  @Input() existingTask: Task = null;
  @Input() editable = false;

  public TaskStatus = TaskStatus;
  task: Task;
  taskForm: FormGroup;
  submitAttempt: boolean;
  people: Person[];
  selectedPeople: Person[] = [];
  peopleSubscription: Subscription;
  public title = 'Add Task';
  maxDate = null;
  saving = false;
  public getPeopleFiltered;

  // personForm: FormGroup;
  personNameControl: FormControl;
  personAddressControl: FormControl;
  isNew = true;
  @ViewChild('personComponent') personComponent: IonicSelectableComponent;

  personForm: FormGroup;
  assignerName: string;

  constructor(
    private modalController: ModalController,
    private taskService: TaskService,
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private settingsService: SettingsService,
    private alertController: AlertController,
    public multiSelectService: MultiSelectService,
    private titleCasePipe: TitleCasePipe
  ) {
    this.taskForm = this.formBuilder.group({
      Id: [''],
      Subject: ['', Validators.required],
      Body: [''],
      DueDate: [null],
      Contacts: [''],
      Status: ['Not Started', Validators.required],
      Assigner: [null],
      AssignedUser: [null]
    });

    // Create port form that will be used to add or save port.
    this.personNameControl = this.formBuilder.control(null, Validators.required);
    this.personAddressControl = this.formBuilder.control(null, Validators.required);
    this.personForm = this.formBuilder.group({
      personName: this.personNameControl,
      personAddress: this.personAddressControl
    });
   }

  ngOnInit() {
    this.setMaxDateOption();
    this.isNew = true;
    this.title = 'Add Task';
    if (this.existingTask) {
      if (this.existingTask.Status != 'Not Started' && this.existingTask.Status != 'Completed' && this.existingTask.Status != 'In Progress') {
        this.existingTask.Status = 'Not Started';
      }
      if (this.existingTask.Assigner) {
        const userSettings = this.settingsService.getUserSettings();
        const name = userSettings.name.split('\\');
        this.assignerName = this.titleCasePipe.transform(name[name.length -1]);
        this.existingTask.Assigner = this.titleCasePipe.transform(this.existingTask.Assigner);
      }
      this.taskForm.patchValue(this.existingTask);
      this.isNew = false;
      if (this.editable) {
        this.title = 'Update Task';
      } else {
        this.title = 'View Task';
        this.taskForm.disable();
      }
      if (this.existingTask.Contacts) {
        this.eventService.getUsersByEmails(this.existingTask.Contacts, this.getTimezoneName())
          .subscribe(result => {
            this.selectedPeople = result.people;
          });
      }
    } else {
      this.assignCurrentUserToTask();
    }
    this.getPeopleFiltered = (searchText) => this.eventService.getPeopleFiltered(searchText, this.getTimezoneName())
  }

  getTimezoneName() {
             // https://stackoverflow.com/questions/17348807/how-to-translate-between-windows-and-iana-time-zones
            // need to use IANA time zone and convert because devices pass
            // as example: Eastern Daylight instead of Eastern Standard
            // Exchange server doesn't recognized Easter Daylight Time
            return Intl.DateTimeFormat().resolvedOptions().timeZone;

  // https://stackoverflow.com/questions/9772955/how-can-i-get-the-timezone-name-in-javascript
/*     const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });
  
    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      
      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
  
    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    } */
}

  setMaxDateOption() {
    const twoYearsAhead = new Date();
    twoYearsAhead.setFullYear(twoYearsAhead.getFullYear() + 2);
    this.maxDate = this.datePipe.transform(twoYearsAhead, 'yyyy-MM-dd');
  }

  closeModal(retVal) {
    this.modalController.dismiss({
      'reload': retVal
    });
  }

  submit() {
    this.taskService.addTask(null)
      .subscribe(X => {});
  }

  addUpdateTask() {
    this.submitAttempt = true;
    if (this.taskForm.valid) {
      this.saving = true;
      this.taskForm.get('Contacts').setValue(this.selectedPeople !== undefined ? this.selectedPeople.map(x => x.address) : null);
      if (this.isNew) {
        this.taskService.addTask(this.taskForm.getRawValue())
        .pipe(catchError(this.onCaughtError))
        .subscribe(X => {
            this.afterTaskRequest();
          });
      } else {
        this.taskService.updateTask(this.taskForm.getRawValue())
        .subscribe(X => {
          this.afterTaskRequest();
        });
      }
    } else {
      console.log(this.taskForm.errors);
    }
  }

  afterTaskRequest() {
    this.taskService.refreshTaskNotificationCount();
    this.closeModal(true);
  }

  onCaughtError = (error: any): Observable<any> => {
    console.log(error);
    this.saving = false;
    if (error.status == 400) {
      alert('Error adding task for the following addresses: ' + error.error.Message);
    }
    this.taskService.refreshTaskNotificationCount();
    return of(null);
  }

  onAddPerson(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.personNameControl.reset();
    this.personAddressControl.reset();

    // Show form.
    event.component.showAddItemTemplate();
  }

  addPerson() {
    // Create person.
    const person = {
      name: this.personNameControl.value,
      address: this.personAddressControl.value
    };

    // Add port to the top of list.
    this.personComponent.addItem(person).then(() => {
      // this.personComponent.search(person.name);
    });

    // Clean form.
    this.personNameControl.reset();
    this.personAddressControl.reset();

    // Show list.
    this.personComponent.hideAddItemTemplate();
  }

  assignCurrentUserToTask() {
    const userSettings = this.settingsService.getUserSettings();
    this.eventService.getPeopleFiltered(userSettings.email, this.getTimezoneName()).subscribe(people => {
      if (people) {
        this.selectedPeople = [people[0]];
      }
    });
  }

  async deleteTask() {
    const alert = await this.alertController.create({
      header: 'Confirmation',
      message: 'Are you sure you want to delete this task?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.saving = true;
            this.taskService.deleteTask(this.existingTask).subscribe(result => {
              this.saving = false;
              // If event deletes successfully close the modal
              if (result) {
                this.afterTaskRequest();
              }
            });
          }
        }
      ]
    });

    await alert.present();
  }

  onPersonSelect(event) {
    if (this.selectedPeople.indexOf(event.item) < 0) {
      this.selectedPeople.push(event.item);
    }
    this.multiSelectService.removeFromSearchResultsNG(event, this.personComponent, 'address');
  }

}
