import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ModalController } from '@ionic/angular';

import { AddCustomerPage } from 'src/app/pages/modals/add-customer/add-customer.page';
import { CustomerListItem } from 'src/app/models/CustomerListItem';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerType } from 'src/app/models/enums/CustomerType';
import { DictKey, SharedDataService } from 'src/app/services/shared-data.service';
import { SettingsService } from 'src/app/services/settings.service';
import { PageInfo } from 'src/app/models/PageInfo';
import { CustomerBasicProperties } from 'src/app/models/CustomerBasicProperties';
import { AddCustomerValueCalculatorPage } from 'src/app/pages/modals/add-customer-value-calculator/add-customer-value-calculator.page';

@Component({
    selector: 'app-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss'],
})

export class CustomerSearch implements OnInit, AfterViewInit {
    customerType: string = "2";
    searchText = '';
    pendingDataCalls = 0;
    isDistributor = false;
    isValueCalculator = false;
    customerId = null;
    isBladewizardPage = false;

    @Output() customerSelectedEvent = new EventEmitter<CustomerBasicProperties>();
    @Input() addCustomerEnabled = false;
    @Input() allowEndUserSearch = true;

    public customers: CustomerListItem[] = [];
    distributorEndUsers: CustomerListItem[];

    constructor(
        private router: Router,
        private customerService: CustomerService,
        private activatedRoute: ActivatedRoute,
        private modalController: ModalController,
        private settingsService: SettingsService,
        private sharedDataService: SharedDataService
    ) { }

    ngOnInit() {
        this.isValueCalculatorPage();
        this.isBladewizardPage = this.isBladewizard();
        this.isDistributor = this.settingsService.isDistributor();
        // if (this.isDistributor && !this.isValueCalculatorPage() && !this.isBladewizardPage) {
        //     const info: PageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
        //     if (info) {
        //         this.selectCustomerFromPageInfo(info);
        //     } else {
        //         this.sharedDataService.getEmitter(DictKey.AppPageInfo).subscribe((pageInfo: PageInfo) => {
        //             this.selectCustomerFromPageInfo(pageInfo);
        //         });
        //     }
        // }
        if (this.isDistributor) {
            this.customerType = "1";
        } 
        
        this.getCustomerType();
    }

    ngAfterViewInit(): void {
        if (!this.allowEndUserSearch && !this.isDistributor) {
            this.customerType = "2";
        }
    }

    selectCustomerFromPageInfo(info: PageInfo) {
        this.selectCustomer({
            Id: info.SalesAccountId,
            CustomerNumber: info.OdpCustomerNumber,
            Name: info.OdpCustomerName,
            BillToNumber: info.BillToNumber
        });
    }

    isDistributorBladewizard() {
        if (this.isBladewizard() && this.isDistributor) {
            this.customerType = "1";
            return true;
        }
        return false;
    }

    isBladewizard(): boolean {
        if (document.URL.includes('bladewizard')) {
            return true;
        } else {
            return false;
        }
    }

    isValueCalculatorPage() {
        if ( document.URL.includes('value-calculator') ) {
            this.isValueCalculator = true;
            this.customerType = "1";
            return true;
        }
        return false;
    }

    async addCustomer() {
        const modal = await this.modalController.create({
            component: this.isValueCalculator || (this.isDistributor) ? AddCustomerValueCalculatorPage : AddCustomerPage,
            componentProps: {
                'customerType': this.convertCustomerType(this.customerType)
            }
        });
        modal.onDidDismiss().then((result) => {
            if (result.data.reload) {
            }
            if (result && (this.isValueCalculator || this.isBladewizardPage)) {
                this.customerId = result.data.Id;
                this.getCustomers(result.data.Name, this.convertCustomerType(this.customerType), this.selectCustomerById.bind(this));
            }
        });
        modal.present();
    }

    selectCustomerById() {
        var customer = this.customers.find(c => c.Id == this.customerId);
        this.selectCustomer(customer);
    }

    convertCustomerType(id: number | string): CustomerType {
        switch (id) {
            case 0:
            case '0':
                return CustomerType.RepAgency;
            case 1:
            case '1':
                return CustomerType.EndUser;
            case 2:
            case '2':
                return CustomerType.Distributor;
            default:
                throw RangeError(`${id} not known value for CustomerType enumeration.`);
        }
    }

    customerAdditionalInfo(customer: any): string {
        let result = '(';
        if (customer.CustomerNumber) {
            result = result + customer.CustomerNumber + ' ';
        }
        if (customer.City) {
            result = result + customer.City;
            if (customer.State) {
                result = result + ', ' + customer.State;
            }
        }
        result = result + ')';
        return result;
    }

    filterCustomers(ev) {
        // set val to the value of the ev target
        const val = ev.target.value;
        // if the value is an empty string don't filter the items
        if (val && val.trim() !== '') {
            this.getCustomers(val, this.convertCustomerType(this.customerType));
            this.searchText = val;
        } else {
            this.customers = [];
            this.pendingDataCalls = 0;
        }
    }

    getCustomerType() {
        const customerType = this.activatedRoute.snapshot.queryParamMap.get('type');
        if (this.isValueCalculator || this.isDistributor) {
            this.setCustomerType(1);
        } else if (customerType && Number.parseInt(customerType) >= 0) {
            this.setCustomerType(Number.parseInt(customerType));
            this.customerType = customerType;
        } else {
            this.setCustomerType(2);
        }
     }

    getCustomers(term: string, type: CustomerType, callback?) {
        this.pendingDataCalls++;
        if (this.isDistributor && !this.distributorEndUsers) {
            this.customerService.getMyEndUsers()
                .subscribe(customers => {
                    this.afterGetCustomers(term, customers, callback);
                })
        } else if (this.isDistributor) {
            this.afterGetCustomers(term, this.distributorEndUsers, callback);
        } else {
            this.customerService.searchCustomers(term, type)
                .subscribe(customers => {
                    this.afterGetCustomers(null, customers, callback);
                }
            );
        }
    }

    afterGetCustomers(term: string, customers: CustomerListItem[], callback) {
        if (this.isDistributor) {
            term = term.toLowerCase();
            if (!this.distributorEndUsers) {
                this.distributorEndUsers = customers;
            }
            customers = customers.filter(x => x.CustomerNumber.toLowerCase().startsWith(term) || x.Name.toLowerCase().startsWith(term))
        }
        this.customers = customers;
        if (this.pendingDataCalls > 0) {
            this.pendingDataCalls--;
        }
        if (callback) {
            callback();
        }
    }

    selectCustomer(customer: CustomerBasicProperties) {
        this.customerSelectedEvent.emit(customer);
    }

    setCustomerType(customerType: number) {
        this.customerType = customerType.toString();
        const queryParams: Params = { type: customerType };
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
        }).then(() => {
            setTimeout(() => {
                if (this.searchText && this.searchText.trim() !== '') {
                    this.getCustomers(this.searchText, this.convertCustomerType(customerType));
                } else {
                    this.customers = [];
                }
            });
        });
    }

    showAddCustomer() {
        if (!this.isDistributor && this.isValueCalculator) {
            return false;
        } else {
            return true;
        }
    }
}
