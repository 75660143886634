import { LocalStorage } from '@ngx-pwa/local-storage';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { Observable } from 'rxjs';
import { PageInfo } from '../models/PageInfo';
import { DictKey } from './shared-data.service';
import { ProductDetails } from '../models/ProductDetails';
import { GetKitCompResults } from '../models/GetKitCompResults';
import { GetCompUseList } from '../models/GETCOMPUSELIST';
import { GetCompUserResults } from '../models/GetCompUserResults';
import { CatalogFamilyPage } from '../models/CatalogFamilyPage';
import { FamilyInfo } from '../models/Family';
import { GroupInfo } from '../models/Group';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { RelatedParts } from '../models/RelatedPart';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'Access-Control-Allow-Origin': 'http://localhost:8100'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private url: string;

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService) {
    behaviorSubjectService.morseAPISubject.subscribe(url => { this.url = url; });
  }

  getPageInfo(): Observable<PageInfo> {
    const url = `${this.url}/GetPageInfo`;
    return this.http.post<PageInfo>(url, null);
  }

  getBannerMessage(placeholder: string): Observable<string> {
    const url = `${this.url}/HomePageBannerMessage`;
    const params = new HttpParams().set('message', placeholder);
    const options = {
      headers: httpOptions.headers,
      params: params
    };
    return this.http.get<string>(url, options);
  }

  getFamilyList(): Observable<CatalogFamilyPage> {
    const url = `${this.url}/GetFamilyList`;
    return this.http.get<CatalogFamilyPage>(url, httpOptions);
  }

  getGroupList(familyCode: string): Observable<FamilyInfo[]> {
    const url = `${this.url}/GetGroupList?fam=${familyCode}`;
    return this.http.get<FamilyInfo[]>(url, httpOptions);
  }

  getProductList(familyCode: string, groupCode: string): Observable<GroupInfo[]> {
    const url = `${this.url}/GetProductList?fam=${familyCode}&grp=${groupCode}`;
    return this.http.get<GroupInfo[]>(url, httpOptions);
  }

  getProductDetails(familyCode: string, groupCode: string, productCode: string, extraParams?: any) {
    const url = `${this.url}/GetProductDetails?family=${familyCode}&group=${groupCode}&product=${productCode}`;
    const params = extraParams ? {...extraParams} : {};

    return this.http.get<ProductDetails>(url, {
      headers: httpOptions.headers,
      params: params
    });
  }

  getProductDetailsPart(partNumber: string): Observable<ProductDetails> {
    const url = `${this.url}/GetProductDetailsPart?part=${partNumber}`;
    return this.http.get<ProductDetails>(url, httpOptions);
  }

  getRelatedParts(partNumber: string, uom: string): Observable<RelatedParts[]> {
    const url = `${this.url}/GetRelatedParts?part=${partNumber}&uom=${uom}`;
    return this.http.get<RelatedParts[]>(url, httpOptions);
  }
  
  getKitComponents(partNumber: string): Observable<GetKitCompResults[]> {
    const url = `${this.url}/GetKitComponents?part=${partNumber}`;
    return this.http.get<GetKitCompResults[]>(url, httpOptions);
  }

  getKitComponentParts(kitpart: string, kitcomp: string): Observable<GetCompUserResults[]> {
    const url = `${this.url}/GetKitComponentParts`;
    const params = {};
    params['kitpart'] = kitpart;
    params['kitcomp'] = kitcomp;
    return this.http.get<GetCompUserResults[]>(url, {
      headers: httpOptions.headers,
      params: params
    });
  }

}
