import { Injectable } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectService {

  constructor() { }

  clearPreviousSearch(component: IonicSelectableComponent) {
    component.searchText = '';
    component.items = [];
  }

  removeFromSearchResults(event, component: IonicSelectableComponent, form: FormGroup, formControlName: string, idFieldName: string) {
    const input = form.get(formControlName);
    let selected = input.value;
    if (!input.value.find(x => x.address == event.item[idFieldName])) {
      (selected as any[]).push(event.item);
      input.setValue(selected);
    }
    // Remove the item from the search results since it is not in the selected results
    const items = component.items;
    const index = items.findIndex(result => result[idFieldName] == event.item[idFieldName]);
    if (index >= 0) {
      items.splice(index, 1);
    }
  }

  removeFromSearchResultsNG(event, component: IonicSelectableComponent, idFieldName: string) {
    // Remove the item from the search results since it is not in the selected results
    const items = component.items;
    const index = items.findIndex(result => result[idFieldName] == event.item[idFieldName]);
    if (index >= 0) {
      items.splice(index, 1);
    }
  }

  removeSelected(itemToRemove: any, component: IonicSelectableComponent, form: FormGroup, formControlName: string, idFieldName: string) {
    const selectedPeopleInput = form.get(formControlName);
    let selected = selectedPeopleInput.value;
    const removeIndex = (selected as any[]).findIndex(x => x[idFieldName] == itemToRemove[idFieldName]);
    selected.splice(removeIndex, 1);
    selectedPeopleInput.setValue(selected);
    component.search(component.searchText);
    component._selectedItems = selected;
  }

  removeSelectedNgModel(itemToRemove: any, component: IonicSelectableComponent, model: any, idFieldName: string) {
    let selected = model;
    const removeIndex = (selected as any[]).findIndex(x => x[idFieldName] == itemToRemove[idFieldName]);
    selected.splice(removeIndex, 1);
    model = selected;
    component.search(component.searchText);
    component._selectedItems = selected;
  }

  search(event: {
    component: IonicSelectableComponent,
    text: string
  },
  subscription: Subscription,
  selectedValues: any,
  idFieldName: string,
  getFiltered: any) {
    const text = event.text.trim().toLowerCase();
    event.component.startSearch();
    this.manageSubscriptionPreSearch(event, subscription);
    subscription = getFiltered(text).subscribe(filteredResults => {
      // Subscription will be closed when unsubscribed manually.
      if (subscription.closed) {
        return;
      }

      if (filteredResults) {
        // filter already selected from available options
        const selected = selectedValues;
        selected.map(selectedResult => {
          if (filteredResults) {
            const index = filteredResults.findIndex(result => result[idFieldName] == selectedResult[idFieldName]);
            if (index >= 0) {
              filteredResults.splice(index, 1);
            }
          }
        })
      }

      event.component.items = filteredResults;
      event.component.endSearch();
    });
  }

  manageSubscriptionPreSearch(event, subscription) {
    // Close any running subscription.
    if (subscription) {
      subscription.unsubscribe();
    }

    if (!event.text) {
      // Close any running subscription.
      if (subscription) {
        subscription.unsubscribe();
      }

      event.component.items = [];
      event.component.endSearch();
      return;
    }
  }

}
