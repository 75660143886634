import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'invoiceAmount'
})
export class InvoiceAmountPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe
  ) {}

  transform(value: number, args?: any): any {
    if ((typeof value) !== 'number') {
      return null;
    }
    if (value >= 0) {
      return this.currencyPipe.transform(value, '', '');
    } else {
      const flipped = value * -1;
      const flippedAsCurrency = this.currencyPipe.transform(flipped, '', '');
      return `(${flippedAsCurrency})`;
    }
  }

}
