/**
 * A state that may be found within a country. 
 * For example one of the 50 states of the United States.
 */
export class CountryState 
{
    public id: number; 
    public countryId: number;
    public name: string;
    public abbreviation: string;
    public StateMk_SXSTPR: string;
}