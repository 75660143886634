import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SalesCall } from 'src/app/models/SalesCall';

@Component({
  selector: 'app-add-call',
  templateUrl: './add-call.page.html',
  styleUrls: ['./add-call.page.scss'],
})
export class AddCallPage implements OnInit {

  @Input() call: SalesCall = null;
  title = "Add Sales Call";
  callForm: FormGroup;
  submitAttempt: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
  ) {
    this.callForm = this.formBuilder.group({
      // Customer fields
      // AccountId: [null, Validators.required],
      StartDate: [null, Validators.required],
      StartTime: [null, Validators.required],
      EndDate: [null, Validators.required],
      EndTime: [null, Validators.required]
    });
   }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
