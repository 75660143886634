import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/appConfig';
import { BehaviorSubjectService } from '../services/behavior-subject.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: AppConfig;

  constructor(
      private http: HttpClient,
      private behaviorSubjectManager: BehaviorSubjectService
  ) { }

  loadAppConfig() {
      return this.http.get<AppConfig>('/assets/data/appConfig.json')
          .toPromise()
          .then(data => {
              if (data.apiUrl) {
                this.behaviorSubjectManager.apiUrlSubject.next(data.apiUrl);
              }
              if (data.environment) {
                this.behaviorSubjectManager.environmentSubject.next(data.environment);
              }
              if (data.morseAPI) {
                this.behaviorSubjectManager.morseAPISubject.next(data.morseAPI);
              }
              if (data.bladeWizardAPI) {
                this.behaviorSubjectManager.bladeWizardAPISubject.next(data.bladeWizardAPI);
              }
              if (data.exchangeAPI) {
                this.behaviorSubjectManager.exchangeAPISubject.next(data.exchangeAPI);
              }
              if (data.membershipRebootAPI) {
                this.behaviorSubjectManager.membershipRebootAPISubject.next(data.membershipRebootAPI);
              }
              if (data.interactionsUrl) {
                this.behaviorSubjectManager.interactionsUrlSubject.next(data.interactionsUrl);
              }
              if (data.authUrl) {
                this.behaviorSubjectManager.authUrlSubject.next(data.authUrl);
              }
              if (data.clientId) {
                this.behaviorSubjectManager.clientIdSubject.next(data.clientId);
              }
              if (data.mobileUrl) {
                this.behaviorSubjectManager.mobileUrlSubject.next(data.mobileUrl);
              }
              if (data.odpUrl) {
                this.behaviorSubjectManager.opdUrlSubject.next(data.odpUrl);
              }
              if (data.valueCalcUrl) {
                this.behaviorSubjectManager.valueCalcUrlSubject.next(data.valueCalcUrl);
              }
              if (data.mkMorsePublicHomeUrl) {
                this.behaviorSubjectManager.mkMorsePublicHomeUrlSubject.next(data.mkMorsePublicHomeUrl);
              }
              if (data.trialResultsUrl) {
                this.behaviorSubjectManager.trialResultsUrlSubject.next(data.trialResultsUrl);
              }
              if (data.googleAnalyticsId) {
                this.behaviorSubjectManager.googleAnalyticsIdSubjectManager.next(data.googleAnalyticsId);
              }
              if (data.salesforceUrl) {
                this.behaviorSubjectManager.salesforceUrlSubject.next(data.salesforceUrl);
              }
              if (data.bladeWizardCommonAPI) {
                this.behaviorSubjectManager.bladeWizardCommonAPISubject.next(data.bladeWizardCommonAPI);
              }
              if (data.distributorLocatorUrl) {
                this.behaviorSubjectManager.distributorLocatorUrlSubject.next(data.distributorLocatorUrl);
              }
              if (data.bladewizardUrl) {
                this.behaviorSubjectManager.bladewizardUrlSubject.next(data.bladewizardUrl);
              }
              if (data.cookieYesUrl) {
                this.behaviorSubjectManager.cookieYesUrlSubject.next(data.cookieYesUrl)
              }
          });
  }

  getConfig() {
      return this.appConfig;
  }
}
