import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CustomerService } from 'src/app/services/customer.service';
import { catchError } from 'rxjs/operators';
import { Observable, empty } from 'rxjs';

@Component({
  selector: 'app-add-customer-note',
  templateUrl: './add-customer-note.page.html',
  styleUrls: ['./add-customer-note.page.scss'],
})
export class AddCustomerNotePage implements OnInit {
  customerId: number;
  saving: boolean = false;
  submitAttempt: boolean = false;

  noteForm = new FormGroup({
    note: new FormControl()
  });

  constructor(private modalController: ModalController, private customerService: CustomerService) { }

  ngOnInit() {
  }

  closeModal(retVal) {
    this.modalController.dismiss({
      'reload': retVal
    });
  }

  addNote() {
    this.submitAttempt = true;

    if (this.noteForm.valid) {
      this.saving = true;
      let note: string = this.noteForm.value['note'];
      let customerId: number = this.customerId;

      this.customerService.addCustomerNote(customerId, note)
          .pipe(catchError(this.onError))
          .subscribe(response => this.closeModal(true)); // service method doesn't actually return anything.
    }
  }

  onError(error: any): Observable<any> {
    console.log(error);
    alert(error.statusText);
    return empty();
  }
}
