import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
    ModalController,
    AlertController,
    IonSelect,
    IonTextarea
} from '@ionic/angular';
import { EventService } from 'src/app/services/event.service';
import { Event } from 'src/app/models/Event';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl
} from '@angular/forms';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription, of, Observable, concat } from 'rxjs';
import { Room } from 'src/app/models/Room';
import { DatePipe } from '@angular/common';
import { AttachmentService } from 'src/app/services/attachment.service';
import { Attachment } from 'src/app/models/Attachment';
import { SettingsService } from 'src/app/services/settings.service';
import { catchError, finalize, take } from 'rxjs/operators';
import { Sensitivity } from 'src/app/models/enums/Sensitivity';
import { CustomerListItem } from 'src/app/models/CustomerListItem';
import { CustomerService } from 'src/app/services/customer.service';
import { MultiSelectService } from 'src/app/services/multi-select.service';
import { CustomerType } from 'src/app/models/enums/CustomerType';
import { MeetingType } from 'src/app/models/MeetingType';
import { FilesComponent } from 'src/app/components/files/files.component';
// import { Validators, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

class Location {
    public address: string;
    public name: string;
}

class Person {
    public address: string;
    public name: string;
}

@Component({
    selector: 'app-add-event-modal',
    templateUrl: './add-event-modal.page.html',
    styleUrls: ['./add-event-modal.page.scss']
})
export class AddEventModalPage implements OnInit {
    attachmentsLoading: boolean;
    attachments: Attachment[];
    event: Event;
    eventForm: FormGroup;
    submitAttempt: boolean;
    title: string;
    saving = false;
    locations: Room[];
    location: Room = new Room();
    locationType = 'NotRoom';
    isMarketingEvent = false;
    meetingTypes: MeetingType[];

    people: Person[];
    peopleSubscription: Subscription;
    customersSubscription: Subscription;
    maxDate: string = null;
    descriptionHTML: any;
    eventCRMId = null;
    currentUserPerson: any;
    currentUserAddress: string;

    personForm: FormGroup;
    // locationForm: FormGroup;
    personNameControl: FormControl;
    personAddressControl: FormControl;
    // locationControl: FormControl;
    @ViewChild('personComponent') personComponent: IonicSelectableComponent;
    @ViewChild('customersComponent')
    customersComponent: IonicSelectableComponent;
    @ViewChild('filesComponent') filesComponent: FilesComponent;
    // @ViewChild('locationComponent') locationComponent: IonicSelectableComponent;

    @Input() existingEvent: any;
    searchText = '';
    public customers: CustomerListItem[] = [];
    public getPeopleFiltered: any;
    public getCustomersFiltered: any;

    constructor(
        private modalController: ModalController,
        public eventService: EventService,
        private customerService: CustomerService,
        private formBuilder: FormBuilder,
        private alertController: AlertController,
        private datePipe: DatePipe,
        private attachmentService: AttachmentService,
        private settingsService: SettingsService,
        public multiSelectService: MultiSelectService
    ) {
        this.eventForm = this.formBuilder.group({
            title: ['', Validators.required],
            description: [''],
            location: [''],
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
            selectedPeople: [null, Validators.required],
            selectedCustomers: [[]],
            meetingType: [null],
            private: [false]
        });
        // Create port form that will be used to add or save port.
        this.personNameControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.personAddressControl = this.formBuilder.control(
            null,
            Validators.required
        );
        this.personForm = this.formBuilder.group({
            personName: this.personNameControl,
            personAddress: this.personAddressControl
        });
    }

    locationChange(event: {
        component: IonicSelectableComponent;
        value: any;
    }) {}

    peopleChange(event: { component: IonicSelectableComponent; value: any }) {}

    ngOnInit() {
        this.setMaxDateOption();
        this.getLocations();
        this.getMeetingTypes();
        if (this.existingEvent) {
            this.getExistingEvent();
        } else {
            this.title = 'Add Event';
            this.event = new Event();
        }
        this.submitAttempt = false;
        this.getPeopleFiltered = searchText => {
            return this.eventService.getPeopleFiltered(searchText, this.getTimezoneName());
        };
        this.getCustomersFiltered = searchText => {
            return this.customerService.searchCustomers(
                searchText,
                CustomerType.All,
            );
        };
        this.assignCurrentUserToEvent();
    }

    
    getTimezoneName() {
            // https://stackoverflow.com/questions/17348807/how-to-translate-between-windows-and-iana-time-zones
            // need to use IANA time zone and convert because devices pass
            // as example: Eastern Daylight instead of Eastern Standard
            // Exchange server doesn't recognized Easter Daylight Time
      return Intl.DateTimeFormat().resolvedOptions().timeZone;

      // https://stackoverflow.com/questions/9772955/how-can-i-get-the-timezone-name-in-javascript
       /*  const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });
      
        // Trying to remove date from the string in a locale-agnostic way
        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
          const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
          
          // by this time `trimmed` should be the timezone's name with some punctuation -
          // trim it from both sides
          return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
      
        } else {
          // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
          return full;
        } */        
    }
  
    assignCurrentUserToEvent() {
      const userSettings = this.settingsService.getUserSettings();
      this.currentUserAddress = userSettings.email;
      this.eventService.getPeopleFiltered(userSettings.email, this.getTimezoneName()).subscribe(people => {
        const currentPeopleValue = this.eventForm.get('selectedPeople').value;
        if (people) {
          if (currentPeopleValue && !(currentPeopleValue.find(x => x.address == people[0].address))) {
              currentPeopleValue.unshift(people[0]);
              this.eventForm.get('selectedPeople').setValue(currentPeopleValue);
          } else if (!currentPeopleValue) {
              this.eventForm.get('selectedPeople').setValue([people[0]]);
          }
        }
      });
    }

    getExistingEvent() {
        if(this.existingEvent.isMarketingEvent){
            this.title = 'Marketing Event';
            this.isMarketingEvent = true;
        }
        else{
            this.title = 'Update Event';
        }        
        this.event = new Event();
        this.event.id = this.existingEvent.id;
        this.eventForm
            .get('startDate')
            .setValue(this.existingEvent.start.toISOString());
        this.eventForm
            .get('endDate')
            .setValue(this.existingEvent.end.toISOString());
        this.eventForm.get('title').setValue(this.existingEvent.title);
        this.eventForm.get('private').setValue(this.existingEvent.sensitivity);
        this.descriptionHTML = this.existingEvent.extendedProps.body;
        this.eventForm.get('description').setValue(this.descriptionHTML);
        this.eventForm
            .get('selectedPeople')
            .setValue(this.existingEvent.requiredAttendees);
            if(!this.existingEvent.isMarketingEvent){
        if (this.existingEvent.customers[0]) {
            this.eventForm
                .get('selectedCustomers')
                .setValue(this.existingEvent.customers.map(x => JSON.parse(x)));
        }
    }
        this.getMeetingDetailsFromCRM();
    }

    setMaxDateOption() {
        const twoYearsAhead = new Date();
        twoYearsAhead.setFullYear(twoYearsAhead.getFullYear() + 2);
        this.maxDate = this.datePipe.transform(twoYearsAhead, 'yyyy-MM-dd');
    }

    getLocations() {
        this.eventService.getLocations(this.getTimezoneName()).subscribe(locations => {
            this.locations = locations;
            if (this.existingEvent) {
                const location = this.existingEvent.extendedProps.location;
                if (location) {
                    const exchangeLocation = locations.find(
                        x => x.Name == location
                    );
                    // Custom location
                    if (exchangeLocation === undefined) {
                        this.location = {
                            Name: location,
                            Address: null,
                            Id: null,
                            RoutingType: null,
                            MailboxType: null
                        };
                    } else {
                        this.locationType = 'Room';
                        this.location = locations.find(x => x.Name == location);
                    }
                }
            }
        });
    }

    getMeetingDetailsFromCRM() {
        this.attachmentsLoading = true;
        this.eventService
            .getMeetingDetailsFromCRM(this.event.id)
            .pipe(catchError(this.onGetAttachmentsError))
            .subscribe(eventCRM => {
                if (eventCRM) {
                    this.filesComponent.exchangeId = this.event.id;
                    this.filesComponent.itemId = eventCRM['Id'];
                    this.eventCRMId = eventCRM['Id'];
                    this.filesComponent.getAttachments();                   
                } else {
                    // Exchange created event with no CRM equivalent
                }
            });
    }

    onGetAttachmentsError = (error: any): Observable<any> => {
        this.attachmentsLoading = false;
        return of(null);
    };

    getMeetingTypes() {
        this.eventService.getMeetingTypes().subscribe(meetingTypes => {
            this.meetingTypes = meetingTypes;
            if (this.existingEvent && this.existingEvent.extendedProps.type) {
                const meetingType = meetingTypes.find(
                    x => x.Id === this.existingEvent.extendedProps.type
                );
                this.eventForm.get('meetingType').setValue(meetingType);
            }
        });
    }

    closeModal() {
        this.modalController.dismiss();
    }

    locationTypeChange() {
        this.location = new Room();
    }

    addUpdateEvent() {
        this.submitAttempt = true;
        this.markFormGroupTouched(this.eventForm);
        // if the form is valid
        if (this.eventForm.valid) {
            // this.event.id = null;
            const meetingType = this.eventForm.get('meetingType').value;
            if (meetingType) {
                this.event.type = meetingType.Id;
            } else {
                this.event.type = null;
            }
            this.event.title = this.eventForm.get('title').value;
            this.event.body = this.eventForm.get('description').value;
            this.event.location = this.location ? this.location.Name : null;
            this.event.room = this.location;
            this.event.start = this.eventForm.get('startDate').value;
            this.event.end = this.eventForm.get('endDate').value;
            this.event.requiredAttendees = this.eventForm.get(
                'selectedPeople'
            ).value;
            this.event.customers = this.eventForm
                .get('selectedCustomers')
                .value.map(x => JSON.stringify(x));
            if (this.eventForm.get('private').value == true) {
                this.event.sensitivity = Sensitivity.Private;
            } else {
                this.event.sensitivity = Sensitivity.Normal;
            }
            this.saving = true;
            this.eventForm.disable();
            if (this.eventCRMId) {
                this.eventService.updateEvent(this.event, this.getTimezoneName()).subscribe(result => {
                    let fileSync = this.filesComponent.syncAllFiles();
                    concat(fileSync.addObservable, fileSync.removeObservable)
                        .pipe(
                            take(2),
                            finalize(() => {
                                this.saving = false;
                                // If event adds successfully close the modal
                                if (result) {
                                    this.modalController.dismiss();
                                }
                            })
                        )
                        .subscribe(() => {}, this.catchSaveError);
                }, this.catchSaveError);
            } else {
                this.eventService.addEvent(this.event, this.getTimezoneName()).subscribe(result => {
                    let fileSync = this.filesComponent.syncAllFiles(null,result);
                    concat(fileSync.addObservable, fileSync.removeObservable)
                        .pipe(
                            take(2),
                            finalize(() => {
                                this.saving = false;
                                // If event adds successfully close the modal
                                if (result) {
                                    this.modalController.dismiss();
                                }
                            })
                        )
                        .subscribe(() => {}, this.catchSaveError);
                }, this.catchSaveError);
            }
        }
    }

    catchSaveError = (error: any) => {
      this.commonErrorStuff();
      console.log('error');
      console.log(error);
    //   this.showSaveErrorModal();
    }

    catchDeleteError = (error: any) => {
        this.commonErrorStuff();
        this.showDeleteErrorModal();
    };

    private commonErrorStuff() {
        this.saving = false;
        this.eventForm.enable();
    }

    async showSaveErrorModal() {
        const alert = await this.alertController.create({
            header: 'Alert',
            // subHeader: 'Subtitle',
            message: 'Error saving.',
            buttons: ['OK']
        });

        await alert.present();
    }

    async showDeleteErrorModal() {
        const alert = await this.alertController.create({
            header: 'Alert',
            message: 'Error deleting event.',
            buttons: ['OK']
        });

        await alert.present();
    }

    async deleteEvent() {
        const alert = await this.alertController.create({
            header: 'Confirmation',
            message: 'Are you sure you want to delete this event?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                },
                {
                    text: 'Yes',
                    handler: () => {
                        this.saving = true;
                        this.eventForm.disable();
                        this.eventService
                            .deleteEvent(this.event)
                            .subscribe(result => {
                                this.saving = false;
                                // If event adds successfully close the modal
                                if (result) {
                                    this.modalController.dismiss();
                                }
                            }, this.catchSaveError);
                    }
                }
            ]
        });

        await alert.present();
    }

    setStartDate() {
        const start = this.eventForm.get('startDate').value;
        const end = this.eventForm.get('endDate').value;
        let startDate = new Date(start);
        const endDate = new Date(end);
        // if start date isn't set, set it
        if (start === '') {
            startDate = new Date(end);
            startDate.setHours(endDate.getHours() - 1);
            this.setControlValue('startDate', startDate.toISOString());
        } else if (startDate > endDate) {
            // if the start date is greater than the end date
            startDate = new Date(end);
            startDate.setHours(startDate.getHours() - 1);
            this.setControlValue('startDate', startDate.toISOString());
        }
    }

    setEndDate() {
        const start = this.eventForm.get('startDate').value;
        const end = this.eventForm.get('endDate').value;
        const startDate = new Date(start);
        let endDate = new Date(end);
        // if end date isn't set, set it
        if (end === '') {
            endDate = new Date(start);
            endDate.setHours(endDate.getHours() + 1);
            this.setControlValue('endDate', endDate.toISOString());
        } else if (startDate > endDate) {
            // if the start date is greater than the end date
            endDate = new Date(start);
            endDate.setHours(endDate.getHours() + 1);
            this.setControlValue('endDate', endDate.toISOString());
        }
    }

    setControlValue(controlName: string, value: any) {
        this.eventForm.get(controlName).setValue(value);
        setTimeout(() => {
            this.eventForm.controls[controlName].updateValueAndValidity();
        });
    }

    onAddPerson(event: { component: IonicSelectableComponent }) {
        // Clean form.
        this.personNameControl.reset();
        this.personAddressControl.reset();

        // Show form.
        event.component.showAddItemTemplate();
    }

    isInvalid(controlName: string) {
        return (
            !this.eventForm.controls[controlName].valid &&
            (this.eventForm.controls[controlName].dirty ||
                this.eventForm.controls[controlName].touched ||
                this.submitAttempt)
        );
    }

    addPerson() {
        // Create person.
        const person = {
            name: this.personNameControl.value,
            address: this.personAddressControl.value
        };

        // Add port to storage.
        // this.portService.addPort(port);

        // Add port to the top of list.
        this.personComponent.addItem(person).then(() => {
            // this.personComponent.search(person.name);
        });

        // Clean form.
        this.personNameControl.reset();
        this.personAddressControl.reset();

        // Show list.
        this.personComponent.hideAddItemTemplate();
    }

    markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            control.markAsDirty();
            control.updateValueAndValidity();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    getCustomers(term, type) {
        this.customerService
            .searchCustomers(term, type)
            .subscribe(customers => {
                this.customers = customers;
            });
    }

    customerAdditionalInfo(customer: any): string {
        let result = '(';
        if (customer.CustomerNumber) {
            result = result + customer.CustomerNumber + ' ';
        }
        if (customer.City) {
            result = result + customer.City;
            if (customer.State) {
                result = result + ', ' + customer.State;
            }
        }
        result = result + ')';
        return result;
    }

    customerTypeDisplay(customer) {
        return ' (' + customer.CustomerType[0] + ') ';
    }

    clearSelectedPeople() {
        this.eventForm.get('selectedPeople').setValue([this.currentUserPerson]);
    }
}
