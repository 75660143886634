import { ProductCrossoverImage } from './ProductCrossoverImage';

export class CompetitiveXRefSearchResult {
    Id: number;
    Title: string;

    Competitor: string;
    Competitor_Brand: string;
    Competitor_Blade_Description: string;
    Competitor_PartNumber: string;
    Product_Category: string;
    CrossOver_Type: string;
    Morse_CrossOver: string;
    Morse_ModelNumber: string;
    Morse_PartNumber: string;

    Other_Information: string;
    Packaging: string;
    Images: ProductCrossoverImage[];
    Url: string;

    // These will get set when the method below them runs. I'd make the properties functions but they keep getting called
    // and I want it to happen once.
    competitorBrandImage: ProductCrossoverImage;
    competitorImage: ProductCrossoverImage;
    morseImage: ProductCrossoverImage;

    processImages() {
        let numberOfCompetitorImages = 0, numberOfMorseImages = 0;
        for (let index = 0; index < this.Images.length; index++) {
            if (this.Images[index].type === 'Morse') {
                numberOfMorseImages++;
            } else {
                numberOfCompetitorImages++;
            }
        }

        if (numberOfCompetitorImages === 2) {
            this.competitorBrandImage = this.Images[0];
        } else {
            this.competitorBrandImage = null;
        }

        if (numberOfCompetitorImages === 2) {
            this.competitorImage = this.Images[1];
        } else if (numberOfCompetitorImages === 1) {
            this.competitorImage = this.Images[0];
        } else {
            this.competitorImage = null;
        }

        this.morseImage = this.Images.find(x => x.type === 'Morse');
    }
}
