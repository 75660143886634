import { Component, OnInit, Input, Output } from '@angular/core';
import { Attachment } from 'src/app/models/Attachment';
import { AttachmentService } from 'src/app/services/attachment.service';
import { Observable, of, concat } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {
    attachmentsLoading: boolean;
    attachments: Attachment[] = [];
    attachmentsToDelete: Attachment[] = [];
    attachmentsToAdd: File[] = [];
    @Input() itemId: number;
    @Input() exchangeId: string;
    @Input() itemType: string;

    constructor(private attachmentService: AttachmentService) {}
    onGetAttachmentsError = (error: any): Observable<any> => {
        this.attachmentsLoading = false;
        return of(null);
    };
    ngOnInit() {
        if ((this.itemId || this.exchangeId) && this.itemType)
            this.getAttachments();
    }
    getAttachments() {
        this.attachmentsLoading = true;
        this.attachmentService
            .getAttachments(this.exchangeId, this.itemId, this.itemType)
            // this.attachmentService
            //     .getFileUploadsForItem(this.itemId, this.itemType)
            .pipe(catchError(this.onGetAttachmentsError))
            .subscribe(attachments => {
                this.attachmentsLoading = false;
                this.attachments = attachments;
            });
    }

    downloadAttachment(attachment: Attachment) {
        this.attachmentService.getFile(this.itemType, this.itemId, attachment.Id, attachment.FileName);
    }

    uploadChange(event) {
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                this.attachmentsToAdd.push(event.target.files[i]);
            }
        }
    }
    scheduleDeleteAttachment(attachment: Attachment) {
        this.attachmentsToDelete.push(attachment);
    }
    unscheduleDeleteAttachment(attachment: Attachment) {
        this.attachmentsToDelete.splice(
            this.attachmentsToDelete.indexOf(attachment),
            1
        );
    }
    syncAllFiles(itemId?: number, exchangeId?: string, itemType?: string)  {
        if (!itemId) itemId = this.itemId;
        if (!exchangeId) exchangeId = this.exchangeId;
        if (!itemType) itemType = this.itemType;
        let addObservable: Observable<Attachment[]> = of(null);
        let removeObservable = of(true);
        
        // Fix for null error on concat
        if (!this.attachments) {
            this.attachments = [];
        }

        if (this.attachmentsToAdd) {
            let i = itemId ? itemId.toString() : undefined;
            addObservable = this.attachmentService
                .addAttachments(exchangeId, i, itemType, this.attachmentsToAdd)                                
                .pipe(
                    map(x => {
                        this.attachments = this.attachments.concat(x);
                        this.attachmentsToAdd = [];
                        return x;
                    })
                );
        }

        if (this.attachmentsToDelete) {
            {
                let i = itemId ? itemId.toString() : undefined;
                removeObservable = this.attachmentService
                    .deleteAttachments(
                        exchangeId,
                        i,
                        itemType,
                        this.attachmentsToDelete.map(x => x.Id)
                    )
                    .pipe(
                        map(x => {
                            if (x) {
                                this.attachments = this.attachments.filter(
                                    v =>
                                        !this.attachmentsToDelete.some(
                                            d => d.Id == v.Id
                                        )
                                );
                                this.attachmentsToDelete = [];
                                return true;
                            }
                            return false;
                        })
                    );
            }
        }
        return {addObservable,removeObservable}
    }
}
