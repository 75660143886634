import { Component, NgModule, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerDetails } from '../../models/customerDetails';
import { CustomerType } from 'src/app/models/enums/CustomerType';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { TerritorySalesperson } from 'src/app/models/TerritorySalesperson';
import { SelectListItem } from './../../models/SelectListItem';
import { CustomerService } from 'src/app/services/customer.service';
import { EventService } from 'src/app/services/event.service';
import { ListService } from 'src/app/services/list.service';
import { SalesService } from 'src/app/services/sales.service';
import { ModalController } from '@ionic/angular';
import { TitleCasePipe } from '@angular/common';
import { CustomerStatus } from './../../models/CustomerStatus';
import { CustomerUpdate } from 'src/app/models/CustomerUpdate';

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.html',
  styleUrls: ['./update-customer.scss']
})

export class UpdateCustomerPage implements OnInit {
  id: number;
  customer: any; //CustomerDetails
  states: SelectListItem[];
  countries: SelectListItem[];
  territorySalesPeople: TerritorySalesperson[];
  selectedTerritorySalesPerson: any;
  territorySalesPerson: any;
  statesInitialized = false;
  statesLoading = true;
  saving = false;
  customerStatus: CustomerStatus;
  selectedStatus: any;
  CustomerFormChanges = new Map<string, CustomerUpdate>();

  constructor(
    private route: ActivatedRoute,
    private listService: ListService,
    private customerService: CustomerService,
    private modalController: ModalController,
    private eventService: EventService,
    private salesService: SalesService,
    private titlePipe: TitleCasePipe
   ) {
       this.customer = {
        Id: this.id,
        CustomerNumber: '',
        Name: '',
        NickName: '',
        Address1: '',
        City: '',
        State: '',
        Country: '',
        CustomerType: '',
        TerritoryInfo: '',
        Active: true,
        Suspended: false,
        Address2: '',
        StateId: -1,
        CountryId: 100,
        PostalCode: '',
        PhoneNumber: '',
        AltPhoneNumber: '',
        FaxNumber: '',
        WebAddress: '',
        Deleted: '',
        LastModifiedUserId: -1,
        LastModifiedBy: '',
        CreatedByUserId: -1,
        CreatedBy: '',
        MorseCompetitivePosition: '',
        MorseCompetitiveShare: -1,
        TerritoryI: -1,
        TerritoryName: '',
        TerritoryNumber: -1,
        ChannelId: -1,
        Channel: '',
        ChannelAbbrev: '',
        TradingAreaId: -1,
        TradingArea: '',
        SalesAreaId: -1,
        SalesArea: '',
        PrimaryContactName: '',
        Contacts: '',
        BilltoNumber: '',
        SalesforceId: '',
        BillToNumber: ''
      };
      this.customerStatus = {
        isActive: true,
        isDeleted: false,
        isSuspended: false
      };
      this.selectedStatus = 'Active';
   }
  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.loadOptionsAsync();
  }

  async loadOptionsAsync() {
    await this.getCustomer();
    if (this.customer.CustomerType == '') {
      this.customer.CustomerType = 'EndUser';
    }

    console.log('CustomerType: ' + this.customer.CustomerType);
    await this.getCountriesAsync();
    await this.getStatesAsync(this.customer.CountryId);
    await this.getTerritoriesSalespeopleAsync();
    this.CustomerFormChanges.clear();
  }

  customerUpdate() {
    console.log('Update Customer Pressed');
    this.customerService.updateCustomer(Array.from<CustomerUpdate>(this.CustomerFormChanges.values())).then(() => {
      alert('Customer Data Updated');
    });
  }

  async getCustomer() {
    try {
      await this.customerService.getCustomer(this.id).toPromise().then(customer => {
        this.customer = customer;
      });
    } catch (error) {
      console.log('Error Getting Customer Object - Likely Service Issue (MKWebService). Details:');
      console.error(error);
    }
    console.log(this.customer.Name);
  }

  async getStatesAsync(countryId: number) {
    try {
      //Issue found with country ID 27 not returning any states. In testing 27 was found to be US - Need to verify this
      if (countryId==27){
        countryId=100;
      }
      await this.listService.getStates(countryId).toPromise().then(states => {
        this.states = [];
        this.states = JSON.parse(states);
      });
    } catch (error) {
      console.log('Error Getting States Object - Likely Service Issue (MKWebService). Details:');
      console.error(error);
    }

  }
  async getCountriesAsync() {
    await this.listService.getCountries().toPromise().then(countries => {
      this.countries = JSON.parse(countries);
    });
  }

  async getTerritoriesSalespeopleAsync() {
    await this.salesService.getTerritoriesSalespeople().toPromise().then(territorySalesPeople => {
      this.territorySalesPeople = territorySalesPeople.sort((a, b) => this.alphaSort2(a, b));
    });
    this.loadTerritory();
  }

  alphaSort(a: SelectListItem, b: SelectListItem) {
    if (a.Text < b.Text) {
      return -1;
    } else if (a.Text > b.Text) {
      return 1;
    } else {
      return 0;
    }
  }

  alphaSort2(a: TerritorySalesperson, b: TerritorySalesperson) {
    if (a.DisplayName < b.DisplayName) {
      return -1;
    } else if (a.DisplayName > b.DisplayName) {
      return 1;
    } else {
      return 0;
    }
  }

  loadTerritory() {
    console.log('TerritoryNum:' + this.customer.TerritoryNumber.toString());
    this.selectedTerritorySalesPerson = 
      this.territorySalesPeople.find(x => x.TerritoryNumber == this.customer.TerritoryName).DisplayName;
    console.log(this.selectedTerritorySalesPerson);
  }
 
  changeTerritory (change) {
    this.selectedTerritorySalesPerson = change.detail.value;
    this.changeData(change, 'territory_salesperson');
  }

  changeStatus (change) {
    this.selectedStatus = change.detail.value;
    //this.changeData(change.detail.value,'Status')
    //Could not find this field - is it saved witht he data?

    if (change.detail.value == 'Active'){
      this.customerStatus = {
        isActive: true,
        isDeleted: false,
        isSuspended: false
      };
    } else if (change.detail.value == 'Deleted'){
      this.customerStatus = {
        isActive: false,
        isDeleted: true,
        isSuspended: false
      };
    } else if (change.detail.value == 'Suspended'){
        this.customerStatus = {
          isActive: false,
          isDeleted: false,
          isSuspended: true
        };
    }
  }

  changeData(event, fieldName) {
    if (this.customer==null) {
      return;
    }
    if (fieldName == 'state_id' && event.detail.value!="" && this.states!=null) {
      this.queueEdit(fieldName, this.states.find(x => x.Text == event.detail.value).Value);
    } else if (fieldName == 'country_id' && event.detail.value!="" && this.countries!=null) {
      this.queueEdit(fieldName, this.countries.find(x => x.Text == event.detail.value).Value);
    } else {
      this.queueEdit(fieldName, event.detail.value);
    }
  }

  queueEdit(fieldName: string, fieldValue: any) {
    const customerUpdate = new CustomerUpdate(this.customer.Id, this.customer.CustomerType, fieldName, fieldValue);
    this.CustomerFormChanges.set(fieldName, customerUpdate);
  }
}

