import { Injectable, EventEmitter } from '@angular/core';
import { Task } from './../models/Task';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PagedResult } from '../models/PagedResult';
import { map } from 'rxjs/operators';
import { UserSettings } from 'src/app/models/UserSettings';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  public taskNotificationCountUpdate: EventEmitter<number> = new EventEmitter<number>();
  private url: string;
  private morseApiUrl: string;
  private urlSuffix = 'api/task';  // URL to web api

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService,
    // private userSettings: UserSettings,
    private datePipe: DatePipe) {
    behaviorSubjectService.exchangeAPISubject.subscribe(url => {
      this.url = url + `/${this.urlSuffix}`; // this.url = `http://localhost:64603/${this.urlSuffix}`;
    });
    behaviorSubjectService.morseAPISubject.subscribe(url => {
      this.morseApiUrl = url; // this.url = `http://localhost:64603/${this.urlSuffix}`;
    });
  }

  getTaskNotificationEmitter() {
    return this.taskNotificationCountUpdate;
  }

  getTask() {
    const task = new Task();
    return task;
  }

  addTask(taskToAdd: Task) {
    return this.http.post(this.url + '/addTask', taskToAdd, httpOptions);
  }

  getCurrentUserTasks(startdate: Date, enddate: Date, status: number): Observable<PagedResult> {
    const start = this.datePipe.transform(startdate, 'MM/dd/yyyy');
    const end = this.datePipe.transform(enddate, 'MM/dd/yyyy');
    const params = new HttpParams().set('startdate', start).set('enddate', end).set('status', status != null ? status.toString() : null);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<PagedResult>(this.url + '/GetCurrentUserTasks', options);
  }

  getUserAssignedTasks(startdate: Date, enddate: Date, status: number): Observable<PagedResult> {
    const start = this.datePipe.transform(startdate, 'MM/dd/yyyy');
    const end = this.datePipe.transform(enddate, 'MM/dd/yyyy');
    const params = new HttpParams().set('startdate', start).set('enddate', end).set('status', status != null ? status.toString() : null);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<string>(this.morseApiUrl + '/GetUserAssignedTasks', options)
      .pipe(map((result) => this.formatUserAssignedTasks(result)));
  }

  formatUserAssignedTasks(result): PagedResult {
    const dataArray = JSON.parse(result);
    const total = dataArray.length;
    result = new PagedResult;
    result.Data = dataArray;
    result.Total = total;
    return result;
  }

  updateTask(taskToUpdate: Task) {
    return this.http.post(this.url + '/updateTask', taskToUpdate, httpOptions);
  }

  deleteTask(taskToDelete: Task) {
    return this.http.post(this.url + '/deleteTask', taskToDelete, httpOptions);
  }

  refreshTaskNotificationCount() {
    this.http.get<number>(this.url + '/getTaskNotificationCount').subscribe(count => {
      this.taskNotificationCountUpdate.emit(count);
    }, er => console.log('ERROR: refreshTaskNotificationCount', er));
  }
}
