import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubjectService } from 'src/app/services/behavior-subject.service';
import { SharedDataService, DictKey } from 'src/app/services/shared-data.service';
import { Router } from '@angular/router';
import { CartPopup } from 'src/app/models/CartPopup';
import { OdpService } from 'src/app/services/odp.service';
import { CartItem } from 'src/app/models/CartItem';
import { EndUserProfileService } from 'src/app/services/end-user-profile.service';
import { EndUserDistributor } from 'src/app/models/EndUserDistributor';
import { SettingsService } from 'src/app/services/settings.service';
import { EndUserShoppingCartService } from 'src/app/services/end-user-shopping-cart.service';
import { AppEndUserLineItemModel } from 'src/app/models/AppEndUserLineItem';
import { AppEndUserCartDistributorsModel } from 'src/app/models/AppEndUserCartDistributorsModel';
import { DistributorSelectorPage } from '../distributor-selector/distributor-selector.page';


@Component({
  selector: 'app-shopping-cart-end-user',
  templateUrl: './shopping-cart-end-user.page.html',
  styleUrls: ['./shopping-cart-end-user.page.scss'],
})
export class ShoppingCartEndUserPage implements OnInit {

  cartPopup: CartPopup = new CartPopup();
  cartId: number;
  odpUrl: string;
  defaultDistributor: EndUserDistributor;
  cartItems: AppEndUserLineItemModel[] = [];
  username: string;
  cartIdDisplay: string = '';
  cartDistributors: AppEndUserCartDistributorsModel[] = [];
  selectedCartDistributors: AppEndUserCartDistributorsModel[] = [];
  distributorsAssigned = false;
  isSubmitting = false;

  constructor(
    private modalController: ModalController,
    private sharedDataService: SharedDataService,
    private router: Router,
    private odpService: OdpService,
    private behaviorSubjectService: BehaviorSubjectService,
    private endUserProfileService: EndUserProfileService,
    private settingsService: SettingsService,
    private endUserShoppingCartService: EndUserShoppingCartService,
    private alertController: AlertController
  ) {
    behaviorSubjectService.opdUrlSubject.subscribe(url => {
      this.odpUrl = url;
    });
  }

  ngOnInit() {
    this.cartId = this.sharedDataService.getCurrentCartQuoteNumber(); 
    if (this.cartId && this.cartId > 0) {
      this.cartIdDisplay = this.cartId.toString();
    }
    this.getDefaultDistributor();
    this.getCartItems();
    this.getCartDistributors();
    // this.distributorService.GetCartItemList(currentCart).subscribe(cartPopup => {
    //   this.cartPopup = cartPopup
    // });
  }

  getCartItems() {
    this.endUserShoppingCartService.getCartLineItemList(this.cartId)
      .subscribe(cartLineItems => {
        this.cartItems = cartLineItems;
      });
  }

  getCartDistributors() {
    this.endUserShoppingCartService.getCartDistributors(this.cartId)
    .subscribe(cartDistributors => {
      this.cartDistributors = cartDistributors.sort((a, b) => {
        if (a.isDefault && !b.isDefault) {
          return -1;
        } else if (!a.isDefault && b.isDefault) {
          return 1;
        } else {
          if (a.distributorName < b.distributorName) {
            return -1;
          } else if (a.distributorName > b.distributorName) {
            return 1;
          } else {
            return 0;
          }
        }
      });
      this.selectedCartDistributors = this.cartDistributors.filter(d => d.isActive);
      if (this.selectedCartDistributors.length == 0){
        this.distributorsAssigned = false;
      } else {
        this.distributorsAssigned = true;
      }
    });
  }

  updateCartDistributors() {
    // this.endUserShoppingCartService.updateCartDistributors(this.cartId, )
    //   .subscribe(result => {

    //   });
  }

  getDefaultDistributor() {
    this.username = this.settingsService.getUserSettings().username;
    this.endUserProfileService.getDistributorsForEndUser(this.username)
      .subscribe(distributors => {
        this.defaultDistributor = distributors.find(x => x.isDefault);
      });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  navigateToOdp() {
    window.open(this.odpUrl, '_blank');
  }

  onPartDescription(part: AppEndUserLineItemModel) {
    // navigate to that part's page
    this.closeModal();
    this.router.navigateByUrl(`/product/${part.partNumber}`);
  }

  onSubmitQuote() {
    // Go to quotes page
    this.closeModal();
  }

  updateCartLineItemQuantity(lineItem: AppEndUserLineItemModel) {
    this.endUserShoppingCartService.updateCartLineItems(this.cartId, lineItem.id, lineItem.quantity)
      .subscribe(result => {
        this.getCartItems();
        this.sharedDataService.fetchCartCount();
      });
  }

  removeCartLineItem(lineItem: AppEndUserLineItemModel) {
    this.endUserShoppingCartService.removeCartLineItem(this.cartId, lineItem.id)
      .subscribe(result => {
        this.getCartItems();
        this.sharedDataService.fetchCartCount();
      });
  }

  submitCart() {
    this.isSubmitting = true;
    this.endUserShoppingCartService.submitCart(this.cartId, this.username)
      .subscribe(result => {
          this.isSubmitting = false;
          this.showQuoteSubmittedModal('Success', 'Thank you for your quote request. Someone will be in touch shortly.');
      }, err => {
        this.isSubmitting = false;
        this.showQuoteSubmittedModal('Failed', 'Request for quote encountered an error during submission.')
      });
  }

  submitDisabled(): boolean {
    if (this.cartItems && this.cartItems.length > 0 && this.selectedCartDistributors && this.selectedCartDistributors.length > 0 
    && !this.isSubmitting) {
      return false;
    } else {
      return true;
    }
  }

  getPartDescription(part: AppEndUserLineItemModel) {
    var partDimensions = null;
    if (part.dimensions) {
      partDimensions = part.dimensions.replace(',', '\n');
    }
    return (partDimensions ? '\n' + partDimensions : '') + (part.tpi ? '\nTPI: ' + part.tpi : '') + (part.packaging ? '\nPackaging: ' + part.packaging : '');
  }

  async editDistributors(editDistributors) {
    if (this.cartDistributors.length < 1) {
      this.closeModal();
      this.router.navigateByUrl(`/distributor-locator/add`);
    } else {
      await this.presentDistributorSelectorModal();
    }
  }

  async showQuoteSubmittedModal(header, message) {
    const alert = await this.alertController.create({
      header: header,
      // subHeader: 'Subtitle',
      message: message,
      buttons: [
          { text: 'OK',
        }
      ],
      
    });

    alert.onDidDismiss().then(() => {
      this.sharedDataService.fetchCartCount()
      this.closeModal();
    });

    await alert.present();
  }

  async presentDistributorSelectorModal() {
    var modal;
    modal = await this.modalController.create({
      component: DistributorSelectorPage,
      componentProps: {
        'cartId': this.cartId,
        'cartDistributors': this.cartDistributors
      }
    });
    modal.onDidDismiss().then(() => {
      this.getCartDistributors();
    });
    return await modal.present();
  }
}
