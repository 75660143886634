import { Component, OnInit, Input } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FamilyInfo } from 'src/app/models/Family';
import { GroupInfo } from 'src/app/models/Group';
import { AlertController } from '@ionic/angular';
import { OdpService } from 'src/app/services/odp.service';
import { Location } from '@angular/common';


@Component({
    selector: 'app-family',
    templateUrl: './family.component.html',
    styleUrls: ['./family.component.scss'],
  })
  export class FamilyComponent implements OnInit {
    @Input() familyCode = '';
    @Input() family: FamilyInfo;
    @Input() group: GroupInfo;

    @Input() selected = false;

    groupList: FamilyInfo[];

    groupInfoList: Array<GroupInfo>

    groupCode: string = null;

    loadingGroups =  false;

    familyExpanded = false;
    
  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private odpService: OdpService,
    private location: Location
  ) { }

    ngOnInit() {
      this.familyCode = this.route.snapshot.queryParamMap.get('fam');
      if (this.familyCode === this.family.Code) {
        this.showHideFamily();
      }
    }


    showHideFamily() {
        this.familyExpanded = !this.familyExpanded;
        if (!this.groupList) {
          this.fetchGroups();
        }
      }

    fetchGroups() {
        this.loadingGroups = true;
        this.pageService.getGroupList(this.family.Code).subscribe(GroupInfoList => {
          this.loadingGroups = false;
          this.groupList = GroupInfoList;
          if (this.familyCode) {
            setTimeout(() => this.scrollToSection(this.familyCode), 10);
          }
        }, err => {
          console.log(err);
        })
  };

        updateURL(familyCode: string) {
          const newPath = 'distributor-home/' + familyCode;
          if (this.location.path().indexOf(newPath) < 0) {
          this.location.go(newPath);
            }
      }

      scrollToSection(familyCode: string) {
        // this.showHideFamily();
        this.updateURL(this.familyCode);
        document.getElementById(this.familyCode).scrollIntoView({behavior: 'smooth'});
      }

  }