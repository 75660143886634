import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { forkJoin } from 'rxjs';
import { AppEndUserMachineModel } from 'src/app/models/AppEndUserMachineModel';
import { BladeWizardInputsBand } from 'src/app/models/BladeWizardInputsBand';
import { MachineManufacturer } from 'src/app/models/RevolutionWizard/MachineManufacturer';
import { MachineModel } from 'src/app/models/RevolutionWizard/MachineModel';
import { EndUserProfileService } from 'src/app/services/end-user-profile.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-add-end-user-machine',
  templateUrl: './add-end-user-machine.page.html',
  styleUrls: ['./add-end-user-machine.page.scss'],
})
export class AddEndUserMachinePage implements OnInit {

  machineDetailsForm: FormGroup;
  bladeInputs: BladeWizardInputsBand;

  machineManufacturers: MachineManufacturer[];
  machineModels: MachineModel[];
  allMachineModels: MachineModel[];

  allMachineManufacturers: MachineManufacturer[];
  userMachineModels: AppEndUserMachineModel[];  

  submitted = false;
  isEndUser = false;
  showAllMfgId = -1;
  showAllModelId = -1;
  showMachineSelect = true;
  showModelSelect = true;
  openMfgSelectEvent;
  openModelSelectEvent;
  saving = false;


  constructor(
    private fb: FormBuilder,
    private endUserProfileService: EndUserProfileService,
    private settingsService: SettingsService,
    private modalController: ModalController
  ) {
    this.bladeInputs = new BladeWizardInputsBand();
    this.machineDetailsForm = this.fb.group({
      brandId: [null, [Validators.required]],
      modelId: [null, [Validators.required]]
    });
   }

  ngOnInit() {
    this.getBrandsEndUser();
  }

  getBrandsEndUser() {
    forkJoin(this.endUserProfileService.getAppEndUserMachinesList(this.settingsService.getUserSettings().username), this.endUserProfileService.getMachineMfgList())
    .subscribe(result => {
      this.userMachineModels = result[0];
      this.allMachineManufacturers = result[1];
      this.machineManufacturers = this.allMachineManufacturers;
    });
  }
  
  brandChange(event) {
    this.openMfgSelectEvent = event;
    const mfgIdStr = event.detail.value;
    console.log('brandChange:', mfgIdStr);
    const mfgId = mfgIdStr ? +mfgIdStr : null;
    this.bladeInputs.machineManufacturerId = mfgId;
    this.bladeInputs.manufacturerName = this.machineManufacturers.find(mm => mm.Id == +mfgIdStr).name;
    this.getModelsEndUser(mfgIdStr);
  }

  getModelsEndUser(mfgIdStr: string) {
    this.endUserProfileService.getMachineModelsbyMfgID(mfgIdStr)
      .subscribe(models => {
        this.allMachineModels = models;
        if (this.userMachineModels.length > 0) {
          const mfgId = this.machineDetailsForm.get('brandId').value;
          const preferredModels = models.filter(m => !this.userMachineModels.find(userM => userM.modelname == m.name && userM.machinemfgId == mfgId));
          if (preferredModels.length > 0) {
            this.afterGetModels(preferredModels);
          } else {
            this.afterGetModels(models);
          }
        } else {
          this.afterGetModels(models);
        }
      });
  }

  afterGetModels(models: MachineModel[]) {
    console.log('getCircularsawMachineModelsByMfgId:', models);
    this.machineModels = models;

    const modelId = this.bladeInputs.machineModelId;
    if (modelId && this.machineModels.find(x => x.Id === modelId)) {
      this.machineDetailsForm.get('modelId').setValue(modelId.toString());
    } else {
      this.machineDetailsForm.get('modelId').setValue(null);
    }
  }

  addMachine() {
    this.markFormGroupTouched(this.machineDetailsForm);
    if (this.machineDetailsForm.valid) {
      const username = this.settingsService.getUserSettings().username;
      const mfgId = this.machineDetailsForm.get('brandId').value;
      const modelId = this.machineDetailsForm.get('modelId').value;
      this.endUserProfileService.addAppEndUserMachinestoProfile(username, mfgId, modelId)
        .subscribe(() => {
          this.modalController.dismiss();
        });
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
