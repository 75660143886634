import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FamilyInfo } from 'src/app/models/Family';
import { GroupInfo } from 'src/app/models/Group';
import { NullValidationHandler } from 'angular-oauth2-oidc';
import { ProductInfo } from 'src/app/models/ProductInfo';



@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
})
export class GroupComponent implements OnInit {
  @Input() familyCode= '';
  @Input() groupCode= '';
  @Input() group: GroupInfo;
  @Input() products: ProductInfo;
  @Input() expanded = false;


  // groupList: GroupInfo[];
  productList: GroupInfo[];

  productInfoList: Array<ProductInfo>

  loadingProducts = false;

  productCode: string = null;

  groupSelected: string;

  sectionExpanded = false;

  constructor(
    private pageService: PageService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
    ) {  }

  ngOnInit() {
  }

  showHideSection() {
    this.sectionExpanded = !this.sectionExpanded;
    if (!this.productList) {
      this.fetchProducts();
    }
  }

  fetchProducts() {
    this.loadingProducts = true;
    this.groupCode = this.group.Code;
    this.pageService.getProductList(this.familyCode, this.group.Code).subscribe(ProductInfoList => {
      this.loadingProducts = false;
      this.productList = ProductInfoList;
      if (this.expanded) {
        setTimeout(() => {
          this.scrollToSection();
        }, 10);
      }
    });
    //this.updateURL(this.familyCode, this.groupCode);
  }

  // updateURL(familyCode: string, groupCode: string) {
  //   const newPath = 'distributor-home/' + familyCode + '/' + groupCode;
  //   if (this.location.path().indexOf(newPath) < 0) {
  //     this.location.go(newPath);
  //   }
  // }

  redirectToMinor(product: string ) {
    this.router.navigateByUrl(`product/${this.familyCode}/${this.groupCode}/${[product]}`);
  }

  scrollToSection() {
    document.getElementById(this.group.Code).scrollIntoView({behavior: 'smooth'});
  }

}
