import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ODPHeaderResult } from '../models/ODPHeaderResult';
import { Order } from '../models/Order';
import { Quote } from '../models/Quote';
import { OrderDetail } from '../models/OrderDetail';
import { CartPopup } from '../models/CartPopup';
import { TrackingResult } from '../models/TrackingResult';
import { AlertController } from '@ionic/angular';
import { SelectListItem } from '../models/SelectListItem';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class OdpService {
  private url: string;

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService,
    private alertController: AlertController) {
    behaviorSubjectService.morseAPISubject.subscribe(url => { this.url = url; });
  }

  setOdpAccountToCustomer(customerNumber: string): Observable<any> {
    const url = `${this.url}/SetOdpAccountToCustomer`;
    const params = new HttpParams().set('cust', customerNumber);
    // const options = httpOptions;
    // options['params'] = params;
    // options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' }),
      params: params
    };
    return this.http.post<any>(url, params, options);
  }

  setOdpUserSettings(firstname: string, lastname: string, email: string, units: string): Observable<boolean> {
    const url = `${this.url}/SetOdpUserSettings`;
    const params = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      units: units
    };
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' }),
      params: params
    };
    return this.http.post<boolean>(url, params, options);
  }

  /*getOpenOrders(user: string, days: number): Observable<Order[]> {
    const url = `${this.url}/GetOpenOrders`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {user: user, days: days.toString()}
    };
    return this.http.get<ODPHeaderResult[]>(url, options).pipe(map((result) => this.formatOrderResponse(result)));
  }*/

  getOpenOrders(days: number): Observable<Order[]> {
    const url = `${this.url}/GetOpenOrders`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: { days: days.toString() }
    };

    return this.http.get<ODPHeaderResult[]>(url, options).pipe(map((result) => this.formatOrderResponse(result)));
  }

  getOrderDetail(orderId: number, type: string): Observable<OrderDetail> {
    const url = `${this.url}/GetOrderDetails`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: { order: orderId.toString(), type: type }
    };

    return this.http.get<OrderDetail>(url, options);
  }

  formatOrderResponse(odpHeaderResults: ODPHeaderResult[]): Order[] {
    const mapped = odpHeaderResults.map(x => {
      const odpHeader = x as ODPHeaderResult;
      // odpHeader.ORDERNUM;
      const order: Order = {
        carrierName: odpHeader.CARRIERNAME,
        customerPONumber: odpHeader.CUSTOMERPO,
        date: new Date(odpHeader.DATE),
        dueDate: new Date(odpHeader.ORDERDUEDATE),
        orderNumber: odpHeader.ORDERNUM.toString(),
        plantCode: odpHeader.PLANTCODE,
        shipTo: odpHeader.SHIPTONAME,
        status: odpHeader.STATUS
      };
      return order;
    });
    return mapped;
  }

  getOpenQuotes(days: any): Observable<Quote[]> {
    const url = `${this.url}/GetOpenQuotes`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: { days: days.toString() }
    };
    return this.http.get<any>(url, options).pipe(map((result) => this.formatQuoteResponse(result)));
  }

  formatQuoteResponse(odpHeaderResults: string): Quote[] {
    const parsed = JSON.parse(odpHeaderResults);
    const mapped = parsed.map(x => {
      const odpHeader = x as ODPHeaderResult;
      // odpHeader.ORDERNUM;
      const quote: Quote = {
        quoteNumber: odpHeader.ORDERNUM.toString(),
        plantCode: odpHeader.PLANTCODE,
        date: new Date(odpHeader.DATE),
        shipTo: odpHeader.SHIPTO,
        expires: new Date(odpHeader.EXPIRDATE)
      };
      return quote;
    });
    return mapped;
  }

  isOdpUser(): Observable<boolean> {
    const url = `${this.url}/IsOdpUser`;
    const headers = httpOptions.headers;
    const options = {
      headers
    };

    return this.http.get<boolean>(url, options);
  }

  getTrackingInfo(orderNumber: string): Observable<TrackingResult[]> {
    const url = `${this.url}/GetTrackingInfo?orderno=${orderNumber}`;
    const headers = httpOptions.headers;
    const options = {
      headers
    };

    return this.http.get<TrackingResult[]>(url, options);
  }

  addToQuote(quoteNumber: number, partNumber: string, quantity: number): Observable<string[]> {
    const url = `${this.url}/AddToQuote`;
    const params = new HttpParams({
      fromObject: {
        'quotenum': quoteNumber.toString(),
        'part': partNumber,
        'qty': quantity.toString()
      }
    });

    return this.http.post<string[]>(url, null, {params});
  }

  addBandToQuote(quoteNumber: number, basepart: string, brandcode: string, pkgcode: string, 
      qty: number, inches: number, mm: number, sparcyn: boolean): Observable<any> {
    const url = `${this.url}/AddBandToQuote`;
    const paramObj: any = {
      'quotenum': quoteNumber.toString(),
      basepart,
      brandcode,
      pkgcode,
      'qty': qty.toString(),
      'sparcyn': sparcyn
    };
    if (inches) paramObj.inches = inches.toString();
    if (mm) paramObj.mm = mm.toString();
    const params = new HttpParams({
      fromObject: paramObj
    });

    return this.http.post<any>(url, null, {params});
  }

  getInventoryPosition(partNumber: string, isBandPart: boolean, plantCode: string): Observable<string> {
    const url = `${this.url}/GetInventoryPosition`;
    const options = httpOptions;
    let params = new HttpParams().set('partNumber', partNumber);
    params = params.set('band', isBandPart.toString());
    params = params.set('plantcode', plantCode);
    options['params'] = params;
    return this.http.get<string>(url, options);
  }

  getAvailablePlants(): Observable<SelectListItem[]> {
    const url = `${this.url}/GetAvailablePlants`;
    const options = httpOptions;
    return this.http.get<SelectListItem[]>(url, options);
  }

  quoteChangeItem(quoteNumber: number, lineNumber: number, partNumber: string, quantity: number): Observable<string[]> {
    const url = `${this.url}/QuoteChangeItem`;
    const params = new HttpParams({
      fromObject: {
        'quotenum': quoteNumber.toString(),
        'item': lineNumber.toString(),
        'part': partNumber,
        'qty': quantity.toString()
      }
    });

    return this.http.post<string[]>(url, null, {params});
  }

  storeNewQuoteMessage() {
    sessionStorage.setItem('newQuote', 'true')
  }

  displayNewQuoteMessageIfStored() {
    if (sessionStorage.getItem('newQuote')) {
      this.showModal('Success', 'New quote created. Search parts to add to the new quote.');
      sessionStorage.removeItem('newQuote');
    }
  }

  public setTheme(username: string, theme: string): Observable<void>
  {
    let params = new HttpParams().set('username', username)
    params = params.set('theme', theme);
    return this.http.post<void>(`${this.url}/SetThemeByUsername`, { username: username, theme: theme}, {params: params});
  }
  
 async showModal(header, message) {
    const alert = await this.alertController.create({
      header: header,
      // subHeader: 'Subtitle',
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }
  
}
