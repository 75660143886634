import { Country } from "./Country";
import { CountryState } from "./CountryState";

export class EndUserProfile
{
    constructor(username: string, emailAddress: string)
    {
        this.username = username;
        this.emailaddress = emailAddress;
        this.contactpref = false;
        this.firstname = null; 
        this.lastname = null; 
        this.address1 = null; 
        this.address2 = null; 
        this.city = null; 
        this.state = null; 
        this.country  = null; 
        this.postalcode = null; 
        this.landline = null; 
        this.cellphone = null; 
        this.fax = null; 
        this.units = 'imperial';
        this.theme = 'light';
    }

    public username: string;
    public firstname: string;
    public lastname: string;
    public emailaddress: string;
    public address1: string;
    public address2: string;
    public city: string;
    public state: string;
    public country: string;
    public postalcode: string;
    public landline: string;
    public cellphone: string;
    public fax: string; 
    public contactpref: boolean;
    public units: string;
    public theme: string;
}

export class EndUserProfileForm {
    constructor() {

    }

    FirstName: string;
    LastName: string;
    Phone: string;
    Fax: string;
    EmailAddress: string;
    Address1: string;
    Address2: string;
    Country: string;
    City: string;
    State: string;
    PostalCode: string;
    ContactPref: boolean;
    Landline: string;
    Units: string;
}