import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubjectService } from './behavior-subject.service';
import { SelectListItem } from '../models/SelectListItem';
import { map } from 'rxjs/operators';
import { CompetitiveXRefSearchResult } from '../models/CompetitiveXRefSearchResult';
import { CompetitiveXRefSearchParams } from '../models/CompetitiveXRefSearchParams';
import { ProductCrossoverView } from '../models/CompetitiveCrossreferenceRecentlyViewed';
import { CompetitiveXRefSearchHistory } from '../models/CompetitiveCrossReferenceSearchHitsory';
import { DatePipe } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  private url: string;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    behaviorSubjectService: BehaviorSubjectService) {
      behaviorSubjectService.morseAPISubject.subscribe(url => {this.url = url; });
  }

  getCompetitors(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getCompetitors`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getCompetitorBrands(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getCompetitorBrands`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getCompetitorBladeDescriptions(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getCompetitorBladeDescriptions`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getCompetitorPartNumbers(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getCompetitorPartNumbers`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getProductCategories(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getProductCategories`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getCrossOverTypes(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getCrossOverTypes`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getMorseCrossOvers(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getMorseCrossOvers`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getMorseModelNumbers(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getMorseModelNumbers`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getMorsePartNumbers(searchObjects: CompetitiveXRefSearchParams): Observable<SelectListItem[]> {
    const url = `${this.url}/getMorsePartNumbers`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  searchProductCrossoverDBFilterWithTypes(searchObjects: CompetitiveXRefSearchParams): Observable<CompetitiveXRefSearchResult[]> {
    const url = `${this.url}/SearchProductCrossoverDBFilterWithTypes`;
    const options = httpOptions;
    return this.http.post<string>(url, searchObjects, options).pipe(map((result) => JSON.parse(result)));
  }

  getProductCrossoverById(id: number): Observable<CompetitiveXRefSearchResult> {
    return this.http.get<string>(this.url + '/GetProductCrossoverById',
      {
        params: {id: id.toString()}
      }
    ).pipe(map((result) => {
        const thing =  Object.assign(new CompetitiveXRefSearchResult(), JSON.parse(result)) as CompetitiveXRefSearchResult;
        thing.Other_Information = thing.Other_Information.trim();
        return thing;
      }));
  }

  getAllDropdownLists(searchObjects: CompetitiveXRefSearchParams): Observable<Record<string, SelectListItem[]>> {
    return this.http.post<string>(this.url + '/GetAllCrossrefDropdowns', JSON.stringify(searchObjects), httpOptions)
      .pipe(map((result) => JSON.parse(result)));
  }

  getCXRefRecentSearchParamsHistory(): Observable<CompetitiveXRefSearchHistory[]> {
    return this.http.post<CompetitiveXRefSearchHistory[]>(this.url + '/GetRecentSearchParamsHistory', httpOptions)
  }

  getCXRefRecentlyVisitedItems(): Observable<ProductCrossoverView[]> {
    return this.http.post<ProductCrossoverView[]>(this.url + '/GetRecentlyVisitedItems', httpOptions)
  }

  getCXRefSearchParamsHistory(startDate: Date, endDate: Date): Observable<CompetitiveXRefSearchHistory[]> {
    var params = {
      fromDate: startDate,
      toDate: endDate
    }
    return this.http.post<CompetitiveXRefSearchHistory[]>
    (this.url + '/GetSearchParamsHistory?fromDate=' + this.datePipe.transform(startDate, 'MM-dd-yyyy') + '&toDate=' +  this.datePipe.transform(endDate, 'MM-dd-yyyy'), params, httpOptions)
  }

  getCXRefVisitedItems(startDate: Date, endDate: Date): Observable<ProductCrossoverView[]> {
    var params = {
      fromDate: startDate,
      toDate: endDate
    }
    return this.http.post<ProductCrossoverView[]>(this.url + '/GetVisitedItems?fromDate=' + this.datePipe.transform(startDate, 'MM-dd-yyyy') + '&toDate=' +  this.datePipe.transform(endDate, 'MM-dd-yyyy'), params, httpOptions)
  }
}
