import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserSettings } from '../models/UserSettings';
import { BehaviorSubjectService } from './behavior-subject.service';
import { RepInfoModel } from '../models/RepInfoModel';
import { Observable } from 'rxjs';
import { TechRepInfo } from '../models/TechRepInfo';
import { CartPopup } from '../models/CartPopup';
import { GetReorderListResult } from '../models/GetReorderListResult';
import { RepInfo } from '../models/RepInfo';
import { SettingsService } from './settings.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DistributorService {
  private url: string;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    behaviorSubjectService: BehaviorSubjectService
  ) {
    behaviorSubjectService.morseAPISubject.subscribe(url => this.url = url);
  }

  /** Creates a new quote and makes it the active cart. */
  getNewQuote(): Observable<any> {
    // Need to pass username in params
    const url = `${this.url}/GetNewQuote`;
    const options = httpOptions;
    const username = this.settingsService.getUserSettings().username;
    const params = new HttpParams().set('username', username);
    options['params'] = params;
    return this.http.get<any>(url, options);
  }

  /** Sets different quote to be the active cart. */
  setNewCart(quotenum: number): Observable<any> {
    const url = `${this.url}/SetNewCart`;
    const params = new HttpParams().set('quotenum', quotenum.toString());
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json' }),
      params: params
    };
    return this.http.post<any>(url, params, options);
  }

  /**
   * returns int[2]
   * [0] = quote number, [1] = number of items in cart to display on home page
   * @param quotenum is optional
   */
  getCartCountArray(quotenum: number): Observable<number[]> {
    const url = `${this.url}/GetCartCount`;
    if (quotenum) {
      const options = httpOptions;
      const params = new HttpParams().set('quotenum', quotenum.toString());
      options['params'] = params;
      return this.http.get<number[]>(url, options);
    }
    return this.http.get<number[]>(url);
  }

  // getCartItemList(quoteNumber?: number, activeCart?: boolean): Observable<CartPopup> {
  //   const url = `${this.url}/GetCartItemList`;
  //   const options = httpOptions;
  //   const params = new HttpParams();
  //   params.set('quotenum', quoteNumber ? quoteNumber.toString() : '0');
  //   if (activeCart != null) {
  //     params.set('activeCart', String(activeCart));
  //   }
  //   options['params'] = params;
  //   return this.http.get<CartPopup>(url, options);
  // }

  GetCartItemList(quoteNumber?: string): Observable<CartPopup> {
    const url = `${this.url}/GetCartItemList`;
    const options = httpOptions;
    const params = quoteNumber ? new HttpParams().set('quotenum', quoteNumber) : new HttpParams();
    options['params'] = params;
    return this.http.get<CartPopup>(url, options);
  }

  // getCartItemListWithParams(quoteNumber: number, activeCart: boolean): Observable<CartPopup> {
  //   // TODO see if they use activeCart. Currently, they dont.
  //   const url = `${this.url}/GetCartItemList`;
  //   const headers = httpOptions.headers;
  //   const options = {
  //     headers,
  //     params: {
  //       quotenum: quoteNumber.toString(),
  //       activeCart: String(activeCart)
  //     }
  //   };
  //   return this.http.get<CartPopup>(url, options);
  // }

  getSalesRepInfo(custno: string): Observable<RepInfo> {
    const url = `${this.url}/GetSalesPerson`;
    const options = httpOptions;
    const params = new HttpParams().set('custno', custno);
    options['params'] = params;
    return this.http.get<RepInfo>(url, options);
  }

  getTechRepInfo(custno: string): Observable<RepInfo> {
    const url = `${this.url}/GetTechPerson`;
    const options = httpOptions;
    const params = new HttpParams().set('custno', custno);
    options['params'] = params;
    return this.http.get<RepInfo>(url, options);
  }

  getReorderList(odpUser: string): Observable<GetReorderListResult[]> {
    const url = `${this.url}/GetReorderList`;
    const options = httpOptions;
    const params = new HttpParams().set('odpuser', odpUser);
    options['params'] = params;
    return this.http.get<GetReorderListResult[]>(url, options);
  }

  postEmailInvoicePdf(emailto: string, emailcc: string, invoicenbr: string): Observable<any> {
    const url = `${this.url}/PostEmailInvoicePdf`;
    const options = httpOptions;
    let params = new HttpParams();
    params = params.set('emailto', emailto);
    params = params.set('emailcc', emailcc);
    params = params.set('invoicenbr', invoicenbr);
    options['params'] = params;

    return this.http.post<any>(url, null, options);
  }

}
