import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubjectService } from './behavior-subject.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private router: Router,
    private behaviorSubjectService: BehaviorSubjectService,
    private settingsService: SettingsService
  ) { }

  redirectHome(isPublicURL: boolean) {
    const homeURL = this.getHomeURL(isPublicURL);
    if (isPublicURL) {
        window.location.href = homeURL;
    } else {
      this.redirect(homeURL);
    }
  }

  getHomeURL(isPublicURL: boolean): string {
    if (isPublicURL) {
      this.behaviorSubjectService.mkMorsePublicHomeUrlSubject.subscribe(url => {
        return url;
      });
    }
    else if (this.settingsService.isDistributor()) {
      return 'distributor-home';
    } else if (this.settingsService.isEndUser()) {
      return 'product-catalog';
    } else {
      // internal users now redirect to product-catalog due to calendar/task feature removal (8/23/23)
      return 'product-catalog';
    }
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

  /**
   * ex. a=20mm b=16mm returns 20-16
   */
  compareNumberStringsWithUnits(a: string, b: string): number {
    if (!a) {
      return -1;
    } else if (!b) {
      return 1;
    }
    if (a.indexOf('\"') !== -1 && b.indexOf('\"') !== -1) {
      const aVal = this.getNumberFromInchString(a);
      const bVal = this.getNumberFromInchString(b);
      // console.log(`getNumberFromInchString: ${a} -> ${aVal}`);
      return aVal - bVal;
    }
    a = a.replace(/\D/g, '');
    b = b.replace(/\D/g, '');
    return (+a) - (+b);
  }

  /**
   * 5-3/4" -> 5.75
   */
  getNumberFromInchString(s: string): number {
    const dashIdx = s.indexOf('-');
    const slashIdx = s.indexOf('/');
    if (dashIdx === -1 || slashIdx === -1) return +(s.replace(/\D/g, ''));
    const num = s.substring(0, dashIdx);
    const numer = s.substring(dashIdx + 1, slashIdx);
    let denom = s.substring(slashIdx + 1);
    denom = denom.replace(/\D/g, '');
    return (+num) + (+numer) / (+denom);
  }

}
