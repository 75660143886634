export class PageInfo {
    OdpCustomerNumber: string;
    OdpCustomerName: string;
    SalesAccountId: number;
    OdpCartCount: number;
    showCart: boolean;
    showSearch: boolean;
    showAccount: boolean;
    showPrices: boolean;
    showCustomerPart: boolean;
    BillToNumber: string;
    SalesforceId: string;
}
