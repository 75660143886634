import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToolsService } from 'src/app/services/tools.service';
import { CompetitiveXRefSearchResult } from 'src/app/models/CompetitiveXRefSearchResult';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProductCrossoverImage } from 'src/app/models/ProductCrossoverImage';

@Component({
  selector: 'app-crossover-product-display',
  templateUrl: './crossover-product-display.page.html',
  styleUrls: ['./crossover-product-display.page.scss'],
})
export class CrossoverProductDisplayPage implements OnInit {

  title = 'Competitor Product Reference';
  @Input() productId: number;

  product: CompetitiveXRefSearchResult;

  constructor(
    private modalController: ModalController,
    private toolsService: ToolsService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.toolsService.getProductCrossoverById(this.productId).subscribe(product => {
      product.processImages();
      console.log(product);
      this.product = product;
    });
  }

  getImageSrc(image: ProductCrossoverImage): SafeResourceUrl {
    const bad = '../../assets/images/Broken-image.svg';

    // if (!image || !image.Image) {
    //   return null;
    // } else {
    //   return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + image.Image);
    // }
    if (!image || (!image.Image && !image.ImageUrl)) {
      return bad;
    } else {
      if(image.Image){
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + image.Image);
      } else{
        return image.ImageUrl;
      }
    }


  }

  closeModal() {
    this.modalController.dismiss();
  }

}
