import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { JsonPipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { Customer } from './../models/Customer';
import { RepAgency } from '../models/RepAgency';
import { EndUser } from '../models/EndUser';
import { Distributor } from '../models/Distributor';
import { CustomerType } from '../models/enums/CustomerType';
import { CustomerDetails } from '../models/CustomerDetails';
import { BehaviorSubjectService } from './behavior-subject.service';
import { CustomerListItem } from '../models/CustomerListItem';
import { ContactListItem } from '../models/ContactListItem';
import { Contact } from '../models/Contact';
import { CustomerNote } from '../models/CustomerNote';
import { GetARAging } from '../models/GetARAging';
import { GetARDetail } from '../models/GetARDetail';
import { GetCoopAmt } from '../models/GetCoopAmt';
import { GetPromoList } from '../models/GetPromoList';
// import { SummaryReportRow } from '../models/Summ';
import { CustomerUpdate } from '../models/CustomerUpdate';
import { ReportAreaGrouping } from '../models/enums/ReportAreaGrouping';
import { SummaryReportRow } from '../models/SummaryReportRow';
import { ContactUsRequest } from '../models/ContactUsRequest';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private url: string;

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService,
  ) {
    behaviorSubjectService.morseAPISubject.subscribe(url => {
      this.url = url;
    });
  }

  // getCustomers() {
  //   const customers = [];
  //   for (let i = 1; i < 11; i++) {
  //     customers.push({
  //       id: i,
  //       name: 'Customer ' + i,
  //       note: 'This is item #' + i
  //     });
  //   }
  //   return customers;
  // }

  searchCustomers(term,type): Observable<CustomerListItem[]> {
    const url = `${this.url}/SearchCustomer`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {
        term: term ? term : null,
        type: type ? (type === 'End User' ? 'EndUser' : type) : null,
        // filter1: 'Both',
        // filter2: 'All',
        // filter3: 'CustomerNameNumber'
      }
    };
  // their service returns a string that needs parsed
    return this.http.get<CustomerListItem[]>(url, options);
  }

  searchMyEndUsers(term): Observable<CustomerListItem[]> {
    const url = `${this.url}/SearchMyEndUsers`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {
        term: term ? term : null
      }
    };
  // their service returns a string that needs parsed
    return this.http.get<CustomerListItem[]>(url, options);
  }

  getMyEndUsers(): Observable<CustomerListItem[]> {
    const url = `${this.url}/GetMyEndUsers`;
    const headers = httpOptions.headers;
    const options = {
      headers
    };
  // their service returns a string that needs parsed
    return this.http.get<CustomerListItem[]>(url, options);
  }

  searchCustomerLinks(term, account): Observable<CustomerListItem[]> {
    const url = `${this.url}/SearchCustomerLinks`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {
        term: term ? term : null,
        account: account ? account : null
      }
    };
    // their service returns a string that needs parsed
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getCustomer(id: number): Observable<CustomerDetails> {
    const url = `${this.url}/GetCustomer`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: id.toString()}
    };
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getCustomerSummary(id: string): Observable<CustomerListItem> {
    const url = `${this.url}/GetCustomerSummary`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: id.toString()}
    };
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getEndUserCustomer(id: number): Observable<EndUser> {
    const url = `${this.url}/GetEndUserCustomer`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: id.toString()}
    };
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getDistributorCustomer(id: number): Observable<Distributor> {
    const url = `${this.url}/GetDistributorCustomer`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: id.toString()}
    };
      return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getMorseCustomerType(type: CustomerType) : string {

    switch (type) {
      case CustomerType.Distributor:
        return 'Distributor';

      case CustomerType.EndUser:
        return 'EndUser';

      default:
        return null;
    }
  }

  addCustomer(customer: EndUser | RepAgency | Distributor) {
    if (customer.CustomerType === CustomerType.Distributor) {
      return this.http.post(this.url + '/AddDistributor', customer, httpOptions);
    } else if (customer.CustomerType === CustomerType.EndUser) {
      return this.http.post(this.url + '/AddEndUser', customer, httpOptions);
    } else if (customer.CustomerType === CustomerType.RepAgency) {
      return this.http.post(this.url + '/AddRepAgency', customer, httpOptions);
    }
  }

  addSimpleAccount(name: string) {
    return this.http.post(this.url + '/AddSimpleAccount', null, {params: {
      name: name
    }});
  }

  getContactsForCustomer(customerId: number): Observable<string> {
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: customerId.toString()}
    };
    return this.http.get<string>(this.url + '/GetContactsForCustomer', options);
  }

  getLinkedEndUsers(id: number): Observable<CustomerListItem[]> {
    const url = `${this.url}/GetLinkedEndUsers`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {distributorId: id.toString()}
    };
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getLinkedDistributors(id: number): Observable<CustomerListItem[]> {
    const url = `${this.url}/GetLinkedDistributors`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {enduserId: id.toString()}
    };
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  addUpdateContact(contact: Contact) {
    return this.http.post<number>(`${this.url}/AddUpdateContact`, contact);
  }

  // this needs parsed when it comes back
  getContact(id: number) {
    const url = `${this.url}/GetContact`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: id.toString()}
    };
    return this.http.get<string>(url, options);
  }

  submitContactUs(form: ContactUsRequest) {
    const url = `${this.url}/SubmitContactUs`;
    return this.http.post<any>(url, form);
  }

  linkEndUserAndDistributor(distributorId: number, endUserId: number) {
    return this.http.post<number>(`${this.url}/LinkEndUserAndDistributor`, null, {params: {
      distributorId: distributorId.toString(),
      endUserId: endUserId.toString(), 
      customerId: '0', // Not used in api     
      isAdd: true.toString()
    }});
  }
// 
  unlinkEndUserAndDistributor(distributorId: number, endUserId: number) {
    return this.http.post<number>(`${this.url}/LinkEndUserAndDistributor`, null, {params: {
      distributorId: distributorId.toString(),
      endUserId: endUserId.toString(),
      customerId: '0', // Not used in api
      isAdd: false.toString()
    }});
  }
      // 

  getNotesForCustomer(customerId: number) {
    let params = new HttpParams().append("id", customerId.toString());

    const headers = httpOptions.headers;
    const options = {
      headers,
      params: params
    }
    return this.http
        .get<string>(`${this.url}/GetCustomerNotes`, options)
        .pipe(map(str => JSON.parse(str)));
  }

  addCustomerNote(customerId: number, note: string) {
    console.log("Adding Customer Note", {"customerId": customerId, "note": note});
    let params = new HttpParams().append("AccountId", customerId.toString()).append("Note", note);

    const headers = httpOptions.headers;
    const options = {
      headers,
    };

    const newNote = {
      AccountId: customerId,
      Note: note
    };

    return this.http.post(`${this.url}/AddCustomerNote`, newNote, options);

  }
  
  getARAging(customerNumber: string): Observable<GetARAging> {
    const url = `${this.url}/GetARAging`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {customernumber: customerNumber}
    };
    return this.http.get<GetARAging>(url, options);
  }

  getARDetail(customerNumber: string): Observable<GetARDetail>  {
    const url = `${this.url}/GetARDetail`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {customernumber: customerNumber}
    };
    return this.http.get<GetARDetail>(url, options);
  }

  getCoopAmt(customerNumber: string, year: number): Observable<GetCoopAmt>  {
    const url = `${this.url}/GetCoopAmt`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {customernumber: customerNumber, year: year.toString()}
    };
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getCustomerPromos(customerNumber: string, eligibility: boolean): Observable<GetPromoList> {
    const url = `${this.url}/GetCustomerPromos`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {customernumber: customerNumber, eligibility: eligibility.toString()}
    };
    return this.http.get<GetPromoList>(url, options);
  }
  
  getOdpPromos(eligibility: boolean): Observable<GetPromoList> {
    const url = `${this.url}/GetOdpPromos`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {eligibility: eligibility.toString()}
    };
    return this.http.get<GetPromoList>(url, options);
  }

  getSingleCustomerSalesSummary(customerNumber: string, year: number): Observable<any> {
    const url = `${this.url}/GetSingleCustomerSalesSummary`;
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {customernumber: customerNumber, year: year.toString()}
    };
    return this.http.get<any>(url, options);
  }

  getSalesSummary(
    groupBy: string, 
    year: number, 
    divisionparent: string,
    regionparent: string,
    salespersonparent: string,
    territoryparent: string,
    tradingareaparent: string,
    filter1column: ReportAreaGrouping, 
    filter1content: string, 
    filter2column: ReportAreaGrouping, 
    filter2content: string): Observable<SummaryReportRow[]> {
    const url = `${this.url}/GetSalesSummary`;
    const headers = httpOptions.headers;
    const params = {
      groupBy, 
      year: year.toString(),
      filter1column,
      filter1content,
      filter2column,
      filter2content
    }
    divisionparent ? params['divisionparent'] = divisionparent : null;
    regionparent ? params['regionparent'] = regionparent : null;
    salespersonparent ? params['salespersonparent'] = salespersonparent : null;
    territoryparent ? params['territoryparent'] = territoryparent : null;
    tradingareaparent ? params['tradingareaparent'] = tradingareaparent : null;
    const options = {
      headers,
      params
    };
    return this.http.get<SummaryReportRow[]>(url, options);
  }

  addCustomerPromo(PromotionCode: string, CustomerNumber: string): Observable<any> {
    const body = {
      PromotionCode,
      CustomerNumber
    }
    return this.http.post(this.url + '/AddCustomerPromo', body, httpOptions);
  }

  deleteNote(noteId: number): Observable<any> {
    const headers = httpOptions.headers;
    const options = {
      headers,
      params: {id: noteId.toString()}
    };
    return this.http.delete(this.url + '/DeleteCustomerNote', options);
  }

  async updateCustomer(customerUpdates: CustomerUpdate[]) {

    var updates: Array<Promise<object>> = [];
    
    for (var i in customerUpdates) {
      updates.push(this.http.post(this.url + '/UpdateCustomerRecord', customerUpdates[i], httpOptions).toPromise());
    }

    await Promise.all(updates);
  }
}
