import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './pages/distributor-home/distributor-home.module#DistributorHomePageModule',
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'list',
  //   loadChildren: './pages/list/list.module#ListPageModule'
  // },
  // { path: 'tasks', loadChildren: './pages/tasks/tasks.module#TasksPageModule', canActivate: [AuthGuard] },
  { path: 'customers', loadChildren: './pages/customers/customers.module#CustomersPageModule', canActivate: [AuthGuard] },
  { path: 'sales', loadChildren: './pages/sales/sales.module#SalesPageModule', canActivate: [AuthGuard] },
  { path: 'customer/sales/:id', loadChildren: './pages/sales/sales.module#SalesPageModule', canActivate: [AuthGuard] },
  { path: 'tools', loadChildren: './pages/tools/tools.module#ToolsPageModule', canActivate: [AuthGuard] },
  { path: 'tools/restore', loadChildren: './pages/tools/tools.module#ToolsPageModule', canActivate: [AuthGuard] },
  { path: 'support', loadChildren: './pages/support/support.module#SupportPageModule', canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule', canActivate: [AuthGuard] },
  // { path: 'contact/:id', loadChildren: './pages/contact/contact.module#ContactPageModule' },
  // { path: 'contacts/:id', loadChildren: './pages/contacts/contacts.module#ContactsPageModule' },
  // { path: 'calls', loadChildren: './pages/calls/calls.module#CallsPageModule' },
  { path: 'marketing', loadChildren: './pages/marketing/marketing.module#MarketingPageModule', canActivate: [AuthGuard] },
  { path: 'tool-history', loadChildren: './pages/tool-history/tool-history.module#ToolHistoryPageModule', canActivate: [AuthGuard] },
  { path: 'contact/:id', loadChildren: './pages/contact/contact.module#ContactPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id', loadChildren: './pages/customer/customer.module#CustomerPageModule', canActivate: [AuthGuard] },
  { path: 'customer/interactions/:id', loadChildren: './pages/interactions/interactions.module#InteractionsPageModule', canActivate: [AuthGuard] },
  { path: 'contacts/:id', loadChildren: './pages/contacts/contacts.module#ContactsPageModule', canActivate: [AuthGuard] },
  { path: 'calls', loadChildren: './pages/calls/calls.module#CallsPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/orders', loadChildren: './pages/orders/orders.module#OrdersPageModule', canActivate: [AuthGuard] },
  // { path: 'customer/:id/orders/:tab', loadChildren: './pages/orders/orders.module#OrdersPageModule', canActivate: [AuthGuard] },
  { path: 'marketing', loadChildren: './pages/marketing/marketing.module#MarketingPageModule', canActivate: [AuthGuard] },
  { path: 'tool-history', loadChildren: './pages/tool-history/tool-history.module#ToolHistoryPageModule', canActivate: [AuthGuard] },
  //{ path: 'tool-results', loadChildren: './pages/tool-results/tool-results.module#ToolResultsPageModule' },
  { path: 'add-customer', loadChildren: './pages/modals/add-customer/add-customer.module#AddCustomerPageModule', canActivate: [AuthGuard] },
  { path: 'add-call', loadChildren: './pages/modals/add-call/add-call.module#AddCallPageModule', canActivate: [AuthGuard] },
  { path: 'add-contact', loadChildren: './pages/modals/add-contact/add-contact.module#AddContactPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/notes', loadChildren: './pages/notes/notes.module#NotesPageModule', canActivate: [AuthGuard] },
  { path: 'add-customer-note', loadChildren: './pages/modals/add-customer-note/add-customer-note.module#AddCustomerNotePageModule', canActivate: [AuthGuard] },
  { path: 'bladewizard', loadChildren: './pages/bladewizard-home/bladewizard-home.module#BladewizardHomePageModule' },
  { path: 'add-call', loadChildren: './pages/modals/add-call/add-call.module#AddCallPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id', loadChildren: './pages/customer/customer.module#CustomerPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/interactions', loadChildren: './pages/interactions/interactions.module#InteractionsPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/rgas/:rgaNumber', loadChildren: './pages/rga/rga.module#RgaPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/rgas', loadChildren: './pages/rgas/rgas.module#RgasPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/rga-request', loadChildren: './pages/rga-request/rga-request.module#RgaRequestPageModule', canActivate: [AuthGuard] },

  // DISTRIBUTOR RGA ROUTES
  { path: 'rgas/rga-details/:rgaNumber', loadChildren: './pages/rga/rga.module#RgaPageModule', canActivate: [AuthGuard] },
  { path: 'rgas', loadChildren: './pages/rgas/rgas.module#RgasPageModule', canActivate: [AuthGuard] },
  { path: 'rgas/rga-request', loadChildren: './pages/rga-request/rga-request.module#RgaRequestPageModule', canActivate: [AuthGuard] },
  // END DISTRIBUTOR RGA ROUTES
  { path: 'distributor-home', loadChildren: './pages/distributor-home/distributor-home.module#DistributorHomePageModule', canActivate: [AuthGuard] },
  { path: 'product-catalog', loadChildren: './pages/distributor-home/distributor-home.module#DistributorHomePageModule', canActivate: [AuthGuard] },
  { path: 'promotions', loadChildren: './pages/promos/promos.module#PromosPageModule', canActivate: [AuthGuard] },
  { path: 'buy-again', loadChildren: './pages/buy-again/buy-again.module#BuyAgainPageModule', canActivate: [AuthGuard] },
  { path: 'my-account', loadChildren: './pages/my-account/my-account.module#MyAccountPageModule', canActivate: [AuthGuard] },

  { path: 'customer/:id/orders', loadChildren: './pages/orders/orders.module#OrdersPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/orders/:orderNumber/:orderType', loadChildren: './pages/order-details/order-details.module#OrderDetailsPageModule', canActivate: [AuthGuard] },
  { path: 'customer/:id/sales', loadChildren: './pages/sales/sales.module#SalesPageModule', canActivate: [AuthGuard] },
  { path: 'filetest', loadChildren: './pages/filetest/filetest.module#FiletestPageModule', canActivate: [AuthGuard] },
  { path: 'saw-information', loadChildren: './pages/saw-information/saw-information.module#SawInformationPageModule', canActivate: [AuthGuard] },
  { path: 'saw-information/saw-files/:maker', loadChildren: './pages/saw-files/saw-files.module#SawFilesPageModule', canActivate: [AuthGuard] },
  { path: 'competitive-cross-reference', loadChildren: './pages/competitive-cross-reference/competitive-cross-reference.module#CompetitiveCrossReferencePageModule', canActivate: [AuthGuard] },
  { path: 'competitive-cross-reference-results', loadChildren: './pages/competitive-cross-reference-results/competitive-cross-reference-results.module#CompetitiveCrossReferenceResultsPageModule', canActivate: [AuthGuard] },
  { path: 'crossover-product-display', loadChildren: './pages/modals/crossover-product-display/crossover-product-display.module#CrossoverProductDisplayPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId', loadChildren: './pages/value-calculator/value-calculator.module#ValueCalculatorPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/businessAnalysis', loadChildren: './pages/value-calculator-businessAnalysis/value-calculator-businessAnalysis.module#ValueCalculatorBusinessAnalysisPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator', loadChildren: './pages/value-calculator/value-calculator.module#ValueCalculatorPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/job', loadChildren: './pages/value-calculator-job/value-calculator-job.module#ValueCalculatorJobPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/job/:jobId/machine', loadChildren: './pages/value-calculator-machine/value-calculator-machine.module#ValueCalculatorMachinePageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/job/:jobId/machine/:machineId', loadChildren: './pages/value-calculator-machine/value-calculator-machine.module#ValueCalculatorMachinePageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/job/:jobId/machine/:machineId/morseBlade', loadChildren: './pages/value-calculator-blade-morse/value-calculator-blade-morse.module#ValueCalculatorBladeMorsePageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/job/:jobId/machine/:machineId/competitorBlade', loadChildren: './pages/value-calculator-blade-competitor/value-calculator-blade-competitor.module#ValueCalculatorBladeCompetitorPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator/:businessAnalysisId/review', loadChildren: './pages/value-calculator-review/value-calculator-review.module#ValueCalculatorReviewPageModule', canActivate: [AuthGuard] },
  { path: 'value-calculator-history', loadChildren: './pages/value-calculator-history/value-calculator-history.module#ValueCalculatorHistoryPageModule', canActivate: [AuthGuard] },
  { path: 'product/:family/:group/:product', loadChildren: './pages/product/product.module#ProductPageModule', canActivate: [AuthGuard] },
  { path: 'product/:part', loadChildren: './pages/product/product.module#ProductPageModule', canActivate: [AuthGuard] },
  { path: 'tracking-info', loadChildren: './pages/modals/tracking-info/tracking-info.module#TrackingInfoPageModule', canActivate: [AuthGuard] },
  { path: 'send-invoice-email', loadChildren: './pages/modals/send-invoice-email/send-invoice-email.module#SendInvoiceEmailPageModule', canActivate: [AuthGuard] },
  { path: 'chipload-calculator', loadChildren: './pages/chipload-calculator/chipload-calculator.module#ChiploadCalculatorPageModule', canActivate: [AuthGuard]},
  { path: 'help', loadChildren: './pages/training/training.module#TrainingPageModule', canActivate: [AuthGuard]},
  { path: 'complete-user-profile', loadChildren: './pages/complete-user-profile/complete-user-profile.module#CompleteUserProfilePageModule',canActivate: [AuthGuard] },
  { path: 'user-profile', loadChildren: './pages/complete-user-profile/complete-user-profile.module#CompleteUserProfilePageModule',canActivate: [AuthGuard] },
  { path: 'competitive-cross-reference-history', loadChildren: './pages/competitive-cross-reference-history/competitive-cross-reference-history.module#CompetitiveCrossReferenceHistoryPageModule',canActivate: [AuthGuard]  },
  { path: 'competitive-cross-reference-search-history', loadChildren: './pages/competitive-cross-reference-search-history/competitive-cross-reference-search-history.module#CompetitiveCrossReferenceSearchHistoryPageModule',canActivate: [AuthGuard]  },
  { path: 'competitive-cross-reference-product-history', loadChildren: './pages/competitive-cross-reference-product-history/competitive-cross-reference-product-history.module#CompetitiveCrossReferenceProductHistoryPageModule',canActivate: [AuthGuard]  },
  { path: 'distributor-locator', loadChildren: './pages/distributor-locator/distributor-locator.module#DistributorLocatorPageModule',canActivate: [AuthGuard]  },
  { path: 'distributor-locator/add', loadChildren: './pages/distributor-locator/distributor-locator.module#DistributorLocatorPageModule',canActivate: [AuthGuard]  },
  { path: 'distributor-location/:id', loadChildren: './pages/distributor-location/distributor-location.module#DistributorLocationPageModule',canActivate: [AuthGuard] },
  { path: 'shopping-cart-end-user', loadChildren: './pages/modals/shopping-cart-end-user/shopping-cart-end-user.module#ShoppingCartEndUserPageModule' },
  { path: 'distributor-selector', loadChildren: './pages/modals/distributor-selector/distributor-selector.module#DistributorSelectorPageModule' },




];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
