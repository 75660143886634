export class EndUserDistributor
{
    customerNumber: string;
    appEndUserId: number;
    DistributorId: number;
    distributorName: string;
    territory: string;
    territorySalesPerson: string;
    isDefault: boolean;
    createDate: boolean;
}
