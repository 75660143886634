import { Injectable, Output, EventEmitter } from '@angular/core';

import { CustomerDetails } from '../models/CustomerDetails';
import { CustomerListItem } from '../models/CustomerListItem';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public customer: CustomerListItem;
  /**
   * This is a stored value that is updated on every redirect.
   */
  public publicURL: boolean;

  @Output() customerChange = new EventEmitter<CustomerListItem>();

  constructor() { }

  clearCustomer() {
    this.setCustomer(null);
  }

  setCustomer(customer: CustomerListItem, emit = true) {
    this.customer = customer;
    if (emit) {
      this.customerChange.emit(customer);
    }
  }

  /**
   * Sets and returns menuService.publicURL
   */
  isPublicURL(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    this.publicURL = urlParams.has('public');
    return this.publicURL;
  }
}