import { Component, OnInit, Input, OnDestroy, Renderer2, ViewChild, ElementRef, ContentChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { IonMenuButton, IonSearchbar, ModalController, PopoverController } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings.service';
import { PageInfo } from 'src/app/models/PageInfo';
import { ShoppingCartPage } from 'src/app/pages/modals/shopping-cart/shopping-cart.page';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OdpService } from 'src/app/services/odp.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { PageService } from 'src/app/services/page.service';
import { SharedDataService, DictKey } from 'src/app/services/shared-data.service';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';
import { SharedService } from 'src/app/services/shared.service';
import { TaskService } from 'src/app/services/task.service';
import { MenuService } from 'src/app/services/menu.service';
import { ShoppingCartEndUserPage } from '../../pages/modals/shopping-cart-end-user/shopping-cart-end-user.page';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() Title: string;
  @Input() isPublicURL: boolean;
  @Input() isLargeScreenHeader = false;

  bannerText: string;

  searchText: string;
  showSearchbar = false;

  showAccountDropdown = false;

  themeChangeSubscription: any;
  logo: string = this.settingsService.getTheme("logo.png");

  pageInfo: PageInfo;
  cart: any[];
  cartCount = 0;
  taskCount = 0;

  isLargeScreen: boolean;
  shouldRender: boolean;
  showProfile = false;

  pageInfoSubscription: any;
  cartInfoSubscription: any;
  cartRefreshJobRunning = false;

  welcomeStr: string;
  taskNotificationSubscription: any;
  isDistributor = false;
  isEndUser = false;

  constructor(
    private router: Router,
    private location: Location,
    private modalController: ModalController,
    private breakpointObserver: BreakpointObserver,
    private sharedDataService: SharedDataService,
    private odpService: OdpService,
    private localStorage: LocalStorage,
    private pageInfoService: PageService,
    private renderer: Renderer2,
    private popoverController: PopoverController,
    private settingsService: SettingsService,
    private sharedService: SharedService,
    private taskService: TaskService,
    public menuService: MenuService,
    private route: ActivatedRoute
  ) { 
    this.themeChangeSubscription = this.settingsService.themeUpdate.subscribe(() => {
      this.logo = this.settingsService.getTheme("logo.png");
    })
  }

  ngOnInit() {
    this.isLargeScreen = this.breakpointObserver.isMatched('(min-width: 1367px)');
    this.isDistributor = this.settingsService.isDistributor();
    this.isEndUser = this.settingsService.isEndUser();
    this.setShouldRender();
    this.observeWidth();
    this.setShowProfile();

    this.pageInfoHandler();
    this.cartInfoHandler();

    this.updateWelcomeText();

    // Get covid response and subscribe to event emitter
    this.bannerText = this.sharedDataService.getValue(DictKey.BannerHTML);
    this.sharedDataService.getEmitter(DictKey.BannerHTML).subscribe(bannerHTML => {
      this.bannerText = bannerHTML;
    });

    this.taskNotificationSubscription = this.taskService.getTaskNotificationEmitter()
    .subscribe(item => this.setTaskNotificationCount(item));
  }

  ngOnDestroy() {
    this.pageInfoSubscription.unsubscribe();
    this.cartInfoSubscription.unsubscribe();
    this.taskNotificationSubscription.unsubscribe();
  }

  setTaskNotificationCount(count) {
    this.taskCount = count;
  }

  observeWidth() {
    this.breakpointObserver.observe('(min-width: 1367px)').subscribe(x => {
      this.isLargeScreen = x.matches;
      this.setShouldRender();
    });
  }

  updateWelcomeText() {
    // Claims should include given_name and family_name.  Use given_name + first initial of family_name.
    // If only family_name is there and no given name, use full family_name.  if neither is there, use username.
    const user = this.settingsService.getUserSettings();
    this.welcomeStr = null;
    if (user) {
      const first = user.given_name;
      const last = user.family_name;
      const username = user.username;
      if (first && last) {
        this.welcomeStr = `Welcome ${first} ${last.charAt(0)}.`;
      } else if (first) {
        this.welcomeStr = `Welcome ${first}`;
      } else if (last) {
        this.welcomeStr = `Welcome ${last}`;
      } else if (username) {
        this.welcomeStr = `Welcome ${username}`;
      }

      // At this point we will append company name.
      if (this.welcomeStr && this.pageInfo.OdpCustomerName && this.isDistributor) {
        this.welcomeStr += ', ' + this.pageInfo.OdpCustomerName;
      }
    }
  }

  setShowProfile() {
    this.showProfile = !this.isPublicURL;
  }

  setShouldRender() {
    this.shouldRender = this.isLargeScreen === this.isLargeScreenHeader;
  }

  cartInfoHandler() {
    this.cartCount = this.sharedDataService.getCurrentCartItemCount();
    this.cartInfoSubscription = this.sharedDataService.getEmitter(DictKey.CartCountArray).subscribe(value => {
      this.cartCount = value[1];
    });

    // Default until api call returns
    if (!this.cartCount) {
      this.cartCount = 0;
    }
  }

  pageInfoHandler() {
    this.pageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    this.pageInfoSubscription = this.sharedDataService.getEmitter(DictKey.AppPageInfo).subscribe(value => {
      this.pageInfo = value;
      this.updateWelcomeText();
    });

    // Default until api call returns
    if (!this.pageInfo) {
      const info = new PageInfo();
      info.OdpCartCount = 0;
      info.showAccount = false;
      info.showCart = false;
      info.showSearch = false;
      this.pageInfo = info;
    }
  }

  redirectHome() {
    this.sharedService.redirectHome(this.isPublicURL);
  }

  goBack() {
    if ((document.URL.includes('value-calculator') || document.URL.includes('bladewizard')) 
    && document.URL.includes('?type=')) {
      this.location.back();
    }
    this.location.back();
  }

  goForward() {
    this.location.forward();
  }

  async onProfile(ev: Event) {
    const popover = await this.popoverController.create({
      component: ProfileDropdownComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  onShoppingCart() {
    this.presentShoppingCartModal();
  }

  async presentShoppingCartModal() {
    var modal;
    if(this.isEndUser) {
      modal = await this.modalController.create({
        component: ShoppingCartEndUserPage
      });
    } else {
      modal = await this.modalController.create({
        component: ShoppingCartPage
      });
    }
    return await modal.present();
  }

  onSearchIcon(targetDocumentId: string) {
    this.searchText = this.sharedDataService.getValue(DictKey.SearchText);
    this.showSearchbar = true;
    setTimeout(() => (document.getElementById(targetDocumentId) as any).setFocus(), 5);
  }

  hideSearchbar() {
    this.showSearchbar = false;
  }

  searchTextChangeHandler() {
    this.sharedDataService.setValue(DictKey.SearchText, this.searchText);
  }

  showCart() {
    return (this.pageInfo && !this.menuService.publicURL && this.pageInfo.OdpCustomerNumber) || this.isEndUser;
  }

}
