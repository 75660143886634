import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from './services/settings.service';
import { MenuService } from './services/menu.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private settingsService: SettingsService,
        private router: Router,
        private menuService: MenuService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean> | Promise<boolean> | boolean  {
        // // Extra condition for home page since guard interferes with IS3 redirects
        // if (window.location.pathname.includes('home') && this.menuService.isPublicURL()) {
        //     return false;
        // } else {
        //     if (!this.settingsService.isLoggedIn())
        //     {
        //         // Identity server may need to do some redirects on this before it is recognized as the user being logged in
        //         return false;
        //     }
            
        //     else if (this.settingsService.isLoggedIn()) {
        //         const isProfileComplete = this.settingsService.isProfileComplete();
        //         const isProfileCompletePage = route && route.routeConfig && route.routeConfig.path ? route.routeConfig.path.includes('complete-user-profile') : false;
        //         if (!isProfileComplete && !isProfileCompletePage) {
        //             this.router.navigate(['/complete-user-profile']);
        //             return false;
        //         }
        //     } else if (!this.settingsService.isLoggedIn()) {
        //         // this.router.navigate(['']);
        //         return false;
        //     }
        // }

        // Check allowed role
        // let allowRoles = route.data["allowRoles"] as Array<string>;
        // if (allowRoles && !(allowRoles.indexOf(this.settingsService.getUserSettings().) >= 0)) {
        //     return false;
        // }


        // // Check denied role
        // let denyRoles = route.data["denyRoles"] as Array<string>;
        // if (denyRoles && denyRoles.indexOf(this.settingsService.getUserSettings().) >= 0) {
        //     return false;
        // }

        return true;
    }
}