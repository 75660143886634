import { Component, OnInit, Input } from '@angular/core';
import { DistributorService } from 'src/app/services/distributor.service';
import { ModalController } from '@ionic/angular';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-send-invoice-email',
  templateUrl: './send-invoice-email.page.html',
  styleUrls: ['./send-invoice-email.page.scss'],
})
export class SendInvoiceEmailPage implements OnInit {
  @Input() invoicenbr: string;
  email1 = '';
  email2 = '';

  saving = false;

  constructor(
    private distributorService: DistributorService,
    private modalCtrl: ModalController,
    private settingsService: SettingsService,
    ) { }

  ngOnInit() {
    this.email1 = this.settingsService.getUserSettings().email;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  sendEmail() {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email1)) {
      alert('The primary email is invalid.');
      return;
    }

    this.saving = true;

    this.distributorService.postEmailInvoicePdf(this.email1, this.email2, this.invoicenbr).subscribe(() => {
      this.saving = false;
      this.modalCtrl.dismiss();
    }, er => {
      this.saving = false;
      alert('Failed to send email.');
      throw er;
    });
  }

}
