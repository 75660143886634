import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppEndUserCartDistributorsModel } from '../models/AppEndUserCartDistributorsModel';
import { AppEndUserLineItemModel } from '../models/AppEndUserLineItem';
import { CartPart } from '../models/CartPart';
import { BehaviorSubjectService } from './behavior-subject.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class EndUserShoppingCartService {

  private url: string;

  constructor(private http: HttpClient, behaviorSubjectService: BehaviorSubjectService) {
    behaviorSubjectService.morseAPISubject.subscribe(url => { this.url = url; });
  }

  //  addToCart(username: string, part: string, description: string, qty: number, dimensions: string, tpi: string, packaging: string): Observable<any> {
  addToCart(cartPart: CartPart): Observable<any> {
    const url = `${this.url}/AddToCart`;
  // their service returns a string that needs parsed
    return this.http.post<any>(url, cartPart, httpOptions);
  }

  getCartItemCount(cartId: number): Observable<number> {
    const url = `${this.url}/GetCartItemCount`;
    const options = httpOptions;
    const params = new HttpParams().set('cartId', cartId.toString());
    options['params'] = params;
    return this.http.get<number>(url, options);
  }

  getActiveCart(username: string): Observable<number> {
    const url = `${this.url}/GetActiveCart`;
    const options = httpOptions;
    const params = new HttpParams().set('username', username);
    options['params'] = params;
    return this.http.get<number>(url, options);
  }

  getCartLineItemList(cartId: number): Observable<AppEndUserLineItemModel[]> {
    const url = `${this.url}/GetCartLineItemList`;
    const options = httpOptions;
    const params = new HttpParams().set('cartId', cartId.toString());
    options['params'] = params;
    return this.http.get<AppEndUserLineItemModel[]>(url, options);
  }

  updateCartLineItems(cartId: number, lineItemId: number, qty: number): Observable<any> {
    const url = `${this.url}/UpdateCartLineItems`;
    const options = httpOptions;
    const params = new HttpParams().set('cartId', cartId.toString())
      .set('lineItemId', lineItemId.toString())
      .set('qty', qty.toString())
    options['params'] = params;
    return this.http.get<any>(url, options);
  }

  removeCartLineItem(cartId: number, lineItemId: number): Observable<any> {
    const url = `${this.url}/RemoveCartLineItem`;
    const options = httpOptions;
    const params = new HttpParams().set('cartId', cartId.toString())
      .set('lineItemId', lineItemId.toString());
    options['params'] = params;
    return this.http.get<any>(url, options);
  }

  submitCart(cartId: number, username: string): Observable<any> {
    const url = `${this.url}/SubmitCart`;
    const options = httpOptions;
    const params = new HttpParams().set('cartId', cartId.toString())
      .set('username', username);
    options['params'] = params;
    return this.http.get<any>(url, options);
  }

  getCartDistributors(cartId: number):  Observable<AppEndUserCartDistributorsModel[]> {
    const url = `${this.url}/GetCartDistributors`;
    const options = httpOptions;
    const params = new HttpParams().set('cartId', cartId.toString())
    options['params'] = params;
    return this.http.get<AppEndUserCartDistributorsModel[]>(url, options);
  }

  updateCartDistributors(cartId: number, distributorIds: number[]):  Observable<any> {
    const url = `${this.url}/UpdateCartDistributors?cartId=` + cartId.toString() + '&distributorIds=' +  distributorIds.join('&distributorIds=');
    const options = httpOptions;
    return this.http.get<any>(url, options);
  }

}