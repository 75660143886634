import { CustomerBasicProperties } from './CustomerBasicProperties';

export class CustomerListItem extends CustomerBasicProperties {
    NickName: string;
    Address1: string;
    City: string;
    State: string;
    Country: string;
    CustomerType: string;
    TerritoryInfo: string;
    Active: boolean;
    Suspended: boolean;
    BilltoNumber: string;
    SalesforceId: string;
    AccountLevel: string;
    AccountLevelMin: number;
    AccountLevelMax: number;
    AccountLevelRange: string;
    Territory: string;
    TerritorySalesperson: string;
}
