import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { ModalController } from '@ionic/angular';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Subscription } from 'rxjs';

import { CustomerType } from 'src/app/models/enums/CustomerType';
import { CustomerUpdate } from 'src/app/models/CustomerUpdate';
import { Distributor } from 'src/app/models/Distributor';
import { EndUser } from 'src/app/models/EndUser';
import { RepAgency } from 'src/app/models/RepAgency';
import { SalesPerson } from 'src/app/models/SalesPerson';
import { SelectListItem } from 'src/app/models/SelectListItem';
import { TerritorySalesperson } from 'src/app/models/TerritorySalesperson';

import { CustomerService } from 'src/app/services/customer.service';
import { EventService } from 'src/app/services/event.service';
import { ListService } from 'src/app/services/list.service';
import { SalesService } from 'src/app/services/sales.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.page.html',
  styleUrls: ['./add-customer.page.scss'],
})

export class AddCustomerPage implements OnInit {

  @Input() existingCustomer: EndUser | Distributor | RepAgency = null;

  public CustomerType = CustomerType;
  @Input() customerType;

  CustomerFormChanges = new Map<string, CustomerUpdate>();
  RepUser: SalesPerson;

  customerForm: FormGroup;
  status = 'active';
  submitAttempt: boolean;
  states: SelectListItem[];
  countries: SelectListItem[];
  accountLevels: SelectListItem[];
  pricingPrograms: SelectListItem[];
  endUserClasses: SelectListItem[];
  territorySalesPeople: TerritorySalesperson[];
  selectedTerritorySalesPerson: any;
  statesInitialized = false;
  pricingProgramsInitialized = false;
  statesLoading = true;
  peopleSubscription: Subscription;
  salesPeople: any;
  saving = false;

  repUserForm: FormGroup;
  morseUserForm: FormGroup;
  repUserNameControl: FormControl;
  repUserAddressControl: FormControl;
  morseUserNameControl: FormControl;
  morseUserAddressControl: FormControl;
  @ViewChild('repUserComponent') repUserComponent: IonicSelectableComponent;
  @ViewChild('morseUserComponent') morseUserComponent: IonicSelectableComponent;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private modalController: ModalController,
    private listService: ListService,
    private eventService: EventService,
    private salesService: SalesService,
    private titlePipe: TitleCasePipe
  ) {
    this.customerForm = this.formBuilder.group({
      // Customer fields
      CustomerType: [this.customerType, Validators.required],
      AccountNumber: [''],
      CustomerNumber: [''],
      Name: [null, Validators.required],
      PhoneNumber: [null, Validators.required],
      AlternatePhoneNumber: [''],
      Address1: [null, Validators.required],
      Address2: [''],
      City: [null, Validators.required],
      StateId: [null],
      CountryId: ['100', Validators.required],
      PostalCode: [null, Validators.required],
      FaxNumber: [''],
      WebsiteAddress: [''],
      IsActive: [true, Validators.required],
      IsSuspended: [false, Validators.required],
      IsDeleted: [false, Validators.required],
      AccountLevel: [null],
      AccountLevelDollars: [null],
      Territory: [null],
      TerritorySalesperson: [null, Validators.required],
      // Distributor fields
      DistributorNumber: [''],
      PricingProgramId: [null],
      RepUserId: [null],
      MorseUserId: [null],
      BranchCount: [null],
      InsideSalesCount: [null],
      OutsideSalesCount: [null],
      WelderCount: [null],
      IsMainOffice: [null],
      MainOfficeAccountId: [null],
      IsWeldCenter: [null],
      IsMorseDistributor: [null],
      IsSellingOtherBrands: [null],
      // End user fields
      EndUserNumber: [''],
      EndUserClassId: [null],
      IsMorseEndUser: [null],
      IsMajorAccount: [null],
      DateTargeted: [null],
      // Rep Agency feilds
      // IsSellingOtherBrands: [null, Validators.required]
    });

    this.repUserForm = this.formBuilder.group({
      personName: this.repUserNameControl,
      personAddress: this.repUserAddressControl
    });
    this.morseUserForm = this.formBuilder.group({
      personName: this.morseUserNameControl,
      personAddress: this.morseUserAddressControl
    });
   }

  ngOnInit() {
    this.typeChanged(this.customerType);

    this.loadOptionsAsync().then(() => {
      this.loadFormForEdit();
      this.hookupEditMonitor();           
    });
  }

  async loadOptionsAsync() {
    await this.getAccountLevelsAsync(this.customerType);
    await this.getCountriesAsync();
    await this.getEndUserClassesAsync();
    await this.getPricingProgramsAsync();
    await this.getSalesPeopleAsync();
    await this.getStatesAsync(this.customerForm.controls['CountryId'].value);
    await this.getTerritoriesSalespeopleAsync();
  }


  queueEdit({ fieldName, fieldValue }: { fieldName: string; fieldValue: any; }) {
    var customerUpdate = new CustomerUpdate(this.existingCustomer.Id, this.existingCustomer.CustomerType, fieldName, fieldValue);
    this.CustomerFormChanges.set(fieldName, customerUpdate);
  }

  hookupEditMonitor() {
    if (!this.existingCustomer) {
      return;
    }
    // Base customer fields.
    this.customerForm.get('Address1').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'address_1', fieldValue: x }));
    this.customerForm.get('Address2').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'address_2', fieldValue: x }));
    this.customerForm.get('AlternatePhoneNumber').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'alternate_phone_number', fieldValue: x }));
    this.customerForm.get('City').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'city', fieldValue: x }));
    this.customerForm.get('CountryId').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'country_id', fieldValue: x }));
    this.customerForm.get('FaxNumber').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'fax_number', fieldValue: x }));
    this.customerForm.get('Name').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'name', fieldValue: x }));
    this.customerForm.get('PhoneNumber').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'phone_number', fieldValue: x }));
    this.customerForm.get('PostalCode').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'postal_code', fieldValue: x }));
    this.customerForm.get('StateId').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'state_id', fieldValue: x }));
    this.customerForm.get('Territory').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'territory', fieldValue: x }));
    this.customerForm.get('TerritorySalesperson').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'territory_salesperson', fieldValue: x }));
    this.customerForm.get('WebsiteAddress').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'website_address', fieldValue: x }));
    
    // Distributor fields.
    this.customerForm.get('AccountLevel').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'account_level', fieldValue: x }));
    this.customerForm.get('AccountLevelDollars').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'account_level_dollars', fieldValue: x }));
    this.customerForm.get('BranchCount').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'branch_count', fieldValue: x }));
    this.customerForm.get('InsideSalesCount').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'inside_sales_count', fieldValue: x }));
    this.customerForm.get('IsMainOffice').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'is_main_office', fieldValue: x }));
    this.customerForm.get('IsMorseDistributor').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'is_morse_distributor', fieldValue: x }));
    this.customerForm.get('IsSellingOtherBrands').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'is_selling_other_brands', fieldValue: x }));
    this.customerForm.get('IsWeldCenter').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'is_weld_center', fieldValue: x }));
    this.customerForm.get('MorseUserId').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'morse_user_id', fieldValue: x }));
    this.customerForm.get('OutsideSalesCount').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'outside_sales_count', fieldValue: x }));
    this.customerForm.get('PricingProgramId').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'pricing_program_id', fieldValue: x }));
    this.customerForm.get('RepUserId').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'rep_user_id', fieldValue: x }));
    this.customerForm.get('WelderCount').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'welder_count', fieldValue: x }));

    // End user fields.
    this.customerForm.get('AccountLevel').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'account_level', fieldValue: x }));
    this.customerForm.get('AccountLevelDollars').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'account_level_dollars', fieldValue: x }));
    this.customerForm.get('DateTargeted').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'DateTargeted', fieldValue: x }));
    this.customerForm.get('EndUserClassId').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'end_user_class_id', fieldValue: x }));
    this.customerForm.get('IsMajorAccount').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'is_major_account', fieldValue: x }));
    this.customerForm.get('IsMorseEndUser').valueChanges.subscribe(x => this.queueEdit({ fieldName: 'is_morse_end_user', fieldValue: x }));
  
  }

  loadFormForEdit() {
    if (!this.existingCustomer) {
      return;
    }

    let country = this.countries.filter(x => x.Text == this.existingCustomer.Country.trim() );

    if (country.length > 0) {
      this.customerForm.controls['CountryId'].setValue(country[0].Value);
    }

    let territorySalesperson = this.territorySalesPeople.filter(x => x.SalespersonUsername == this.existingCustomer.TerritorySalesperson.trim() &&
      x.TerritoryNumber == this.existingCustomer.TerritoryInfo);
    
    if (territorySalesperson.length > 0) {
      this.customerForm.controls['Territory'].setValue(this.existingCustomer.TerritoryInfo);
      this.customerForm.controls['TerritorySalesperson'].setValue(this.existingCustomer.TerritorySalesperson);
      this.selectedTerritorySalesPerson = territorySalesperson[0];
    }

    this.customerForm.controls['Address1'].setValue(this.existingCustomer.Address1);
    this.customerForm.controls['Address2'].setValue(this.existingCustomer.Address2);
    this.customerForm.controls['AlternatePhoneNumber'].setValue(this.existingCustomer.AltPhoneNumber);
    this.customerForm.controls['City'].setValue(this.existingCustomer.City);
    this.customerForm.controls['CustomerType'].setValue(this.existingCustomer.CustomerType.toString());
    this.customerForm.controls['FaxNumber'].setValue(this.existingCustomer.FaxNumber);
    this.customerForm.controls['Name'].setValue(this.existingCustomer.Name);
    this.customerForm.controls['PhoneNumber'].setValue(this.existingCustomer.PhoneNumber);
    this.customerForm.controls['PostalCode'].setValue(this.existingCustomer.PostalCode);
    this.customerForm.controls['StateId'].setValue(this.existingCustomer.StateId != null ? this.existingCustomer.StateId.toString() : null);
    this.customerForm.controls['WebsiteAddress'].setValue(this.existingCustomer.WebAddress);

    if (this.existingCustomer.CustomerType == CustomerType.Distributor) {

      let distributor = this.existingCustomer as Distributor;

      this.customerForm.controls['AccountLevel'].setValue(distributor.AccountLevel);
      this.customerForm.controls['AccountLevelDollars'].setValue(distributor.AccountLevelSpecific);
      this.customerForm.controls['BranchCount'].setValue(distributor.BranchCount);
      this.customerForm.controls['InsideSalesCount'].setValue(distributor.InsideSalesCount);
      this.customerForm.controls['IsMainOffice'].setValue(distributor.MainOffice);
      this.customerForm.controls['IsMorseDistributor'].setValue(distributor.MorseDistributor);
      this.customerForm.controls['IsSellingOtherBrands'].setValue(distributor.SellsOtherBrands);
      this.customerForm.controls['IsWeldCenter'].setValue(distributor.WeldCenter);
      this.customerForm.controls['MorseUserId'].setValue(distributor.MorseUserId);
      this.customerForm.controls['OutsideSalesCount'].setValue(distributor.OutsideSalesCount);
      this.customerForm.controls['PricingProgramId'].setValue(distributor.PricingProgramId != null ? distributor.PricingProgramId.toString() : null);
      this.customerForm.controls['RepUserId'].setValue(distributor.RepUserId);
      this.customerForm.controls['WelderCount'].setValue(distributor.WelderCount);
    } else if (this.existingCustomer.CustomerType == CustomerType.EndUser) {

      let endUser = this.existingCustomer as EndUser;

      this.customerForm.controls['AccountLevel'].setValue(endUser.AccountLevel);
      this.customerForm.controls['AccountLevelDollars'].setValue(endUser.AccountLevelSpecific);
      this.customerForm.controls['DateTargeted'].setValue(endUser.DateTargeted);
      this.customerForm.controls['EndUserClassId'].setValue(endUser.EndUserClassId != null ? endUser.EndUserClassId.toString() : null);
      this.customerForm.controls['IsMajorAccount'].setValue(endUser.MajorAccount);
      this.customerForm.controls['IsMorseEndUser'].setValue(endUser.MorseEndUser);
    }
  }

  typeChanged(newValue) {
    this.customerForm.controls['CustomerType'].setValue(newValue);
    this.getAccountLevels(newValue);
    if (newValue == 'End User') {
      this.getEndUserClasses();
    }
  }

  addUpdateCustomer() {
    this.submitAttempt = true;
    // if the form is valid
    if (this.customerForm.valid) {
      const customer = this.customerForm.getRawValue();
      if (this.existingCustomer) {
          this.customerService.updateCustomer(Array.from<CustomerUpdate>(this.CustomerFormChanges.values())).then(() => {
            this.modalController.dismiss();
        });
      } else {
        this.customerService.addCustomer(customer).subscribe(result => {
          // If event adds successfully close the modal
          if (result) {
            this.modalController.dismiss();
          }
        });
      }
    } else {
      console.log('ERRORS');
      console.log(this.customerForm)
    }
  }

  getTerritoriesSalespeople() {
    this.salesService.getTerritoriesSalespeople()
      .subscribe(territorySalesPeople => {
        this.territorySalesPeople = territorySalesPeople.sort((a,b) => this.alphaSort2(a,b));
      });
  }

  statusChanged(event) {
    const newValue = event.detail.value;
    this.clearStatusValues();
    if (newValue == 'active') {
      this.customerForm.controls['IsActive'].setValue(true);
    } else if (newValue == 'suspended') {
      this.customerForm.controls['IsSuspended'].setValue(true);
    } else if (newValue == 'deleted') {
      this.customerForm.controls['IsDeleted'].setValue(true);
    }
  }

  countryChanged(event){
    /*const newValue = event.detail.value;
    if (newValue) {
      this.getStates(newValue);
    } else {
      this.states = [];
    }
    this.customerForm.controls['CountryId'].setValue(newValue);
    this.customerForm.controls['StateId'].setValue(null);*/
  }

  pricingProgramChanged(event) {
    const newValue = event.detail.value;
    this.customerForm.controls['PricingProgramId'].setValue(newValue);
  }

  endUserClassChanged(event) {
    const newValue = event.detail.value;
    this.customerForm.controls['EndUserClassId'].setValue(newValue);
  }

  getStates(countryId) {
    this.statesLoading = true;
    this.listService.getStates(countryId)
      .subscribe(states => {
        this.states = [];
        this.states = JSON.parse(states);
        this.statesInitialized = true;
        this.statesLoading = false;
      })
  }

  getCountries() {
    this.listService.getCountries()
      .subscribe(countries => {
        this.countries = JSON.parse(countries);
        if (!this.statesInitialized) {
          this.getStates(this.customerForm.controls['CountryId'].value);
        }
      })
  }

  async getStatesAsync(countryId: number) {
    await this.listService.getStates(countryId).toPromise().then(states => {
      this.states = [];
      this.states = JSON.parse(states);
      this.statesInitialized = true;
      this.statesLoading = false;
    });
  }

  async getCountriesAsync() {
    await this.listService.getCountries().toPromise().then(countries => {
      this.countries = JSON.parse(countries);
    });
  }

  async getEndUserClassesAsync() {
    await this.listService.getEndUserClasses().toPromise().then(endUserClasses => {
      this.endUserClasses = JSON.parse(endUserClasses);
    });
  }

  async getTerritoriesSalespeopleAsync() {
    await this.salesService.getTerritoriesSalespeople().toPromise().then(territorySalesPeople => {
      this.territorySalesPeople = territorySalesPeople.sort((a,b) => this.alphaSort2(a,b));
    });
  }

  async getAccountLevelsAsync(customerType: string) {
    await this.listService.getAccountLevels(customerType).toPromise().then(accountLevels => {
        this.accountLevels = accountLevels;
    });
  }

  async getSalesPeopleAsync() {
    await this.salesService.getSalesPeople().toPromise().then(salesPeople => {
        this.salesPeople = salesPeople.sort((a, b) => this.sortSalesPerson(a, b));
    });
  }

  async getPricingProgramsAsync() {
    await this.listService.getPricingPrograms().toPromise().then(pricingPrograms => {
      this.pricingPrograms = pricingPrograms.sort((a,b) => this.alphaSort(a,b));
    })
  }

  getAccountLevels(customerType: string) {
    this.listService.getAccountLevels(customerType)
      .subscribe(accountLevels => {
        this.accountLevels = accountLevels;
      })
  }

  getModalTitle() {
    return this.existingCustomer == null ? 'Add Customer' : 'Edit Customer';
  }

  getPricingPrograms() {
    this.listService.getPricingPrograms()
    .subscribe(pricingPrograms => {
      this.pricingPrograms = pricingPrograms.sort((a,b) => this.alphaSort(a,b));
    })
  }

  alphaSort(a: SelectListItem, b: SelectListItem) {
    if (a.Text < b.Text) {
      return -1;
    } else if (a.Text > b.Text) {
      return 1;
    } else {
      return 0;
    }
  }

  sortSalesPerson(a: SalesPerson, b: SalesPerson) {
    if (a.Name < b.Name) {
      return -1;
    } else if (a.Name > b.Name) {
      return 1;
    } else {
      return 0;
    }
  }

  alphaSort2(a: TerritorySalesperson, b: TerritorySalesperson) {
    if (a.DisplayName < b.DisplayName) {
      return -1;
    } else if (a.DisplayName > b.DisplayName) {
      return 1;
    } else {
      return 0;
    }
  }

  getEndUserClasses() {
    this.listService.getEndUserClasses()
    .subscribe(endUserClasses => {
      this.endUserClasses = JSON.parse(endUserClasses);
    })
  }

  clearStatusValues() {
    this.customerForm.controls['IsActive'].setValue(false);
    this.customerForm.controls['IsSuspended'].setValue(false);
    this.customerForm.controls['IsDeleted'].setValue(false);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getSalesPeople() {
    this.salesService.getSalesPeople()
      .subscribe(salesPeople => this.salesPeople = salesPeople)
  }

  onAddRepUser(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.repUserNameControl.reset();
    this.repUserAddressControl.reset();

    // Show form.
    event.component.showAddItemTemplate();
  }

  addRepUser() {
    // Create person.
    const person = {
      name: this.repUserNameControl.value,
      address: this.repUserAddressControl.value
    };

    // Add port to storage.
    // this.portService.addPort(port);

    // Add port to the top of list.
    this.repUserComponent.addItem(person).then(() => {
      // this.personComponent.search(person.name);
    });

    // Clean form.
    this.repUserNameControl.reset();
    this.repUserAddressControl.reset();

    // Show list.
    this.repUserComponent.hideAddItemTemplate();
  }

  onAddMorseUser(event: {
    component: IonicSelectableComponent
  }) {
    // Clean form.
    this.morseUserNameControl.reset();
    this.morseUserAddressControl.reset();

    // Show form.
    event.component.showAddItemTemplate();
  }

  addMorseUser() {
    // Create person.
    const person = {
      name: this.morseUserNameControl.value,
      address: this.morseUserAddressControl.value
    };

    // Add port to storage.
    // this.portService.addPort(port);

    // Add port to the top of list.
    this.morseUserComponent.addItem(person).then(() => {
      // this.personComponent.search(person.name);
    });

    // Clean form.
    this.morseUserNameControl.reset();
    this.morseUserAddressControl.reset();

    // Show list.
    this.morseUserComponent.hideAddItemTemplate();
  }

  applyTitlePipe(fieldName: string, newValue) {
    const formField = this.customerForm.get(fieldName);
    const currentValue = formField.value;
    const formattedValue = this.titlePipe.transform(newValue)
    if (currentValue != formattedValue) {
      formField.setValue(formattedValue);
    }
  }

  territorySalesChange() {

    if (this.customerForm.get('Territory').value != this.selectedTerritorySalesPerson.TerritoryNumber) {
      this.customerForm.get('Territory').setValue(this.selectedTerritorySalesPerson.TerritoryNumber);
    }

    if (this.customerForm.get('TerritorySalesperson').value === null || this.customerForm.get('TerritorySalesperson').value.trim() != this.selectedTerritorySalesPerson.SalespersonUsername) {
      this.customerForm.get('TerritorySalesperson').setValue(this.selectedTerritorySalesPerson.SalespersonUsername);
    }
  }
}
