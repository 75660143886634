import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppEndUserCartDistributorsModel } from 'src/app/models/AppEndUserCartDistributorsModel';
import { EndUserDistributor } from 'src/app/models/EndUserDistributor';
import { SelectListItem } from 'src/app/models/SelectListItem';
import { EndUserProfileService } from 'src/app/services/end-user-profile.service';
import { EndUserShoppingCartService } from 'src/app/services/end-user-shopping-cart.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-distributor-selector',
  templateUrl: './distributor-selector.page.html',
  styleUrls: ['./distributor-selector.page.scss'],
})
export class DistributorSelectorPage implements OnInit {

  @Input() cartId: number;
  @Input() cartDistributors: AppEndUserCartDistributorsModel[] = [];
  //selectableDistributors: SelectListItem[] = [];
  username: string;

  constructor(
    private modalController: ModalController,
    private endUserProfileService: EndUserProfileService,
    private settingsService: SettingsService,
    private endUserShoppingCartService: EndUserShoppingCartService
  ) { }

  ngOnInit() {
    this.username = this.settingsService.getUserSettings().username;
    //this.getAllDistributors();
    //this.rollingLog();
  }

  
  closeModal() {
    this.modalController.dismiss();
  }

  onUpdateDistributors() {
    var selectedDistributors = this.cartDistributors.filter(d => d.isActive).map(d => d.distributorId);
    this.endUserShoppingCartService.updateCartDistributors(this.cartId, selectedDistributors)
      .subscribe(result => {
        this.closeModal();
      });
  }

  // getAllDistributors() {
  //   this.endUserProfileService.getDistributorsForEndUser(this.username)
  //   .subscribe(distributors => {
  //     this.selectableDistributors = distributors.map(x => {   
  //       return {
  //       Text: x.distributorName,
  //       Value: x.DistributorId.toString(),
  //       Selected: this.cartDistributors.findIndex(c => c.distributorId == x.DistributorId) >= 0 ? true : false,
  //       Disabled: false,
  //       Group: x.isDefault ? 'Default' : null
  //     }
  //     });
  //   })
  // }

  // rollingLog() {
  //   console.log('selectable: ', this.selectableDistributors);
  //   console.log('cartDistributors: ', this.cartDistributors);
  //   setTimeout(() => {
  //     this.rollingLog();
  //   }, 2000)
  // }

}
