import { Component, EventEmitter,OnInit, ViewChild, Input, Output } from '@angular/core';
import {
    ModalController,
} from '@ionic/angular';
import { CustomerService } from '../../../services/customer.service';
import { EventService } from 'src/app/services/event.service';
import { Event } from 'src/app/models/Event';
import { CustomerDetails } from 'src/app/models/CustomerDetails';
import { Router } from '@angular/router';
// import { Validators, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

class Location {
    public address: string;
    public name: string;
}

class Person {
    public address: string;
    public name: string;
}

@Component({
    selector: 'view-event-customers-modal',
    templateUrl: './view-event-customers-modal.page.html',
    styleUrls: ['./view-event-customers-modal.page.scss']
})
export class ViewEventCustomersModalPage implements OnInit {    
    event: Event;
    customers: CustomerDetails[] =[];
    @Output() customerSelectedEvent = new EventEmitter<CustomerDetails>();
    @Input() existingEvent: any;
    
    constructor(
        private modalController: ModalController,
        public eventService: EventService,        
        private router: Router,
        private customerService: CustomerService,   
    ) {
    }


    ngOnInit() {     
       
        this.event = this.existingEvent; 
        if(this.event.customers){
           this.event.customers.forEach(c => {this.mapCustomerDetails(c) });
        }
    }

    mapCustomerDetails(c: any) {
        let customer = JSON.parse(c);
        this.customerService.getCustomer(customer.Id)
        .subscribe(o => {this.customers.push(o)});       
    }

    selectCustomer(customer: CustomerDetails) {
        this.modalController.dismiss();
        this.router.navigate(['customer/' + customer.Id]);        
    }

    closeModal() {
        this.modalController.dismiss();
    }
    
}
