import { CustomerListItem } from './CustomerListItem';

export class CustomerDetails extends CustomerListItem {
    Address2: string;
    StateId: number;
    CountryId: number;
    PostalCode: string;
    PhoneNumber: string;
    AltPhoneNumber: string;
    FaxNumber: string;
    WebAddress: string;
    Deleted: string;
    LastModifiedUserId: number;
    LastModifiedBy: string;
    CreatedByUserId: number;
    CreatedBy: string;
    MorseCompetitivePosition: string;
    MorseCompetitiveShare: number;
    TerritoryI: number;
    TerritoryName: string;
    TerritoryNumber: number;
    ChannelId: number;
    Channel: string;
    ChannelAbbrev: string;
    TradingAreaId: number;
    TradingArea: string;
    SalesAreaId: number;
    SalesArea: string;
    PrimaryContactName: string;

    Contacts: any;
}


// public ContactMethod PrimaryContactMethod { get; set; }