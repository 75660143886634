import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { DatePipe, TitleCasePipe, CurrencyPipe, DecimalPipe } from '@angular/common';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppConfigService } from './services/app-config.service';
import { AddEventModalPageModule } from './pages/modals/add-event-modal/add-event-modal.module';
import { IonicSelectableModule } from 'ionic-selectable';
import { TokenInterceptor } from './token.interceptor';
import { AddTaskPageModule } from './pages/modals/add-task/add-task.module';
import { CreditDetailsPageModule } from './pages/modals/credit-details/credit-details.module';
import { AddCustomerPageModule } from './pages/modals/add-customer/add-customer.module';
import { AddCallPageModule } from './pages/modals/add-call/add-call.module';
import { AddContactPageModule } from './pages/modals/add-contact/add-contact.module';
import { AddCustomerNotePageModule } from './pages/modals/add-customer-note/add-customer-note.module';
import { FiletestPageModule } from './pages/filetest/filetest.module';
import { CrossoverProductDisplayPageModule } from './pages/modals/crossover-product-display/crossover-product-display.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './services/pwa-service.service';
import { ViewEventCustomersModalModule } from './pages/modals/view-event-customers-modal/view-event-customers-modal.module';
import { GlobalMethodsService } from './global-methods.service';
import { Calendar } from '@fullcalendar/core';
import { PageService } from './services/page.service';
import { AuthGuard } from './auth.guard';
import { CartDropdownComponent } from './secondary-components/cart-dropdown/cart-dropdown.component';
import { ShoppingCartPageModule } from './pages/modals/shopping-cart/shopping-cart.module';
import { ShoppingCartEndUserPageModule } from './pages/modals/shopping-cart-end-user/shopping-cart-end-user.module';
import { LayoutModule } from '@angular/cdk/layout';
import { SharedDataService } from './services/shared-data.service';
import { ContactUsModalPageModule } from './pages/modals/contact-us-modal/contact-us-modal.module';
import { ProfileDropdownComponent } from './secondary-components/header/profile-dropdown/profile-dropdown.component';
import { TrackingInfoPageModule } from './pages/modals/tracking-info/tracking-info.module';
import { SendInvoiceEmailPageModule } from './pages/modals/send-invoice-email/send-invoice-email.module';
import { AddCustomerValueCalculatorPageModule } from './pages/modals/add-customer-value-calculator/add-customer-value-calculator.module';
import { AddEndUserMachinePageModule } from './pages/modals/add-end-user-machine/add-end-user-machine.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { DistributorSelectorPageModule } from './pages/modals/distributor-selector/distributor-selector.module';

export function createTranslateLoader(http: HttpClient) {
  // the script will get named with a hash on prod builds.
  const scripts = document.getElementsByTagName('script');
  const thisOne = scripts[scripts.length - 1].src || 'default.js';
  const fileName = thisOne.split('/').pop();
  const withoutExtension = fileName.split('.')[0];
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${withoutExtension}`);
}

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
      return appConfig.loadAppConfig();
    };
  };
@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [
    ProfileDropdownComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    IonicModule.forRoot(),
    OAuthModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    TrackingInfoPageModule,
    AddEventModalPageModule,
    AddEndUserMachinePageModule,
    ShoppingCartPageModule,
    ShoppingCartEndUserPageModule,
    DistributorSelectorPageModule,
    ContactUsModalPageModule,
    SendInvoiceEmailPageModule,
    ViewEventCustomersModalModule,
    AddTaskPageModule,
    AddCustomerNotePageModule,
    CreditDetailsPageModule,
    AddCustomerPageModule,
    AddCustomerValueCalculatorPageModule,
    AddCallPageModule,
    IonicSelectableModule,
    AddContactPageModule,
    FiletestPageModule,
    CrossoverProductDisplayPageModule,
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    GoogleMapsModule,
  ],
  providers: [
    AppConfigService,
    AuthGuard,
    {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [AppConfigService],
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    DatePipe,
    SharedDataService,
    DecimalPipe,
    TitleCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    PwaService,
    GlobalMethodsService,
    CurrencyPipe,
    PageService,
    { provide: OAuthStorage, useFactory: storageFactory }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
