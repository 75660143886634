import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { count, map } from 'rxjs/operators';
import { AppEndUserMachineModel } from '../models/AppEndUserMachineModel';
import { Country } from '../models/Country';
import { CountryState } from '../models/CountryState';
import { EndUserDistributor } from '../models/EndUserDistributor';
import { EndUserProduct } from '../models/EndUserProduct';
import { EndUserProfile, EndUserProfileForm } from '../models/EndUserProfile';
import { EndUserProfileResponse } from '../models/EndUserProfileResponse';
import { MachineManufacturer } from '../models/RevolutionWizard/MachineManufacturer';
import { MachineModel } from '../models/RevolutionWizard/MachineModel';
import { BehaviorSubjectService } from './behavior-subject.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class EndUserProfileService {

  private url: string;

  constructor(private http: HttpClient, behaviorSubjectService: BehaviorSubjectService) {
    behaviorSubjectService.morseAPISubject.subscribe(url => { this.url = url; });
  }

  public getCountries(): Observable<Array<Country>>
  {
    return this.http
                  .get<Array<any>>(`${this.url}/GetCountryList`, httpOptions)
                  .pipe(map(results => this.parseCountryList(results)))
  }

  public getStatesForCountry(countryId: number): Observable<Array<CountryState>>
  {
    const params = new HttpParams().set('countryID', countryId.toString());
    const options = httpOptions;
    options['params'] = params;
    return this.http
                  .get<Array<any>>(`${this.url}/GetStatesbyCountryId`, options)
                  .pipe(map(results => this.parseCountryStatesList(results)));
  }

  public getDistributorsForEndUser(username: string): Observable<EndUserDistributor[]>
  {
    const params = new HttpParams().set('_username', username);
    const options = httpOptions;
    options['params'] = params;
    return this.http
                  .get<Array<any>>(`${this.url}/ListDistributorsbyUsername`, options)
                  .pipe(map(results => this.parseDistributorListForEndUser(results)));
  }

  public getEndUserMajorCategories(username: string): Observable<EndUserProduct[]>
  {
    const params = new HttpParams().set('_username', username);
    const options = httpOptions;
    options['params'] = params;
    return this.http
                  .get<Array<any>>(`${this.url}/ListAppEndUserMajorCategories`, options);
  }

  public getAppEndUserbyUsername(username: string): Observable<EndUserProfileResponse>
  {
    const params = new HttpParams().set('username', username);
    const options = httpOptions;
    options['params'] = params;
    return this.http
                  .get<EndUserProfileResponse>(`${this.url}/GetAppEndUserbyUsername`, options);
  }

  public setTheme(username: string, theme: string): Observable<void>
  {
    let params = new HttpParams().set('username', username)
    params = params.set('theme', theme);
    return this.http.post<void>(`${this.url}/SetThemeByUsername`, { username: username, theme: theme}, {params: params});
  }

  public updateEndUserProfile(profile: EndUserProfile): Observable<void>
  {
    let params = new HttpParams().set('username', profile.username)

    if (profile.firstname)
    {
      params = params.set('firstname', profile.firstname);      
    }

    if (profile.lastname)
    {
      params = params.set('lastname', profile.lastname);      
    }

    if (profile.emailaddress)
    {
      params = params.set('emailaddress', encodeURIComponent(profile.emailaddress));
    }

    if (profile.address1)
    {
      params = params.set('address1', profile.address1);
    }

    if (profile.address2)
    {
      params = params.set('address2', profile.address2);
    }

    if (profile.city)
    {
      params = params.set('city', profile.city);
    }

    if (profile.state)
    {
      params = params.set('state', profile.state); //.state.abbreviation
    }

    if (profile.country)
    {
      params = params.set('country', profile.country); //.country.abbreviation
    }

    if (profile.postalcode)
    {
      params = params.set('postalcode', profile.postalcode);
    }

    if (profile.landline)
    {
      params = params.set('landline', encodeURIComponent(profile.landline));
    }
    
    if (profile.cellphone)
    {
      params = params.set('mobphone', encodeURIComponent(profile.cellphone));
    }

    if (profile.fax)
    {
      params = params.set('fax', encodeURIComponent(profile.fax));
    }

    if (profile.contactpref)
    {
      params = params.set('contactpref', 'true');
    }

    if (profile.units)
    {
      params = params.set('units', profile.units);
    }

    if (profile.theme)
    {
      params = params.set('theme', profile.theme);
    }

    return this.http.post<void>(`${this.url}/UpdateEndUserProfilebyUsername`, null, {params: params})
  }

  public deleteEndUserProfilebyUsername(username: string, emailAddress: string): Observable<void> {
    let params = new HttpParams()
    .set('username', username)
    .set('emailaddress', emailAddress)
    return this.http.post<void>(`${this.url}/deleteEndUserProfilebyUsername`, null, {params: params});
  }

  
  public deleteAppEndUserDistributorById(username: string, id: number): Observable<void>
  {
    let params = new HttpParams()
      .set('_username', username)
      .set('distributorId', id.toString())
    return this.http.post<void>(`${this.url}/deleteAppEndUserDistributorById`, null, {params: params});
  }

  public AddAppEndUserDistributorById(username: string, id: number, isdefault: boolean): Observable<void> {
    let params = new HttpParams()
      .set('_username', username)
      .set('distributorId', id.toString())
      .set('isdefault', isdefault ? 'true' : 'false')
    return this.http.post<void>(`${this.url}/AddAppEndUserDistributorById`, null, {params: params});
  }

  public setDefaultDistributorById(username: string, id: number, isdefault: boolean): Observable<void> {
    let params = new HttpParams()
      .set('_username', username)
      .set('isdefault', isdefault ? 'true' : 'false')
      .set('distributorId', id.toString())
    return this.http.post<void>(`${this.url}/SetDefaultDistributorById`, null, {params: params});
  }

  public addAppEndUserMajorCategory(username: string, majorName: string): Observable<string>
  {
    let params = new HttpParams()
      .set('_username', username)
      .set('MajorName', majorName)
    return this.http.post<string>(`${this.url}/AddAppEndUserMajorCategory`, null, {params: params});
  }

  public deleteAppEndUserMajorCategories(username: string, majorName: string): Observable<void>
  {
    let params = new HttpParams()
      .set('_username', username)
      .set('MajorName', majorName)
    return this.http.post<void>(`${this.url}/DeleteAppEndUserMajorCategories`, null, {params: params});
  }


  // All response shapes taken from provided swagger examples
  private parseCountryList(results: Array<{id: any, name: any, CORG_P6COD: any}>): Array<Country>
  {
    return results.map(result => {
      let country = new Country();
      country.id = result.id;
      country.name = result.name;
      country.abbreviation = result.CORG_P6COD;
      return country;
    });
  }

  private parseCountryStatesList(results: Array<{id: any, country_id: any, name: any, abbreviation: any, STATEMK_SXSTPR: any}>): Array<CountryState>
  {
    return results.map(result => {
      let state = new CountryState();
      state.id = result.id;
      state.countryId = result.country_id;
      state.name = result.name;
      state.abbreviation = result.abbreviation;
      state.StateMk_SXSTPR = result.STATEMK_SXSTPR;
      return state;
    });
  }

  private parseDistributorListForEndUser(results: Array<{appEndUserId: any, DistributorId: any, DistributorName: any, territory: any, territory_sales_person: any, customerNumber: any, isDefault: any, CreateDate: any}>): Array<EndUserDistributor>
  {
    return results.map(result => {
      let endUserDistributor = new EndUserDistributor();
      endUserDistributor.appEndUserId = result.appEndUserId;
      endUserDistributor.DistributorId = result.DistributorId;
      endUserDistributor.distributorName = result.DistributorName;
      endUserDistributor.territory = result.territory;
      endUserDistributor.territorySalesPerson = result.territory_sales_person;
      endUserDistributor.isDefault = result.isDefault;
      endUserDistributor.createDate = result.CreateDate;
      endUserDistributor.customerNumber = result.customerNumber;
      return endUserDistributor;
    })
  }  

  public convertEndUserProfileToForm(endUserProfile: EndUserProfile): EndUserProfileForm {
      const endUserProfileForm = new EndUserProfileForm();
      endUserProfileForm.FirstName = endUserProfile.firstname;
      endUserProfileForm.LastName = endUserProfile.lastname;
      endUserProfileForm.Phone = endUserProfile.cellphone;
      endUserProfileForm.Fax = endUserProfile.fax;
      endUserProfileForm.EmailAddress = endUserProfile.emailaddress;
      endUserProfileForm.Address1 = endUserProfile.address1;
      endUserProfileForm.Address2 = endUserProfile.address2;
      endUserProfileForm.Country = endUserProfile.country;
      endUserProfileForm.State = endUserProfile.state;
      endUserProfileForm.PostalCode = endUserProfile.postalcode;
      endUserProfileForm.ContactPref = endUserProfile.contactpref;
      endUserProfileForm.Landline = endUserProfile.landline;
      endUserProfileForm.City = endUserProfile.city;
      return endUserProfileForm;
  }

  public convertEndUserProfileResponseToForm(endUserProfile: EndUserProfileResponse): EndUserProfileForm {
    const endUserProfileForm = new EndUserProfileForm();
    endUserProfileForm.FirstName = endUserProfile.FirstName;
    endUserProfileForm.LastName = endUserProfile.FamilyName;
    endUserProfileForm.Phone = endUserProfile.MobilePhoneNumber;
    // endUserProfileForm.Fax = endUserProfile.fax;
    endUserProfileForm.EmailAddress = endUserProfile.Email;
    endUserProfileForm.Address1 = endUserProfile.Address1;
    endUserProfileForm.Address2 = endUserProfile.Address2;
    endUserProfileForm.Country = endUserProfile.Country;
    endUserProfileForm.State = endUserProfile.StateRegion;
    endUserProfileForm.PostalCode = endUserProfile.Postal
    endUserProfileForm.ContactPref = endUserProfile.ContactPref;
    // endUserProfileForm.Landline = endUserProfile.Landline;
    endUserProfileForm.City = endUserProfile.City;
    return endUserProfileForm;
}

  public convertEndUserFormToProfile(endUserProfileForm: EndUserProfileForm): EndUserProfile {
      const endUserProfile = new EndUserProfile(null, endUserProfileForm.EmailAddress);
      endUserProfile.firstname = endUserProfileForm.FirstName;
      endUserProfile.lastname = endUserProfileForm.LastName;
      endUserProfile.cellphone = endUserProfileForm.Phone;
      endUserProfile.fax = endUserProfileForm.Fax;
      endUserProfile.emailaddress = endUserProfileForm.EmailAddress;
      endUserProfile.address1 = endUserProfileForm.Address1;
      endUserProfile.address2 = endUserProfileForm.Address2;
      endUserProfile.country = endUserProfileForm.Country;
      endUserProfile.state = endUserProfileForm.State;
      endUserProfile.postalcode = endUserProfileForm.PostalCode;
      endUserProfile.contactpref = endUserProfileForm.ContactPref;
      endUserProfile.landline = endUserProfileForm.Landline;
      endUserProfile.city = endUserProfileForm.City;
      endUserProfile.units = endUserProfileForm.Units;
      return endUserProfile;
  }

  public listPrimaryIndustries(): Observable<PrimaryIndustryListItem[]>
  {
    return this.http
                  .get<PrimaryIndustryListItem[]>(`${this.url}/ListPrimaryIndustries`);
  }

  public listAppEndUserIndustries(username: string): Observable<EndUserIndustry[]>
  {
    const params = new HttpParams().set('_username', username);
    const options = httpOptions;
    options['params'] = params;
    return this.http
                  .get<EndUserIndustry[]>(`${this.url}/ListAppEndUserIndustries`, options);
  }

  public deleteAppEndUserIndustry(username: string, industryId: number): Observable<any> {
    let params = new HttpParams()
      .set('_username', username)
      .set('industryId', industryId.toString())
    return this.http.post<void>(`${this.url}/DeleteAppEndUserIndustry`, null, {params: params});
  }

  public addAppEndUserIndustries(username: string, industryId: number): Observable<any> {
    let params = new HttpParams()
      .set('_username', username)
      .set('primaryIndustryId', industryId.toString())
    return this.http.post<void>(`${this.url}/AddAppEndUserIndustries`, null, {params: params});
  }

  public getMachineMfgList(): Observable<MachineManufacturer[]> {
    const options = httpOptions;
    return this.http.get<any[]>(`${this.url}/GetMachineMfgList`, options)
    .pipe(map(result => result.map(mm => {
      const transformed = new MachineManufacturer();
      transformed.Id = mm.id;
      transformed.name = mm.mfgname
      transformed.is_active = mm.is_active
      return transformed;
    })));
  } 

  public getAppEndUserMachinesList(username: string): Observable<AppEndUserMachineModel[]> {
    const params = new HttpParams().set('_username', username);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<AppEndUserMachineModel[]>(`${this.url}/GetAppEndUserMachinesList`, options);
  }

  public getMachineModelsbyMfgID(mfgId: string): Observable<MachineModel[]> {
    const params = new HttpParams().set('mfgId', mfgId);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<any[]>(`${this.url}/GetMachineModelsbyMfgID`, options)
    .pipe(map(result => result.map(mm => {
      const transformed = new MachineModel();
      transformed.Id = mm.id;
      transformed.machine_manufacturer_id = mm.machinemfgId;
      transformed.name = mm.modelname;
      transformed.is_active = true;
      transformed.is_bandsaw = false;
      return transformed;
    })));
  }

  public addAppEndUserMachinestoProfile(username: string, mfgId: number, modelId: number): Observable<any> {
    let params = new HttpParams()
      .set('username', username)
      .set('machinemfgId', mfgId.toString())
      .set('machinemodelId', modelId.toString())
    return this.http.post<void>(`${this.url}/AddAppEndUserMachinestoProfile`, null, {params: params});
  }

  public deleteAppEndUserMachinesfromProfile(username: string, mfgId: number, modelId: number): Observable<any> {
    let params = new HttpParams()
      .set('_username', username)
      .set('machinemfgId', mfgId.toString())
      .set('machinemodelId', modelId.toString())
    return this.http.post<void>(`${this.url}/DeleteAppEndUserMachinesfromProfile`, null, {params: params});
  }
  
}
