import { Room } from './Room';
import { Sensitivity } from './enums/Sensitivity';

export class Event {
    id: string;
    start: any;
    end: any;
    title: string;
    type: string;
    body: string;
    location: string;
    customerId: number;
    agenda: string;
    requiredAttendees: Person[];
    optionalAttendees: Person[];
    resources: string[];
    room: Room;
    sensitivity: Sensitivity;
    customers: any;
    isMarketingEvent: any;
    // Room: Room;
}
