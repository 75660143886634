import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactUsRequest } from 'src/app/models/ContactUsRequest';
import { SharedDataService, DictKey } from 'src/app/services/shared-data.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ContactListItem } from 'src/app/models/ContactListItem';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { PageInfo } from 'src/app/models/PageInfo';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.page.html',
  styleUrls: ['./contact-us-modal.page.scss'],
})
export class ContactUsModalPage implements OnInit {
  @Input() type: string;

  title = 'Contact Us';

  request: ContactUsRequest;
  requestForm: FormGroup;

  submitAttempt = false;
  saving = false;
  public pageInfo: PageInfo = null;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private customerService: CustomerService,
    private sharedDataService: SharedDataService
  ) {
    this.requestForm = this.formBuilder.group({
      FullName: [null, Validators.required],
      Email: [null, Validators.required],
      PhoneNumber: [null, Validators.required],
      Info: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.request = new ContactUsRequest();

    // this.type is trial, tech, other, or null. TODO: make enum
    if (this.type === 'trial') {
      this.title = 'Request a Trial';
    } else if (this.type === 'tech') {
      this.title = 'Request a Tech Service Call';
    } else if (this.type === 'rga') {
      this.title = 'Request an RGA';
    }
    this.request.Type = this.type;

    const tokenClaims = this.settingsService.getUserSettings();
    const userInfo = this.settingsService.mapTokenClaimsToUserSettings(tokenClaims);
    const fullName = `${userInfo.FirstName || ''} ${userInfo.FamilyName || ''}`.trim();
    // console.log('userInfo, fullName', userInfo, fullName);

    this.request.FullName = fullName;
    this.request.Email = userInfo.Email;
    this.request.PhoneNumber = userInfo.Phone;
    this.requestForm.patchValue(this.request);
    this.getCustomerInfo();
  }

  getCustomerInfo() {
    this.pageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo) as PageInfo;
    this.sharedDataService.getEmitter(DictKey.AppPageInfo).subscribe(value => {
      this.pageInfo = value;
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  submit() {
    this.submitAttempt = true;
    // if the form is valid
    if (this.requestForm.valid) {
      this.saving = true;
      const fromForm = this.requestForm.getRawValue();
      this.request.FullName = fromForm.FullName;
      this.request.Email = fromForm.Email;
      this.request.PhoneNumber = fromForm.PhoneNumber;
      this.request.Info = fromForm.Info;

      this.request.CustNo = this.pageInfo && this.pageInfo.OdpCustomerNumber && this.pageInfo.OdpCustomerNumber.toString();

      this.customerService.submitContactUs(this.request).subscribe(result => {
        console.log(result);
        // If event adds successfully close the modal
        if (result) {
          this.closeModal();
        } else {
          alert('Save unsuccesful. Please try again.');
          this.saving = false;
        }
      }, (error) => {
        alert(`Save unsuccessful. Please try again. If it still doesn't work, this may help tech support solve this issue more quickly:
        Status: ${error.status}. Location: P-M-ACP. Reason: ${error.error.ExceptionMessage}`);
        this.saving = false;
      });
    } else {
      console.log('ERRORS');
      console.log(this.requestForm);
    }
  }

}
