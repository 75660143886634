import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TrackingResult } from 'src/app/models/TrackingResult';

@Component({
  selector: 'app-tracking-info',
  templateUrl: './tracking-info.page.html',
  styleUrls: ['./tracking-info.page.scss'],
})
export class TrackingInfoPage implements OnInit {

  @Input() trackingResults: TrackingResult[];

  constructor(private modalController: ModalController) { }

  ngOnInit() {

  }

  
  closeModal() {
      this.modalController.dismiss();
  }

}
