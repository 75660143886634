import { Component, HostListener, OnInit, Output, ViewChild, ElementRef, EventEmitter, Input } from "@angular/core";

import { IonSlides } from '@ionic/angular';
import { promise } from "protractor";
import { SettingsService } from "src/app/services/settings.service";

export class RulerSlide {
    id: number;
    name: string;
    class: string;
    value: number;
}

@Component({
    selector: 'ruler-slide',
    templateUrl: './ruler-slide-component.html',
    styleUrls: ['./ruler-slide-component.scss']
})
export class RulerSlideComponent implements OnInit {


    @ViewChild('slider', { read: ElementRef }) slider: ElementRef;
    @ViewChild('slider') ionSlides: IonSlides;

    @Input() initValue: number;
    @Input() initFraction: string;


    @Output() selectedInch = new EventEmitter<number>();
    @Output() selectedFraction = new EventEmitter<string>();
    @Output() currentSlide = new EventEmitter<number>();

    themeChangeSubscription: any;
    dropdown: string = this.settingsService.getTheme("icons/dropdown-icon.svg");

    slideOpts = {
        initialSlide: 0,
        slidesPerView: 23,
        centeredSlides: true,
        breakpoints: {
            320: {
                slidesPerView: 13
            },
            480: {
                slidesPerView: 15
            },
            640: {
                slidesPerView: 17
            },
            768: {
                slidesPerView: 19
            },
            1024: {
                slidesPerView: 21
            },
            1280: {
                slidesPerView: 23
            }

        }
    };

    activeInch: number = 0;
    activeFraction: string = "0";

    slides: RulerSlide[] = [];
    selectedSlide: number;

    values: string[] = [
        '0', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '1', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '2', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '3', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '4', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '5', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '6', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '7', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '8', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '9', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '10', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
        '11', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8',
    ];


    // selectedMeasurement: number;

    constructor(
        private settingsService: SettingsService
    ) {
        this.themeChangeSubscription = this.settingsService.themeUpdate.subscribe(() => {
            this.dropdown = this.settingsService.getTheme("icons/dropdown-icon.svg");
        })
    }


    ngOnInit() {
        if (this.initValue) {
            this.goToSlide(this.initValue);
        }
        const swiper = this.ionSlides;
        for (let i = 0; i < this.values.length; i++) {
            const slide = new RulerSlide(

            );
            slide.id = i;
            slide.value = i * 0.125;
            slide.name = this.values[i];
            slide.class = "rulerStyle";
            if (slide.value % 0.25 == 0 && slide.value % 1 != 0) {
                slide.class = slide.class + " quarterRulerStyle";
            } else if (slide.value % 0.125 == 0 && slide.value % 1 != 0 && slide.value % 0.25 != 0) {
                slide.class = slide.class + " eigthRulerStyle";
            }
            this.slides.push(slide);
        }
    };

    goToSlide(initValue: number) {
        this.ionSlides.slideTo(initValue);
    }


    setActiveIndex() {
        this.ionSlides.getActiveIndex().then(x => {
            this.selectedSlide = x;
            this.currentSlide.emit(x);
            this.activeInch = Math.floor(this.selectedSlide * 0.125);
            this.activeFraction = this.values[this.selectedSlide * 1];
            this.selectedInch.emit(this.activeInch);
            this.selectedFraction.emit(this.activeFraction);
        });
    }




}







