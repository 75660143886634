import { Component, OnInit, Input } from '@angular/core';
import { BladeSearchResult } from 'src/app/models/BladeSearchResult';

@Component({
  selector: 'app-break-in',
  templateUrl: './break-in.component.html',
  styleUrls: ['./break-in.component.scss'],
})
export class BreakInComponent implements OnInit {

  @Input() selectedResult: BladeSearchResult;

  constructor() { }

  ngOnInit() {}

}
