import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from '../secondary-components/footer/footer.component';
import { HeaderComponent } from '../secondary-components/header/header.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NotificationComponent } from '../secondary-components/notification/notification.component';
import { AutosizeModule } from 'ngx-autosize';
import { FilesComponent } from '../components/files/files.component';
import { CustomerFooterComponent } from '../secondary-components/customer-footer/customer-footer.component';
import { CustomerSearch } from 'src/app/components/customer-search/customer-search.component';
import { AutoCompleteComponent } from '../components/auto-complete/auto-complete.component';
import { BoldPrefix } from '../pipes/bold-prefix.pipe';
import { PopoverSalesDrillComponent } from '../components/popover-sales-drill/popover-sales-drill.component';
import { UpdateCustomerPage } from './../components/update-customer/update-customer';
import { CartDropdownComponent } from '../secondary-components/cart-dropdown/cart-dropdown.component';
import { GroupComponent } from '../components/group/group.component';
import { ProfileDropdownComponent } from '../secondary-components/header/profile-dropdown/profile-dropdown.component';
import { InvoiceAmountPipe } from '../pipes/invoice-amount.pipe';
import { BreakInComponent } from '../components/blade-wizard/break-in/break-in.component';
import { PortaBladeLwtPipe } from '../pipes/porta-blade-lwt.pipe';
import { BladeUnitCardComponent } from '../components/bladewizard-cards/blade-unit-card/blade-unit-card.component';
import { FamilyComponent } from '../components/family/family.component';
import { RulerSlideComponent } from '../secondary-components/ruler-slide/ruler-slide-component';
import { SprcHelpComponent } from '../components/sprc-help/sprc-help.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    CustomerSearch,
    FooterComponent,
    HeaderComponent,
    NotificationComponent,
    FilesComponent,
    AutoCompleteComponent,
    BoldPrefix,
    InvoiceAmountPipe,
    PortaBladeLwtPipe,
    CustomerFooterComponent,
    PopoverSalesDrillComponent,
    UpdateCustomerPage,
    FamilyComponent,
    GroupComponent,
    CartDropdownComponent,
    ProfileDropdownComponent,
    BreakInComponent,
    BladeUnitCardComponent,
    RulerSlideComponent,
    SprcHelpComponent
  ],
  imports: [
  AutosizeModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({ loader: { // <--- add this
      provide: TranslateLoader, // <--- add this
      useFactory: (createTranslateLoader),  // <--- add this
      deps: [HttpClient] // <--- add this
    } })
  ],
  exports: [
    CustomerSearch,
    NotificationComponent,
    IonicModule,
    AutosizeModule,
    FooterComponent,
    HeaderComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FilesComponent,
    AutoCompleteComponent,
    CustomerFooterComponent,
    FamilyComponent,
    GroupComponent,
    PopoverSalesDrillComponent,
    UpdateCustomerPage,
    InvoiceAmountPipe,
    PortaBladeLwtPipe,
    CartDropdownComponent,
    ProfileDropdownComponent,
    BreakInComponent,
    BladeUnitCardComponent,
    RulerSlideComponent,
    SprcHelpComponent
  ]
})
export class SharedModule { }
