export class BladeSearchResult {
    B1BRNA: string; //Name
    B1BDS: string; //Description
    B2TOOD: string; //Tooth Description
    sortOrder: number; //present blades in correct order of use
    BreakIn: string;
    thicknessImperial: number;
    thicknessMetric: number;
    widthMetric: number;
    widthImperial: number;
    tpiDesc: string;
    feedRate: number;
    cutTime: string;
    lifeFactor: string;
    materialgrade_id: number;
    materialgrade_name: string;
    materialgradetype_text: string;
    setStyle: string;
}