import { Injectable } from '@angular/core';
import { Event } from './../models/Event';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { RequestOptions, Headers } from '@angular/http';
import { Room } from '../models/Room';
import { EventGrouped } from '../models/EventGrouped';
import { map } from 'rxjs/operators';
import { MeetingType } from '../models/MeetingType';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private exchangeURL: string;
  private exchangeURLSuffix = 'api/event';  // URL to web api
  private morseURL: string;

  constructor(
    private http: HttpClient,
    private behaviorSubjectService: BehaviorSubjectService) {
    behaviorSubjectService.exchangeAPISubject.subscribe(url => {
      this.exchangeURL = url + `/${this.exchangeURLSuffix}`; //      this.url = `http://localhost:64603/${this.urlSuffix}`;
        });
    behaviorSubjectService.morseAPISubject.subscribe(url => {
      this.morseURL = url; //      this.url = `http://localhost:64603/${this.urlSuffix}`;
        });
  }

  getTimezoneName() {
            // https://stackoverflow.com/questions/17348807/how-to-translate-between-windows-and-iana-time-zones
            // need to use IANA time zone and convert because devices pass
            // as example: Eastern Daylight instead of Eastern Standard
            // Exchange server doesn't recognized Easter Daylight Time
            return Intl.DateTimeFormat().resolvedOptions().timeZone;

// https://stackoverflow.com/questions/9772955/how-can-i-get-the-timezone-name-in-javascript
/*     const today = new Date();
    const short = today.toLocaleDateString(undefined);
    const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

    // Trying to remove date from the string in a locale-agnostic way
    const shortIndex = full.indexOf(short);
    if (shortIndex >= 0) {
      const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
      
      // by this time `trimmed` should be the timezone's name with some punctuation -
      // trim it from both sides
      return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');

    } else {
      // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
      return full;
    } */
    // need to use IANA time zone and convert because devices pass
    // as example: Eastern Daylight instead of Eastern Standard
    // Exchange server doesn't recognized Easter Daylight Time
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getMeetingTypes(): Observable<MeetingType[]> {
    return this.http.get<string>(this.morseURL + '/GetMeetingTypes').pipe(map((result) => {
      const dictionary: Record<string, string>[] = JSON.parse(result);
      return Object.keys(dictionary).map(key => ({ Id: key, MeetingType1: dictionary[key] }) as MeetingType);
    }));
  }
   
  getCurrentUserEvents(
      startdate,
      enddate,
      maxItems,
      takeMaxFromEndDateBack: boolean,
      previousStart: Date,
      previousEnd: Date,
      usertimezone: string): Observable<Event[]> {
    const params = new HttpParams()
      .set('startdate', startdate)
      .set('enddate', enddate)
      .set('usertimezone', this.getTimezoneName());
    return this.http.get<Event[]>(this.exchangeURL + '/GetCurrentUserEvents/' + maxItems, {params: params});
  }

  getCurrentUserEventsListView(
      startdate,
      enddate,
      maxItems,
      takeMaxFromEndDateBack: boolean,
      previousStart: Date,
      previousEnd: Date,
      previousStartId: string,
      previousEndId: string,
      usertimezone: string) : Observable<EventGrouped[]> {
    let params = new HttpParams()
      .set('startdate', startdate)
      .set('enddate', enddate)
      .set('takeMaxFromEndDateBack', takeMaxFromEndDateBack.toString())
      .set('usertimezone', this.getTimezoneName());
    if (previousStart && previousEnd) {
      params = params
        .set('previousStart', previousStart.toISOString())
        .set('previousEnd', previousEnd.toISOString())
        .set('previousStartId', previousStartId)
        .set('previousEndId', previousEndId);
        //.set('usertimezone', this.getTimezoneName());
    } else {
      params = params
        .set('previousStart', null)
        .set('previousEnd', null)
        .set('previousStartId', null)
        .set('previousEndId', null);
        //.set('usertimezone', this.getTimezoneName());        
      // .set('previousStartId', null).set('previousEndId', null);
    }
    return this.http.get<EventGrouped[]>(this.exchangeURL + '/GetCurrentUserEventsListView/' + maxItems, {params: params});
  }

getMarketingEventsListView(
  startdate,
  enddate,
  maxItems,
  takeMaxFromEndDateBack: boolean,
  previousStart: Date,
  previousEnd: Date,
  previousStartId: string,
  previousEndId: string,
  usertimezone: string) : Observable<EventGrouped[]> {
let params = new HttpParams()
  .set('startdate', startdate)
  .set('enddate', enddate)
  .set('takeMaxFromEndDateBack', takeMaxFromEndDateBack.toString())
  .set('usertimezone', this.getTimezoneName());
if (previousStart && previousEnd) {
  params = params
    .set('previousStart', previousStart.toISOString())
    .set('previousEnd', previousEnd.toISOString())
    .set('previousStartId', previousStartId)
    .set('previousEndId', previousEndId)
    .set('usertimezone', this.getTimezoneName());;
} else {
  params = params
    .set('previousStart', null)
    .set('previousEnd', null)
    .set('previousStartId', null)
    .set('previousEndId', null)
    .set('usertimezone', this.getTimezoneName());;
  // .set('previousStartId', null).set('previousEndId', null);
}
  return this.http.get<EventGrouped[]>(this.exchangeURL + '/GetMarketingEventsListView/' + maxItems, {params: params});
}

getMarketingEvents(
  startdate,
  enddate,
  maxItems,
  takeMaxFromEndDateBack: boolean,
  previousStart: Date,
  previousEnd: Date,
  usertimezone: string): Observable<Event[]> {
const params = new HttpParams()
  .set('startdate', startdate)
  .set('enddate', enddate) 
  .set('usertimezone', this.getTimezoneName());
return this.http.get<Event[]>(this.exchangeURL + '/GetMarketingEvents/' + maxItems, {params: params});
}

getFutureMarketingEvents(prevDate, usertimezone, listView: boolean): Observable<Event[]> {
  const params = new HttpParams()
    .set('prevDate', prevDate)
    .set('usertimezone', this.getTimezoneName())
    .set('listView', listView ? 'true' : 'false');
return this.http.get<Event[]>(this.exchangeURL + '/GetFutureMarketingEvents', {params: params});
}

getPastMarketingEvents(prevDate, usertimezone, listView: boolean): Observable<Event[]> {
  const params = new HttpParams()
    .set('prevDate', prevDate)
    .set('usertimezone', this.getTimezoneName())
    .set('listView', listView ? 'true' : 'false');
  return this.http.get<Event[]>(this.exchangeURL + '/GetPastMarketingEvents', {params: params});
  }

  getFutureEvents(numberOfDays: number, usertimezone) {
    const params = new HttpParams()
      .set('usertimezone', this.getTimezoneName());
    return this.http.get<Event[]>(this.exchangeURL + '/GetFutureEvents/' + numberOfDays), {params: params}; 
  }

  getGroupEvents(startdate, enddate, usertimezone): Observable<Event[]> {
    const params = new HttpParams()
      .set('startdate', startdate)
      .set('enddate', enddate) 
      .set('usertimezone', this.getTimezoneName());;
    return this.http.get<Event[]>(this.exchangeURL + '/GetGroupEvents', {params: params});
  }

  addEvent(eventToAdd: Event, usertimezone):Observable<string> {
    const formData: FormData = new FormData();
    usertimezone = this.getTimezoneName();
    return this.http.post<string>(this.exchangeURL + '/addEvent', formData, {params: {eventToAddJSON: JSON.stringify(eventToAdd), usertimezone}});
  }

  updateEvent(eventToUpdate: Event, usertimezone) {
    const formData: FormData = new FormData();
    usertimezone = this.getTimezoneName();
    return this.http.post(this.exchangeURL + '/updateEvent', formData, {params: {eventToUpdateJSON: JSON.stringify(eventToUpdate), usertimezone}});
  }

  deleteEvent(eventToDelete: Event) {
    return this.http.post(this.exchangeURL + '/deleteEvent', eventToDelete, httpOptions);
  }

  getPeopleFiltered(searchParam, usertimezone): Observable<any> {
    const params = new HttpParams()
      .set('searchParam', searchParam)
      .set('usertimezone', this.getTimezoneName());
    return this.http.get<any>(this.exchangeURL + '/getUsers', {params: params}).pipe(map((result) => result.people));
  }

  getUsersByEmails(addresses: string[], usertimezone): Observable<any> {
    const params = new HttpParams()
      .set('addresses', addresses.join(','))
      .set('usertimezone', this.getTimezoneName());
    return this.http.get<any>(this.exchangeURL + '/getUsersByEmails', {params: params});
  }

  getLocations(usertimezone): Observable<Room[]> {
    const params = new HttpParams()
      .set('usertimezone', this.getTimezoneName());
    return this.http.get<Room[]>(this.exchangeURL + '/getRooms', {params: params});
  }

  getMeetingDetailsFromCRM(exchangeMeetingId: string): Observable<any> {
    const params = new HttpParams().set('exchangeMeetingId', exchangeMeetingId);
    return this.http.get<string>(this.morseURL + '/GetMeetingDetailsFromCRM', {params: params}).pipe(map((result) => JSON.parse(result)));
  }
}
