import { Component, OnInit, Input } from '@angular/core';
import { CustomerDetails } from 'src/app/models/CustomerDetails';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-customer-footer',
  templateUrl: './customer-footer.component.html',
  styleUrls: ['./customer-footer.component.scss'],
})
export class CustomerFooterComponent implements OnInit {

  @Input() customer: CustomerDetails;
  isPublicURL = false;

  constructor(
    private router: Router,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.isPublicURL = this.menuService.isPublicURL();
  }

  redirect(route: string) {
    this.router.navigate([route]);
  }

}
