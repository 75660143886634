import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserSettings } from '../models/UserSettings';
import { TokenClaims } from '../models/TokenClaims';
import { Observable } from 'rxjs';
import { BehaviorSubjectService } from './behavior-subject.service';
import { DrivingDirectionsAppType } from '../models/enums/DrivingDirectionsAppType';
import { PageInfo } from '../models/PageInfo';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private url: string;
  public theme: string;

  @Output() themeUpdate = new EventEmitter<string>();


  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService) {
    behaviorSubjectService.membershipRebootAPISubject.subscribe(url => { this.url = url; });
  }

  isProfileComplete(): boolean {
    const userSettings = this.getUserSettings();
    if (userSettings && userSettings.profileComplete && userSettings.profileComplete.toLowerCase() == "true") {
      return true;
    } else {
      return false;
    }
  }

  updateUserSettings(settings: UserSettings) {
    const url = this.url + `/UserAccount/Home/Settings`;
    return this.http.post<any>(url, settings, httpOptions);
  }

  getUserSettings(): TokenClaims {
    const token = window.localStorage.getItem('id_token_claims_obj');
    if (token) {
      return JSON.parse(token) as TokenClaims;
    } else {
      return null;
    }
  }

  getUserView(): UserView {
    if (!this.isLoggedIn()) {
      return UserView.NoLogin;
    }
    const userSettings = this.getUserSettings();
    if (userSettings) {
      const roles = userSettings.role;
      if (roles.includes('endUser')) {
        return UserView.EndUser;
      } else if (roles.includes('odp') && !roles.includes('sales')) {
        return UserView.Distributor;
      } else {
        return UserView.Sales;
      }
    } else {
      return UserView.NoLogin;
    }
  }

  themeChanged(newTheme: string, emit = true){
    this.theme = newTheme;
    if (emit) {
      this.themeUpdate.emit(this.theme);
    }
  }

  getTheme(imgName: string) {
    var settings = this.getUserSettings();
    let theme = null;
    if (settings) {
      theme = settings.theme;
    }
    if (!theme) {
      theme = 'light';
    }
    this.theme = theme;
  
    if (this.theme === "light") {
      return `./assets/images/light-theme/${imgName}`;
    }
      return `./assets/images/dark-theme/${imgName}`;
  }

  getLocalTheme

  getUserRoleAnalytics() {
    const roles = this.getRoles();
    if (this.containsAdminRole(roles)) {
      return 'Admin'
    } else if (this.isDistributor()) {
      return 'Distributor';
    } else if (this.containsEndUserRole(roles)) {
      return 'EndUser';
    } else if (this.containsRepAgentRole(roles)) {
      return 'RepAgent';
    } else {
      return 'Public';
    }
  }

  isDistributor(): boolean {
    return this.getUserView() === UserView.Distributor;
  }

  isRepAgent(): boolean {
    const roles = this.getRoles();
    return this.containsRepAgentRole(roles);
  }

  isEndUser(): boolean {
    const roles = this.getRoles();
    return this.containsEndUserRole(roles);
  }

  containsEndUserRole(roles: string[]): boolean {
    if(roles.includes('endUser') || roles.includes('enduser')) {
      return true;
    } else {
      return false;
    }
  }

  containsRepAgentRole(roles: string[]): boolean {
    if (roles.includes('repagent')) {
      return true;
    } else {
      return false;
    }
  }

  containsAdminRole(roles: string[]): boolean {
    if (roles.includes('Admin')) {
      return true;
    } else {
      return false;
    }
  }

  getRoles(): string[] {
    const userSettings = this.getUserSettings();
    let roles: string[] = [];
    if (userSettings) {
      roles = userSettings.role;
    }
    return roles;
  }

  isMorseDomain(): boolean {
    const userSettings = this.getUserSettings();
    // if(userSettings.domain.includes("Morse") || userSettings.domain.includes("MKMORSE") ||
    // userSettings.domain.includes("morse") || userSettings.domain.includes("MORSE"))
    return userSettings && userSettings.domain === 'MKMORSE';
  }

  mapTokenClaimsToUserSettings(claims: TokenClaims): UserSettings {
    const userSettings = new UserSettings();
    if (claims) {
      userSettings.Email = claims.email;
      userSettings.Domain = claims.domain;
      userSettings.FamilyName = claims.family_name;
      userSettings.FirstName = claims.given_name;
      userSettings.Language = claims.preferredLanguage;
      userSettings.Units = claims.preferredUnitSystem;
      userSettings.DrivingDirectionsApp = claims.drivingDirectionsApp;
      userSettings.Phone = claims.phone_number;
    }
    return userSettings;
  }

  isLoggedIn(): boolean {
    const token = this.getUserSettings();
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  // getUserSettingsIS3(): Observable<any> {
  //   const url = `https://authdev.mkmorse.com/core/connect/userinfo`;
  //   return this.http.get<any>(url);
  // }
}

export enum UserView {
  Sales,
  Distributor,
  EndUser,
  NoLogin
}
