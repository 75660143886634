import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalMethodsService {

  constructor() { }

  public checkPlural(word, count, wordIndexInString, knownSuffix):string {
    var plurals = {
      'Salesperson':'Salespeople',
      'Territory':'Territories'
    }

    return count == 1 ? word : plurals[word] ? plurals[word] : word + 's';
  }

  public addIdSuffix(type:string, id:string){
    if (type != 'Divison' && type != 'Region' && type != 'Salesperson') return ' (' + id + ')';
    return '';
  }
}
