import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RepAgency } from '../models/RepAgency';
import { EndUser } from '../models/EndUser';
import { Distributor } from '../models/Distributor';
import { CustomerType } from '../models/enums/CustomerType';
import { SelectListItem } from '../models/SelectListItem';
import { BehaviorSubjectService } from './behavior-subject.service';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' }),
};

@Injectable({
  providedIn: 'root'
})
export class ListService {
  private url: string;

  constructor(
    private http: HttpClient,
    behaviorSubjectService: BehaviorSubjectService,
  ) {
    behaviorSubjectService.morseAPISubject.subscribe(url => {
      this.url = url;
    });
  }

  getStates(countryId): Observable<string> {
    const url = `${this.url}/GetStates`;
    const params = new HttpParams().set('countryId', countryId);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<string>(url, options);
  }

  getCountries(): Observable<string> {
    const url = `${this.url}/GetCountries`;
    return this.http.get<string>(url);
  }

  getPricingPrograms(): Observable<SelectListItem[]> {
    const url = `${this.url}/GetPricingPrograms`;
    return this.http.get<string>(url).pipe(map((result) => JSON.parse(result)));
  }

  getEndUserClasses(): Observable<string> {
    const url = `${this.url}/GetEndUserClass`;
    return this.http.get<string>(url);
  }

  getSalesPeople(): Observable<any> {
    const url = `${this.url}/GetSalesPeople`;
    return this.http.get<string>(url).pipe(map((result) => JSON.parse(result)));
  }

  getAccountLevels(customerType: string): Observable<SelectListItem[]> {
    if (customerType === 'End User') {
      customerType = 'EndUser';
    }
    const url = `${this.url}/GetAccountLevels`;
    const params = new HttpParams().set('type', customerType);
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getContactMethodTypes(): Observable<SelectListItem[]> {
    const url = `${this.url}/GetContactMethodTypes`;
    return this.http.get<string>(url).pipe(map((result) => JSON.parse(result)));
  }

  getRGAReturnTypes(): Observable<SelectListItem[]> {
    const url = `${this.url}/GetRgaReturnTypes`;
    return this.http.get<string>(url).pipe(map((result) => JSON.parse(result)));
  }

  getRGATypes(returnTypeId: number): Observable<SelectListItem[]>{
    const url = `${this.url}/GetRgaTypes`;
    const params = new HttpParams().set('returnTypeId', returnTypeId.toString());
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

  getRGASubTypes(rgaTypeId: number): Observable<SelectListItem[]>{
    const url = `${this.url}/GetRgaSubTypes`;
    const params = new HttpParams().set('rgaTypeId', rgaTypeId.toString());
    const options = httpOptions;
    options['params'] = params;
    return this.http.get<string>(url, options).pipe(map((result) => JSON.parse(result)));
  }

}
