import { ContactMethod } from './ContactMethod';

export class ContactListItem {
    Id: number;
    FirstName: string;
    LastName: string;
    FullName: string;
    Position: string; // Role
    AccountId: string;
    AccountDisplay: string;
    IsPrimaryContact: boolean;
    PrimaryContactMethod: ContactMethod;
    DisplayName: string;
}
