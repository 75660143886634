import { Injectable } from '@angular/core';
import { Event } from './../models/Event';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubjectService } from './behavior-subject.service';
import { RequestOptions, Headers } from '@angular/http';
import { Attachment } from '../models/Attachment';
import { map } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {
    private url: string;
    private exchangeUrl: string;
    private downloadURL = window.URL;

    constructor(
        private http: HttpClient,
        behaviorSubjectService: BehaviorSubjectService
    ) {
        behaviorSubjectService.morseAPISubject.subscribe(url => {
            this.url = url;
        });
        behaviorSubjectService.exchangeAPISubject.subscribe(
            url => (this.exchangeUrl = url)
        );
    }

    //saveNewFileUpload
    saveNewFileUpload(file: File, itemId: number, itemType: string) {
        const formData: FormData = new FormData();
        formData.append(file.name, file, file.name);
        return this.http.post(this.url + '/saveNewFileUpload', formData, {
            params: { itemId: itemId.toString(), itemType }
        });
    }

    //saveNewFileUpload
    saveFileUploadNewVersion() {
        return this.http.post(
            this.url + '/saveFileUploadNewVersion',
            httpOptions
        );
    }

    getItemTypes(): Observable<string[]> {
        return this.http
            .get<string>(this.url + '/GetItemTypes')
            .pipe(map(result => JSON.parse(result)));
    }

    getFileUploads(): Observable<Attachment[]> {
        return this.http
            .get<string>(this.url + '/getFileUploads', httpOptions)
            .pipe(map(result => JSON.parse(result)));
    }

    getFile(itemType: string, itemId: number, attachmentId: number, fileName: string) {
        var downloadHttpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'Blob' as 'json'
        };
        this.http
            .get<Blob>(
                this.url + '/GetFileById/' + itemType + '/' + itemId + '/' + attachmentId,
                downloadHttpOptions
            )
            .subscribe(blob => {
                this.afterGetBlob(blob, fileName)
            });
    }

    getTrainingFile(folder: string, fileName: string): Observable<Blob> {
        return this.http.get<Blob>(`${this.url}/GetTrainingFile/`+folder+'/'+fileName, {responseType: 'blob' as 'json'})
    }

    getPromoFlyer(fileNameExtension: string) {
        const fileExtension = fileNameExtension.slice(fileNameExtension.lastIndexOf('.') + 1, fileNameExtension.length);
        const fileNameNoExt = fileNameExtension.slice(0, -1 * (fileExtension.length + 1));
        var downloadHttpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'Blob' as 'json',
        };
        this.http
            .get<Blob>(
                this.url + '/GetPromoFlyer/' + fileNameNoExt + '/' + fileExtension,
                downloadHttpOptions
            )
            .subscribe(blob => {
                this.afterGetBlob(blob, fileNameExtension);
            });
    }

    getFileUploadsForItem(
        itemId: number,
        itemType: string
    ): Observable<Attachment[]> {
        return this.http
            .get<string>(this.url + '/getFileUploadsForItem', {
                params: { itemId: itemId.toString(), itemType }
            })
            .pipe(map(result => JSON.parse(result)));
    }
    getAttachments(
        exchangeId: string,
        itemId: number,
        itemType: string
    ): Observable<Attachment[]> {
        return this.http.get<Attachment[]>(
            `${this.exchangeUrl}/api/attachment?exchangeId=${exchangeId}&itemId=${itemId}&itemType=${itemType}`
        );
    }

    addAttachments(
        exchangeId: string,
        itemId: string,
        itemType: string,
        files: File[]
    ): Observable<Attachment[]> {
        const formData: FormData = new FormData();
        files.forEach(file => {
            formData.append(file.name, file, file.name);
        });
        return this.http.post<Attachment[]>(
            `${this.exchangeUrl}/api/attachment`,
            formData,
            {
                params: {
                    itemId: itemId,
                    itemType: itemType,
                    exchangeId: exchangeId
                }
            }
        );
    }
    
    deleteAttachments(
        exchangeId: string,
        itemId: string,
        itemType: string,
        attachmentIds: number[]
    ): Observable<boolean> {
        return this.http.post<boolean>(
            `${this.exchangeUrl}/api/attachment/delete`,
            attachmentIds ,
            {
                params: {
                    itemType: itemType,
                    itemId: itemId,
                    exchangeId: exchangeId
                }
            }
        );
    }

    afterGetBlob(blob, fileName: string) {
        const ios = !!navigator.platform && (/iPad|iPhone|iPod|/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
        if (ios) {
            var reader = new FileReader();
            reader.onload = function(e) {
                window.location.href = reader.result as string;
            }
            reader.readAsDataURL(blob);
        } else {
            var url = this.downloadURL.createObjectURL(blob);
            window.open(url, '_blank')
        }
    }
}
