import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorSubjectService {
  apiUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  environmentSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  productionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bladeWizardAPISubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  morseAPISubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  exchangeAPISubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  membershipRebootAPISubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  interactionsUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  authUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  clientIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  mobileUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  opdUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  valueCalcUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  mkMorsePublicHomeUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  trialResultsUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  googleAnalyticsIdSubjectManager: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  salesforceUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  bladeWizardCommonAPISubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  bladewizardUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  distributorLocatorUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  cookieYesUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor() {}
}
