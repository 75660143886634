import { UnitType } from './enums/UnitType';
import { MaterialShapeAndSize } from './MaterialShapeAndSize';
import { PTAArbor } from './PTAArbor';
import { PTACircsawDiameterAndArborSize } from './PTACircsawDiameterandArborSize';
import { PTADiameter } from './PTADiameter';
import { PTAHandsawLengthModel } from './PTAHandsawLengthModel';
import { PTAPortabandLengthWidthThickness } from './PTAPortabandLengthWidthThickness';
import { PTARecipBladeLengthWdthTPIModel } from './PTARecipBladeLengthWdthTPIModel';
import { RevDiaArborPinhole } from './RevolutionWizard/RevDiaArborPinhole';

export class BladeWizardInputsBand {
    chipLoad: string;
    aggressive: string;
    performance: string;
    performanceName: string;
    preferedUnits: UnitType;
    bladeWidth: string;
    materialShapeAndSize: MaterialShapeAndSize;
    airSawCheckboxType: AirSawCheckboxType;
    majorminorcats: string[];
    jigShankType: string;
    majorminorratingsort: string[];

    ptaCircsawDiameter: PTADiameter;
    ptaCircsawDiameterId: number;

    ptaCircsawArbor: PTAArbor;
    ptaCircsawArborId: number;

    ptaRecipBladeLength: any;
    ptaRecipBladeLengthId: number;
    ptaRecipBladeTPI: any;
    ptaRecipBladeTPIId: number;

    ptaHandsawLength: PTAHandsawLengthModel;
    ptaHandsawLengthId: number;

    ptaPortabandLengthWidthThickness: PTAPortabandLengthWidthThickness;

    drillToolType: DrillToolType;
    drillMajorMinorCategoryOptionId: number;
    drillMajorMinorCategory: MajorMinorCategoryOption;
    drillCutDepth: number;
    drillHoleSize: number;

    ptaThickness: number;

    machineManufacturerId: number;
    machineModelId: number;
    manufacturerName: string;
    modelName: string;

    revDiaArborPinhole: RevDiaArborPinhole;

    materials: BladeMaterial[];
};

export class MajorMinorCategoryOption {
    Label: string;
    Value: MajorMinorCategory;
    Visible: boolean;
}
  
export class MajorMinorCategory {
    Id: number;
    MajorCategory: string;
    MinorCategory: string;
}

export enum DrillToolType {
    StandardHoleCutting,
    PrecisionProductionHoleCutting,
    WoodBoring
  }

export enum AirSawCheckboxType {
    None = 'NIL',
    USH = 'USH',
    AIR = 'AIR'
}

export enum DrillMinorCategory {
    MHT = 'MHT',
    DIA = 'DIA',
    MHG = 'MHG',
    RCL = 'RCL',
    CTK = 'CTK',
    STP = 'STP',
    SPA = 'SPA',
    AUG = 'AUG'
}

export enum DrillMajorCategory {
    HOL = 'HOL',
    HCP = 'HCP',
    BOR = 'BOR'
}

export class BladeMaterial {
    // fakeId: number;
    materialId: number;
    materialName: string;
    materialGradeId: number;
    materialGrade: string;
}