import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { PageInfo } from 'src/app/models/PageInfo';
import { SharedDataService, DictKey } from 'src/app/services/shared-data.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit, OnDestroy {
  
  pageInfo: PageInfo;
  pageInfoSubscription: any;
  isEndUser = false;

  constructor(
    private popoverController: PopoverController,
    private sharedDataService: SharedDataService,
    private router: Router,
    private oauthService: OAuthService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.pageInfoHandler();
    this.isEndUser = this.settingsService.isEndUser();
  }

  ngOnDestroy() {
    this.pageInfoSubscription.unsubscribe();
  }

  nav(path: string) {
    this.router.navigateByUrl(path);
    this.close();
  }

  logout() {
    this.oauthService.logOut();
  }

  close() {
    this.popoverController.dismiss();
  }

  pageInfoHandler() {
    this.pageInfo = this.sharedDataService.getValue(DictKey.AppPageInfo);
    this.pageInfoSubscription = this.sharedDataService.getEmitter(DictKey.AppPageInfo).subscribe(value => {
      this.pageInfo = value;
    });

    // Default until api call returns
    if (!this.pageInfo) {
      const info = new PageInfo();
      info.OdpCartCount = 0;
      info.showAccount = false;
      info.showCart = false;
      info.showSearch = false;
      this.pageInfo = info;
    }
  }

}
