export class SalesCall {
    Id: number;
    AccountId: number;
    StartDateTime: Date;
    EndDateTime: Date;
    CreatedOn: Date;
    CreatedBy: number;
    ModifiedOn: Date;
    ModifiedBy: number;
}
