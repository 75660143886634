import { DrivingDirectionsAppType } from './enums/DrivingDirectionsAppType';

export class UserSettings {
    FirstName: string;
    MiddleName: string;
    FamilyName: string;
    Email: string;
    Domain: string;
    Currency: number;
    Language: string;
    Units: string;
    DrivingDirectionsApp: string;
    Phone: string;
    Theme: string;
}
