import { Component, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { UnitType } from 'src/app/models/enums/UnitType';

@Component({
  selector: 'app-blade-unit-card',
  templateUrl: './blade-unit-card.component.html',
  styleUrls: [
    './blade-unit-card.component.scss',
    '../../../pages/bladewizard/bladewizard.page.scss'
  ]
})
export class BladeUnitCardComponent implements OnInit {
  @Input() generalInfo: FormGroup;
  @Output() onChangeUnitEmitter = new EventEmitter();

  public UnitType = UnitType;
  submitted = false;

  constructor() { }

  ngOnInit() {}

  onChangeUnit() {
    this.onChangeUnitEmitter.emit('');
  }

}
