import { Component, OnInit, ViewChild } from '@angular/core';
import { FilesComponent } from '../../components/files/files.component'

@Component({
  selector: 'app-filetest',
  templateUrl: './filetest.page.html',
  styleUrls: ['./filetest.page.scss'],
})
export class FiletestPage implements OnInit {

  constructor() { }
  @ViewChild("fileComponent") fileComponent:FilesComponent
  ngOnInit() {
  }
  callFileSave(){
    //this.fileComponent.syncAllFiles().subscribe();
  }
}
